import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_PATHS } from '../constants/api.constant';
import { ApiService } from '../services/api/api.service';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TransitTimesTransportService {
    endpoint: string;
    constructor(private apiService: ApiService, @Inject('ENVIRONMENT') ENVIRONMENT) {
        this.endpoint = ENVIRONMENT.API_ENDPOINT;
    }

    public getServiceLevels(): Observable<any> {
        const path = `/${API_PATHS.SERVICE_LEVELS}/`;
        const url = `${this.endpoint}${path}`;

        return this.apiService.get(url);
    }
}
