import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { GLOBAL } from '../../common/scripts/constants/dashboard.constant';
@Component({
    selector: 'dashboard-item-contract',
    templateUrl: './dashboard-item-contract.component.html'
})
export class DashboardItemContractComponent implements OnInit, OnChanges {
    @Input()
    public viewModel: any;
    @Input()
    public onRemoveClick: Function;
    @Input()
    public onActionClick: Function;
    @Input()
    public isFuelsurcharge: boolean;

    @Input()
    public isItemActive = true;

    public status: string;
    constructor() {}
    ngOnChanges() {
        this.viewModel = this.viewModel || {};
    }
    ngOnInit() {
        this.viewModel = this.viewModel || {};
        this.status = this.viewModel.status ? this.viewModel.status : GLOBAL.CONTRACT_STATUS.NOT_CREATED;

        switch (this.status) {
            case GLOBAL.CONTRACT_STATUS.DRAFT: {
                this.viewModel.content = 'Continue';
                break;
            }
            case GLOBAL.CONTRACT_STATUS.NOT_CREATED: {
                this.viewModel.content = 'Create';
                break;
            }
            case GLOBAL.CONTRACT_STATUS.EXPIRED:
            case GLOBAL.CONTRACT_STATUS.SCHEDULED:
            case GLOBAL.CONTRACT_STATUS.UP_TO_DATE: {
                this.viewModel.content = this.viewModel.expiryDate;
                break;
            }
        }
    }
}
