import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { WizardStepSpec } from '../../../common/scripts/constants/wizard-steps.constant';

@Component({
    selector: 'dropdown-item',
    templateUrl: './dropdown-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownItemComponent implements OnInit {
    @Input() item: WizardStepSpec;

    @Input() isPreview = false;

    @Input() isFirst = false;

    @Output() onItemSelected = new EventEmitter<any>();

    constructor() {}

    public ngOnInit() {}

    public onItemClick() {
        this.onItemSelected.emit(this.item);
    }
}
