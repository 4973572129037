import { PlatformLocation } from '@angular/common';

function genericCalculateBaseHref(platformLocation: PlatformLocation): string {
    const path = platformLocation.pathname;
    let newBaseHref = '/';

    if (path.includes('/private/')) {
        newBaseHref = '/private/';
    } else if (path.includes('/client/')) {
        newBaseHref = '/client/';
    }

    return newBaseHref;
}

export function CalculateBaseHrefFactory(platformLocation: PlatformLocation): string {
    return genericCalculateBaseHref(platformLocation) + 'carrier-account';
}
