import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContractTransportService } from '../transport/contract-transport.service';
import { URL_CONFIG } from '../../../config/url.config';
import { has, get } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class InitWizardGuard  {
    constructor(private router: Router, private transport: ContractTransportService) {}

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<any> {
        const contractId = childRoute.queryParamMap.get('contractId');
        const carrierAccountId = childRoute.queryParamMap.get('carrierAccountId');
        const carrierAccountServiceTypeId = childRoute.queryParamMap.get('carrierAccountServiceTypeId');

        return of(contractId).pipe(
            switchMap((id) =>
                id ? of(true) : this.transport.createContract(carrierAccountId, carrierAccountServiceTypeId) as any
            ),
            map((activator) => {
                if (has(activator, 'id')) {
                    return this.router.createUrlTree([`${URL_CONFIG.CAS.INDEX}/${URL_CONFIG.CAS.WIZARD.INDEX}`], {
                        queryParams: {
                            contractId: get(activator, 'id'),
                            carrierAccountId
                        },
                        queryParamsHandling: 'merge'
                    });
                }

                return activator;
            })
        );
    }
}
