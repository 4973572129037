
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WarehousesTransportService } from '../transport/warehouses-transport.service';

@Injectable()
export class WarehousesResolver  {
    constructor(private transport: WarehousesTransportService) {}

    resolve(): Observable<any> {
        return this.transport.getWarehouseList().pipe(map(({ results }) => results));
    }
}
