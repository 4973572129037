import { Component, Input, OnInit } from '@angular/core';
import { UiModalService, UiModalComponent } from '@maersk-global/huub-material';
import { UiModalCarrierAccountServiceActiveComponent } from '../ui-modal-carrier-account-service-activity/ui-modal-carrier-account-service-activity.component';
import { UiModalFuelsurchargeComponent } from '../ui-modal-fuelsurcharge/ui-modal-fuelsurcharge.component';
import { DashboardTransportService } from '../../common/scripts/transport/dashboard.transport.service';
import { UiModalRemoveContractComponent } from '../ui-modal-remove-contract/ui-modal-remove.component';
import { Router } from '@angular/router';

@Component({
    selector: '[dashboard-item]',
    templateUrl: './dashboard-item.component.html'
})
export class DashboardItemComponent implements OnInit {
    @Input()
    public viewModel: any;
    constructor(
        private uiModal: UiModalService,
        private transport: DashboardTransportService,
        private router: Router
    ) {}

    ngOnInit() {}

    openFuelsurchargeModal = () => {
        this.uiModal
            .openModal(
                UiModalComponent,
                [UiModalFuelsurchargeComponent],
                {
                    title: 'Fuel surcharge',
                    customClass: 'stock-sidebar',
                    position: 'right',
                    componentViewModel: this.viewModel
                },
                () => {} // Disables backdrop click by providing an empty backDropCallCallback
            )
            .afterClose()
            .subscribe(() => {
                this.router.navigate(['.']);
            });
    };

    openRemoveModal = (carrierAccountId, contractId) => {
        this.uiModal
            .openModal(UiModalComponent, [UiModalRemoveContractComponent], {
                title: 'Remove'
            })
            .afterClose()
            .subscribe((confirm) => {
                if (confirm) {
                    this.transport.deleteContract(carrierAccountId, contractId).subscribe();
                }
            });
    };

    editOrCreateContract = (carrierAccountId, carrierAccountServiceTypeId, contractId) => {
        let params: any;
        if (contractId) {
            params = { carrierAccountId, contractId };
        } else {
            params = { carrierAccountId, carrierAccountServiceTypeId };
        }

        this.router.navigate(['cas/wizard/contract-properties'], {
            queryParams: params
        });
    };

    onActivityClick() {
        const title = this.viewModel.isActive ? 'Inactivate service type' : 'Activate service type';
        this.uiModal
            .openModal(UiModalComponent, [UiModalCarrierAccountServiceActiveComponent], {
                title,
                isActive: this.viewModel.isActive
            })
            .afterClose()
            .subscribe((confirm) => {
                if (!confirm) {
                    this.viewModel.isActive = !this.viewModel.isActive;
                    return;
                }
                this.transport
                    .activateDeactivateCarrierAccountServiceType(
                        this.viewModel.carrierServiceTypeAccount.id,
                        this.viewModel.isActive
                    )
                    .subscribe();
            });
    }
}
