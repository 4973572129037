import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'ui-card',
    templateUrl: './ui-card.component.html'
})
export class UiCardComponent implements OnInit {
    @Input() padding = 24;
    constructor() {}

    ngOnInit() {}
}
