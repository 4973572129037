import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContractPropertiesService } from '../../../common/scripts/services/contract-properties.service';
import { ContractPropertiesInput } from '../../../common/scripts/interfaces/contract-properties';
import { of } from 'rxjs';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ContractTransportService } from '../../../common/scripts/transport/contract-transport.service';
import { ActivatedRoute } from '@angular/router';
import { ContractModel } from '../../../common/scripts/models/contract.model';
import { get, isNil, omitBy, pickBy } from 'lodash';
import { FooterBtns, FooterStateService } from '../../../common/scripts/services/footer-state.service';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';

@Component({
    selector: 'contract-properties',
    templateUrl: './contract-properties.component.html'
})
export class ContractPropertiesComponent extends AbstractWizardStep implements OnInit, IsWizardStep {
    @Output() private onDocumentsUpdated = new EventEmitter();

    private uploadDocumentsEventsMap = {};
    private carrierAccountId: number;

    public uploadFilesViewModel = {
        isUploading: false,
        isUploaded: false,
        fileUploadPercentage: 0,
        fileName: ''
    };

    public contractPropertiesContent: ContractPropertiesInput;
    public contractPropertiesForm: UntypedFormGroup;
    public contract: ContractModel = {} as ContractModel;
    public stepNumber: number;

    constructor(
        private footerStateService: FooterStateService,
        private fb: UntypedFormBuilder,
        private contractPropertiesService: ContractPropertiesService,
        private transportContractProperties: ContractTransportService,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    protected setupFooter() {
        this.footerStateService.toggleAll([
            { btn: FooterBtns.NEXT, state: { enabled: true, visible: true, text: 'Zones' } }
        ]);
    }

    private init() {
        this.setupFooter();
        this.initForm();

        if (this.contract.contract_document !== null) {
            this.onRetrieveUploadedFile();
        } else {
            this.uploadFilesViewModel.isUploaded = false;
        }
    }

    private initForm() {
        this.contractPropertiesForm = this.fb.group({
            id: [this.contract.id || null],
            payment_conditions: [this.contract.payment_conditions || '', Validators.required],
            credit_days: [this.contract.credit_days || '', Validators.required],
            target_shipments: [this.contract.target_shipments || '', Validators.required],
            allow_multi_parcel: [this.contract.allow_multi_parcel],
            currency_iso3_code: [this.contract.currency_iso3_code || 'EUR'],
            account_carrier_service_type: [this.contract.account_carrier_service_type],
            weight_unit: [this.contract.weight_unit || 'kg']
        });
    }

    private resolve() {
        this.activatedRoute.data.subscribe((data) => {
            this.footerStateService.setContract(data.contract);
            this.contract = get(data, 'contract', {});
            this.stepNumber = data.step.index + 1;

            this.init();
        });
    }

    private handleSelectedFiles(files: File[], documentRef: any) {
        if (files.length === 0) {
            return;
        }

        const contract = omitBy({ ...this.contract, ...this.contractPropertiesForm.getRawValue() }, isNil);

        this.uploadDocumentsEventsMap[documentRef.id] = this.transportContractProperties
            .uploadDocuments(files[0], contract, this.carrierAccountId)
            .subscribe(
                (result) => {
                    if (!result) {
                        return;
                    }

                    documentRef.isUploaded = result.isUploaded;
                    documentRef.isUploading = result.isUploading;
                    documentRef.fileUploadPercentage = result.fileUploadPercentage;
                },
                () => {
                    documentRef.isUploaded = false;
                    documentRef.isUploading = false;
                    documentRef.fileUploadPercentage = 0;
                }
            );
    }

    public ngOnInit() {
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];
        this.contractPropertiesContent = this.contractPropertiesService.getContractPropertiesContent();

        this.resolve();
    }

    public onRetrieveUploadedFile() {
        const fileName = this.contract.contract_document;

        if (!fileName) {
            return;
        }

        const matchedFileName = fileName
            .split('/')
            .slice(4)
            .join('/');
        this.uploadFilesViewModel.isUploaded = true;
        this.uploadFilesViewModel.fileName = matchedFileName;
    }

    public onViewDocumentClicked() {
        this.transportContractProperties
            .retrieveContract(this.contract.id, this.carrierAccountId)
            .subscribe((result) => {
                const windowOpened = window.open(result.contract_document_temp_url, '_blank');
                windowOpened.focus();
            });
    }

    public onRemoveFile() {
        const contract: any = omitBy({ ...this.contract, ...this.contractPropertiesForm.getRawValue() }, isNil);

        this.transportContractProperties.removeUploadedFile(contract, this.carrierAccountId).subscribe(() => {
            this.uploadFilesViewModel.fileName = '';
            this.uploadFilesViewModel.isUploaded = false;
        });
    }

    public onUploadCancelClicked() {
        const contract: any = omitBy({ ...this.contract, ...this.contractPropertiesForm.getRawValue() }, isNil);

        this.transportContractProperties.removeUploadedFile(contract, this.carrierAccountId).subscribe(() => {
            this.uploadFilesViewModel.fileName = '';
            this.uploadFilesViewModel.isUploaded = false;
        });
    }

    public previousStep() {
        return of(false);
    }

    public validateAndSave() {
        const contract = pickBy({ ...this.contract, ...this.contractPropertiesForm.getRawValue() }, (_, k) =>
            this.contractPropertiesForm.contains(k)
        );

        this.contractPropertiesForm.markAsDirty();
        this.contractPropertiesForm.updateValueAndValidity();
        this.footerStateService.setContract(contract);

        return of(this.contractPropertiesForm.valid).pipe(
            catchError(() => of(false)),
            map((valid) => !!valid)
        );
    }

    get f() {
        return this.contractPropertiesForm.controls;
    }

    public onCurrencyChange(value: any) {
        this.contractPropertiesForm.patchValue({
            currency_iso3_code: value.currency
        });
    }

    public onWeightUnitChange(value: any) {
        this.contractPropertiesForm.patchValue({
            weight_unit: value.weightUnitSymbol
        });
    }

    public uploadFileOnSelectedFile(payload: any) {
        this.handleSelectedFiles([payload.selectedFile], payload.viewModel);
    }
}
