import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { get } from 'lodash';
import { withActiveByIndex } from '../../common/scripts/utils/header.utils';
import { URL_CONFIG } from '../../config/url.config';
import { WIZARD_HEADER } from './wizard-header.config';
import { combineLatest } from 'rxjs';
import { FooterStateService } from '../../common/scripts/services/footer-state.service';

@Component({
    selector: 'wizard-header',
    templateUrl: './wizard-header.component.html'
})
export class WizardHeaderComponent implements OnInit {
    public accountInformation: any;

    public nav: any = {
        items: withActiveByIndex(0)
    };

    public preventLeaveModal = {
        isVisible: false,
        title: WIZARD_HEADER.LEAVING_MODAL.title,
        message: WIZARD_HEADER.LEAVING_MODAL.message,
        submit: {
            button_text: WIZARD_HEADER.LEAVING_MODAL.submit_text,
            callback: () => this.saveDraft()
        },
        cancel: {
            button_text: WIZARD_HEADER.LEAVING_MODAL.cancel_text,
            callback: () => {
                this.footerStateService.setContract(null);
                this.router.navigate([URL_CONFIG.CAS.DASHBOARD.INDEX], {
                    relativeTo: this.activatedRoute.parent,
                    state: { force: true }
                });
            }
        }
    };

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private footerStateService: FooterStateService
    ) {}

    private observeNavigation() {
        this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => this.handleNavigationEnd());
    }

    private handleNavigationEnd() {
        const nav = this.router.getCurrentNavigation();

        const hasStateForced = get(nav, 'extras.state.force', false);

        if (!nav || hasStateForced) {
            return;
        }

        const stepIdx = get(nav, 'extras.state.step.index');
        this.nav = { items: withActiveByIndex(stepIdx) };
    }

    public saveDraft() {
        this.footerStateService.setContract(null);
        this.router.navigate([URL_CONFIG.CAS.DASHBOARD.INDEX], {
            relativeTo: this.activatedRoute.parent,
            state: {
                force: true,
                saveAsDraft: true
            }
        });
    }

    public ngOnInit() {
        this.observeNavigation();

        combineLatest(this.activatedRoute.firstChild.data, this.activatedRoute.data).subscribe(
            ([
                {
                    contract: { account_carrier_service_type_name }
                },
                {
                    accountData: { accountInformation }
                }
            ]) => {
                accountInformation.carrier = {
                    ...accountInformation.carrier,
                    account_carrier_service_type_name
                };

                this.accountInformation = accountInformation;
            }
        );
    }

    public togglePreventLeaveModal() {
        this.preventLeaveModal.isVisible = true;
    }
}
