import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractResolver } from './contract.resolver';
import { WizardResolver } from './wizard.resolver';
import { DashboardResolver } from './dashboard.resolver';
import { ServiceLevelResolver } from './service-levels.resolver';
import { WarehousesResolver } from './warehouses.resolver';

@NgModule({
    imports: [CommonModule],
    providers: [ContractResolver, WizardResolver, DashboardResolver, ServiceLevelResolver, WarehousesResolver]
})
export class ResolversModule {}
