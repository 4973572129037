
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransitTimesTransportService } from '../transport/transit-times-transport.service';

@Injectable()
export class ServiceLevelResolver  {
    constructor(private transport: TransitTimesTransportService) {}

    resolve(): Observable<any> {
        return this.transport.getServiceLevels().pipe(map(({ results }) => results));
    }
}
