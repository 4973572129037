import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    HostListener,
    ElementRef
} from '@angular/core';
import { get } from 'lodash';

@Component({
    selector: 'zones-countries-list-item',
    templateUrl: './zones-countries-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZonesCountriesListItemComponent {
    @Input() public country: any;
    @Input() public canAdd?: boolean;
    @Input() public canEdit?: boolean;
    @Input() public selectable?: boolean;
    @Input() public selected?: boolean;
    @Input() public moveToZones?: any[];

    @Output() public onCountrySelectionChange = new EventEmitter<{ item: any; checked: boolean }>();
    @Output() public onAddToZone = new EventEmitter<any>();
    @Output() public onMoveToZone = new EventEmitter<any>();
    @Output() public onRemoveFromZone = new EventEmitter<any>();

    constructor(private element: ElementRef) {}

    public removeActive = false;
    public selectZoneActive = false;
    public moveToZone = null;

    @HostListener('document:click', ['$event'])
    public onOtherElementClicked($event) {
        if (!this.element.nativeElement.contains(event.target)) {
            this.selectZoneActive = false;
        }
    }

    public onCountrySelection(e) {
        const checked = get(e, 'target.checked', false);
        this.onCountrySelectionChange.emit({ item: this.country, checked });
    }

    public addToZone() {
        this.onAddToZone.emit(this.country);
    }

    public moveTo() {
        this.onMoveToZone.emit({ country: this.country, moveToZoneUuid: this.moveToZone });
    }

    public remove() {
        this.onRemoveFromZone.emit(this.country);
    }

    public selectDelete() {
        this.removeActive = !this.removeActive;
        this.selectZoneActive = false;
    }

    public selectNewZone() {
        this.removeActive = false;
        this.selectZoneActive = !this.selectZoneActive;
    }
}
