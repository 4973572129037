export interface WizardStepsSpec {
    [key: string]: WizardStepSpec;
}

export interface WizardStepSpec {
    index: number;
    text: string;
    path: string;
    footer: WizardStepFooterSpec;
}

export interface WizardStepFooterSpec {
    prev: WizardStepFooterItemSpec;
    next: WizardStepFooterItemSpec;
    edit?: WizardStepFooterItemSpec;
    restore?: WizardStepFooterItemSpec;
    clearAllAndEdit?: WizardStepFooterItemSpec;
    finish?: WizardStepFooterItemSpec;
}

export interface WizardStepFooterItemSpec {
    display: boolean;
    text?: string;
    path?: string;
}

export const WIZARD_STEPS: WizardStepsSpec = {
    SET_CONTRACT_PROPERTIES: {
        index: 0,
        text: 'Set contract properties',
        path: 'contract-properties',
        footer: {
            prev: {
                display: false
            },
            next: {
                display: true
            }
        }
    },
    COUNTRY_ZONES: {
        index: 1,
        text: 'Country zones',
        path: 'zones',
        footer: {
            prev: {
                display: true
            },
            next: {
                display: true
            },
            edit: {
                display: true,
                text: 'Edit'
            },
            restore: {
                display: true
            },
            clearAllAndEdit: {
                display: true
            }
        }
    },
    INCOTERM_SERVICE_TYPE: {
        index: 2,
        text: 'Incoterm service type',
        path: 'incoterm-service-type',
        footer: {
            prev: {
                display: true
            },
            next: {
                display: true
            }
        }
    },
    COST_BASE_TABLE: {
        index: 3,
        text: 'Cost base table',
        path: 'cost-base-table',
        footer: {
            prev: {
                display: true
            },
            next: {
                display: true
            }
        }
    },
    SPECIAL_PACKS: {
        index: 4,
        text: 'Special packs',
        path: 'special-packs',
        footer: {
            prev: {
                display: true
            },
            next: {
                display: true
            }
        }
    },
    ADDITIONAL_CHARGING_RULES: {
        index: 5,
        text: 'Additional charging rules',
        path: 'additional-charging-rules',
        footer: {
            prev: {
                display: true
            },
            next: {
                display: true
            }
        }
    },
    TRANSIT_TIMES: {
        index: 6,
        text: 'Transit times',
        path: 'transit-times',
        footer: {
            prev: {
                display: true
            },
            next: {
                display: true
            }
        }
    },
    START_END_DATE: {
        index: 7,
        text: 'Start and end dates',
        path: 'start-end-date',
        footer: {
            prev: {
                display: true
            },
            next: {
                display: true
            }
        }
    },
    WAREHOUSE_SELECTION: {
        index: 8,
        text: 'Warehouse selection',
        path: 'warehouse',
        footer: {
            prev: {
                display: true
            },
            next: {
                display: false
            },
            finish: {
                display: true
            }
        }
    }
};
