import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from '@maersk-global/huub-utils';


@Injectable({
    providedIn: 'root'
})
export class AuthHeaderInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return of(UserService.userToken).pipe(
            switchMap((jwt) => {
                const clone = req.clone({
                    setHeaders: { jwt: jwt }
                });

                return next.handle(clone);
            })
        );
    }
}
