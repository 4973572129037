
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardTransportService } from '../transport/dashboard.transport.service';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardResolver  {
    constructor(private transport: DashboardTransportService) {}

    resolve(): Observable<any> {
        return this.transport.getCarrierAccountServiceTypesList().pipe(
            map((accountInformation) => ({
                accountInformation
            }))
        );
    }
}
