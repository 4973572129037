import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HuubMaterialLibModule } from '@maersk-global/huub-material';
import {
    UrlBuilderModule,
    UserServiceModule,
    CountriesListHelperModule,
    CurrenciesListHelperModule,
    WeightUnitsListHelperModule
} from '@maersk-global/huub-utils';
import { DatesHelper } from './common/scripts/helpers/dates.helper';
import { RouterModule } from '@angular/router';
import { ROUTES } from './config/cas.routes';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReactiveFormsModule } from '@angular/forms';

/* Modules */
import { GuardsModule } from './common/scripts/guards/guards.module';
import { ResolversModule } from './common/scripts/resolvers/resolvers.module';
import { ModalsModule } from './components/modals/modals.module';

/* Components */
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { IncotermServiceTypeComponent } from './pages/wizard/incoterm-service-type/incoterm-service-type.component';
import { ZonesComponent } from './pages/wizard/zones/zones.component';
import { ContractPropertiesComponent } from './pages/wizard/contract-properties/contract-properties.component';
import { CostBaseTableComponent } from './pages/wizard/cost-base-table/cost-base-table.component';
import { SpecialPacksComponent } from './pages/wizard/special-packs/special-packs.component';
import { AdditionalChargingRulesComponent } from './pages/wizard/additional-charging-rules/additional-charging-rules.component';
import { TaxableWeightComponent } from './pages/wizard/taxable-weight/taxable-weight.component';
import { TransitTimesComponent } from './pages/wizard/transit-times/transit-times.component';
import { StartEndDateComponent } from './pages/wizard/start-end-date/start-end-date.component';
import { WizardShellComponent } from './components/wizard-shell/wizard-shell.component';
import { WizardHeaderComponent } from './components/wizard-header/wizard-header.component';
import { WizardFooterComponent } from './components/wizard-footer/wizard-footer.component';
import { UiCardComponent } from './components/ui-card/ui-card.component';
import { UiNumberInCircleComponent } from './components/ui-number-in-circle/ui-number-in-circle.component';
import { ContractInfoComponent } from './components/contract-info/contract-info.component';
import { NavDropdownComponent } from './components/nav-dropdown/nav-dropdown.component';
import { DropdownItemComponent } from './components/nav-dropdown/dropdown-item/dropdown-item.component';
import { ZonesTabsComponent } from './components/zones-tabs/zones-tabs.component';
import { DashboardItemComponent } from './components/dashboard-item/dashboard-item.component';
import { DashboardItemContractComponent } from './components/dashboard-item-contract/dashboard-item-contract.component';
import { FuelsurchargeContractComponent } from './components/fuelsurcharge-contract/fuelsurcharge-contract.component';
import { WarehouseSelectionComponent } from './pages/wizard/warehouse/warehouse.component';

/* Transport */

/* View Model Helpers */

/* Helpers */
import { MessagingService } from './common/scripts/services/messaging.service';

/* Interceptors */
import { AuthHeaderInterceptor } from './common/scripts/interceptors/auth-header-interceptor';
import { UiModalReimportComponent } from './components/ui-modal-reimport/ui-modal-reimport.component';
import { UiCurrenciesComponent } from './components/ui-currencies/ui-currencies.component';
import { WeightUnitsComponent } from './components/weight-units/weight-units.component';
import { ComponentsModule } from './components/components.module';
import { UiModalConfirmationRulesComponent } from './components/ui-modal-confirmation-rules/ui-modal-confirmation-rules.component';
import { UiModalOverlapComponent } from './components/ui-modal-overlap/ui-modal-overlap.component';
import { UiModalGapComponent } from './components/ui-modal-gap/ui-modal-gap.component';
import { UiModalRemoveContractComponent } from './components/ui-modal-remove-contract/ui-modal-remove.component';
import { UiModalCarrierAccountServiceActiveComponent } from './components/ui-modal-carrier-account-service-activity/ui-modal-carrier-account-service-activity.component';
import { UiModalFuelsurchargeComponent } from './components/ui-modal-fuelsurcharge/ui-modal-fuelsurcharge.component';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { CalculateBaseHrefFactory } from '../src/config/calculate-base-href.factory';
import { URL_CONFIG } from '../src/config/url.config';

@NgModule({
    declarations: [
        ContractPropertiesComponent,
        IncotermServiceTypeComponent,
        ZonesComponent,
        CostBaseTableComponent,
        SpecialPacksComponent,
        AdditionalChargingRulesComponent,
        TaxableWeightComponent,
        TransitTimesComponent,
        StartEndDateComponent,
        WizardShellComponent,
        WizardHeaderComponent,
        WizardFooterComponent,
        DashboardComponent,
        UiCardComponent,
        UiNumberInCircleComponent,
        ContractInfoComponent,
        NavDropdownComponent,
        DropdownItemComponent,
        ZonesTabsComponent,
        UiModalReimportComponent,
        UiCurrenciesComponent,
        WeightUnitsComponent,
        UiModalConfirmationRulesComponent,
        UiModalOverlapComponent,
        UiModalGapComponent,
        DashboardItemComponent,
        DashboardItemContractComponent,
        UiModalRemoveContractComponent,
        UiModalCarrierAccountServiceActiveComponent,
        UiModalFuelsurchargeComponent,
        FuelsurchargeContractComponent,
        WarehouseSelectionComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forChild(ROUTES),
        HuubMaterialLibModule,
        UrlBuilderModule,
        GuardsModule,
        ResolversModule,
        HttpClientModule,
        UserServiceModule,
        CountriesListHelperModule,
        ReactiveFormsModule,
        AngularSvgIconModule.forRoot(),
        CurrenciesListHelperModule,
        WeightUnitsListHelperModule,
        ModalsModule,
        ComponentsModule
    ],
    providers: [
        DatesHelper,
        MessagingService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true }
    ],
    exports: [
        BrowserModule,
        HuubMaterialLibModule,
        UrlBuilderModule,
        HttpClientModule,
        UserServiceModule,
        ReactiveFormsModule
    ]
})
export class CasModule {
    static setEnvironment(ENVIRONMENT): ModuleWithProviders<CasModule> {
        return {
            ngModule: CasModule,
            providers: ENVIRONMENT.IS_ECL
                ? [
                      {
                          provide: 'ENVIRONMENT',
                          useValue: ENVIRONMENT
                      }
                  ]
                : [
                      {
                          provide: 'ENVIRONMENT',
                          useValue: ENVIRONMENT
                      },
                      { provide: APP_BASE_HREF, useFactory: CalculateBaseHrefFactory, deps: [PlatformLocation] }
                  ]
        };
    }

    constructor(@Inject('ENVIRONMENT') ENVIRONMENT) {
        if (!ENVIRONMENT) {
            throw new Error(`
                For this module to be able to work you need to configure the ENVIRONMENT.
                Did you try to inject this module like this?

                imports: [
                    Imports.setEnvironment(environment)
                ]
            `);
        }
        UrlBuilderModule.setUrlConfig(URL_CONFIG);
    }
}
