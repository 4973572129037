import { EventEmitter, Inject, Injectable } from '@angular/core';
import { API_PATHS } from '../constants/api.constant';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../services/api/api.service';
import { ContractModel } from '../models/contract.model';
import { map } from 'rxjs/operators';
import { UiUploadFileEventsHelper } from '@maersk-global/huub-material';
import { ContractStatus } from '../models/contract-status';

const multiPartHttpOptions: any = {
    headers: {},
    observe: 'events',
    reportProgress: true
};

@Injectable({
    providedIn: 'root'
})
export class ContractTransportService {
    endpoint: string;
    contractUpdated: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private apiService: ApiService,
        private uploadEventsHelper: UiUploadFileEventsHelper,
        @Inject('ENVIRONMENT') ENVIRONMENT
    ) {
        this.endpoint = ENVIRONMENT.API_ENDPOINT;
    }

    public createContract(
        carrierAccountId: number | string,
        carrierAccountServiceTypeId: number | string
    ): Observable<ContractModel> {
        const path = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/`;
        const url = `${this.endpoint}${path}`;

        return this.apiService.post(url, {
            account_carrier_service_type: carrierAccountServiceTypeId,
            status: ContractStatus.Draft
        });
    }

    public patchContract(contract: any, carrierAccountId: number, isToPublish = false): Observable<ContractModel> {
        const path = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${contract.id}/`;
        const url = `${this.endpoint}${path}`;

        contract.status = isToPublish ? ContractStatus.Published : ContractStatus.Draft;

        return this.apiService.patch(url, contract);
    }

    public removeUploadedFile(contract: ContractModel, carrierAccountId: number): Observable<ContractModel> {
        const path = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${contract.id}/`;
        const url = `${this.endpoint}${path}`;

        if (contract.contract_document !== null) {
            contract.contract_document = null;
        }

        return this.apiService.patch(url, contract);
    }

    public getAccountInformation(carrierAccountId: number | string): Observable<any> {
        const path = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/`;
        const url = `${this.endpoint}${path}`;

        return this.apiService.get(url);
    }

    public retrieveContract(id: number | string, carrierAccountId: number | string): Observable<any> {
        const path = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${id}/`;
        const url = `${this.endpoint}${path}`;

        return this.apiService.get(url);
    }

    public uploadDocuments(uploadedFile: File, contract: any, carrierAccountId: number): Observable<any> {
        const relativePath = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${contract.id}/document/`;
        const url = `${this.endpoint}${relativePath}`;
        const formData = new FormData();

        formData.append('contract_file_document', uploadedFile);

        return this.apiService
            .patch(url, formData, multiPartHttpOptions)
            .pipe(map((event: any) => this.uploadEventsHelper.getAbstractedEventsDataModel(event)));
    }

    public validateDate(
        carrierAccountId: number | string,
        contractId: number | string,
        startDate: string
    ): Observable<any> {
        const path = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${contractId}/validate-date/`;
        const url = `${this.endpoint}${path}`;

        return this.apiService.post(url, {
            start_date: startDate
        });
    }
}
