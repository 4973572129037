import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WizardShellComponent } from '../components/wizard-shell/wizard-shell.component';
import { ROUTES as WIZARD_ROUTES } from './wizard/wizard.routes';
import { InitWizardGuard } from '../common/scripts/guards/init-wizard.guard';
import { WizardResolver } from '../common/scripts/resolvers/wizard.resolver';
import { DashboardResolver } from '../common/scripts/resolvers/dashboard.resolver';
import { ServiceLevelResolver } from '../common/scripts/resolvers/service-levels.resolver';
import { WarehousesResolver } from '../common/scripts/resolvers/warehouses.resolver';

export const ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [],
        resolve: {
            carrierAccountsList: DashboardResolver
        },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'wizard',
        component: WizardShellComponent,
        canActivateChild: [InitWizardGuard],
        resolve: {
            accountData: WizardResolver,
            serviceLevels: ServiceLevelResolver,
            warehouses: WarehousesResolver
        },
        runGuardsAndResolvers: 'always',
        children: WIZARD_ROUTES
    }
];
