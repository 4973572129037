import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../../common/scripts/services/messaging.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'wizard-shell',
    templateUrl: './wizard-shell.component.html'
})
export class WizardShellComponent implements OnInit {
    public messagingActive$: Observable<boolean>;
    public messagingMsg$: Observable<string>;
    public loadingActive$: Observable<boolean>;
    public messagingType$: Observable<boolean>;

    constructor(private messaging: MessagingService) {}

    public ngOnInit(): void {
        this.loadingActive$ = this.messaging.loading$;
        this.messagingActive$ = this.messaging.active$;
        this.messagingMsg$ = this.messaging.message$;
        this.messagingType$ = this.messaging.messageType$;
    }
}
