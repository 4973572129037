import { Injectable } from '@angular/core';
import { ContractPropertiesInput } from '../interfaces/contract-properties';
import { GLOBAL } from '../constants/global.constant';

@Injectable({
    providedIn: 'root'
})
export class ContractPropertiesService {
    constructor() {}

    private contractPropertiesContent: ContractPropertiesInput = {
        stepNumber: 1,
        paymentConditions: GLOBAL.CONTRACT_PROPERTIES.PAYMENT_CONDITIONS,
        creditDays: GLOBAL.CONTRACT_PROPERTIES.CREDIT_DAYS
    };

    public getContractPropertiesContent(): ContractPropertiesInput {
        return this.contractPropertiesContent;
    }
}
