import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TransitTimesService } from '../../../common/scripts/services/transit-times.service';
import { get, cloneDeep } from 'lodash';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { of } from 'rxjs';
import { FooterStateService, FooterBtns } from '../../../common/scripts/services/footer-state.service';
import { CountriesListHelper } from '@maersk-global/huub-utils';
import { catchError, switchMap, map } from 'rxjs/operators';
import { ContractTransportService } from '../../../common/scripts/transport/contract-transport.service';

const DEFAULT_TRANSIT_TIME_FOR_COUNTRY = {
    lower_bound_days: 0,
    upper_bound_days: 0,
    is_business_days: false,
    service_level: 1
};

@Component({
    selector: 'transit-times',
    templateUrl: './transit-times.component.html'
})
export class TransitTimesComponent extends AbstractWizardStep implements OnInit, IsWizardStep {
    public contriesNameMapped: any = {};
    public transitTimesData: any;
    public carrierAccountId: any;
    public defaultUnit: boolean = DEFAULT_TRANSIT_TIME_FOR_COUNTRY.is_business_days;
    public defaultServiceLevel: number = DEFAULT_TRANSIT_TIME_FOR_COUNTRY.service_level;
    public continentTabsViewModel = {
        items: [],
        selectedIndex: 0
    };

    public contract: any = {};
    public stepNumber: number;

    public serviceLevels: Array<any>;

    constructor(
        private transitTimesService: TransitTimesService,
        private transportContractProperties: ContractTransportService,
        private activatedRoute: ActivatedRoute,
        private footerStateService: FooterStateService,
        private countriesListHelper: CountriesListHelper
    ) {
        super();
    }

    protected setupFooter() {
        const btns = [
            {
                btn: FooterBtns.NEXT,
                state: { visible: true, enabled: true, text: 'Dates' }
            },
            {
                btn: FooterBtns.PREV,
                state: { visible: true, enabled: true, text: 'Additional charging rules' }
            }
        ];

        this.footerStateService.toggleAll(btns);
    }

    public ngOnInit() {
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];
        this.countriesListHelper.getCountriesList().forEach((country) => {
            this.contriesNameMapped[country.iso2code] = country.name;
        });
        this.transitTimesData = this.transitTimesService.getTransitTimesData();

        this.activatedRoute.parent.data.subscribe(({ serviceLevels }) => {
            this.serviceLevels = serviceLevels;
        });

        this.activatedRoute.data.subscribe((data) => {
            this.continentTabsViewModel.items.length = 0;

            this.contract = get(data, 'contract', {});
            this.stepNumber = data.step.index + 1;

            let countryNameIndex = 1;
            this.contract.zones.forEach((zone: any) => {
                const countryName = `${countryNameIndex++}. ${zone.name}`;

                this.continentTabsViewModel.items.push({
                    text: countryName
                });

                zone.countries.forEach((country: any) => {
                    if (!country.transit_times) {
                        country.transit_times = { ...DEFAULT_TRANSIT_TIME_FOR_COUNTRY };
                    }

                    country.validation_errors = {
                        lowerBoundHasError: false,
                        upperBoundHasError: false
                    };
                });
            });
        });

        this.setupFooter();
    }

    public previousStep() {
        const contract = cloneDeep(this.contract);

        contract.zones.forEach((zone: any) => {
            zone.countries.forEach((country: any) => {
                delete country.validation_errors;
            });
        });
        this.footerStateService.setContract(contract);
        return of(true);
    }

    public validateAndSave() {
        let isFormValid = true;
        this.contract.zones.forEach((zone: any) => {
            zone.countries.forEach((country: any) => {
                country.validation_errors.lowerBoundHasError = false;
                country.validation_errors.upperBoundHasError = false;

                if (isNaN(Number(country.transit_times.lower_bound_days))) {
                    country.validation_errors.lowerBoundHasError = true;
                    isFormValid = false;
                }

                if (isNaN(Number(country.transit_times.upper_bound_days))) {
                    country.validation_errors.upperBoundHasError = true;
                    isFormValid = false;
                }

                if (
                    Number(country.transit_times.lower_bound_days) > Number(country.transit_times.upper_bound_days) ||
                    Number(country.transit_times.lower_bound_days) === Number(country.transit_times.upper_bound_days)
                ) {
                    country.validation_errors.lowerBoundHasError = true;
                    country.validation_errors.upperBoundHasError = true;
                    isFormValid = false;
                    return;
                }
            });
        });

        return of(isFormValid).pipe(
            catchError(() => of(false)),
            switchMap((valid: boolean) => {
                if (valid) {
                    const contract = cloneDeep(this.contract);

                    contract.zones.forEach((zone: any) => {
                        zone.countries.forEach((country: any) => {
                            delete country.validation_errors;
                        });
                    });
                    this.footerStateService.setContract(contract);
                }
                return of(valid);
            }),
            map((valid: boolean) => !!valid)
        );
    }

    public onDefaultUnitChange() {
        this.contract.zones.forEach((zone: any) => {
            zone.countries.forEach((country: any) => {
                country.transit_times.is_business_days = this.defaultUnit;
            });
        });
    }

    public onDefaultServiceLevelChange() {
        this.contract.zones.forEach((zone: any) => {
            zone.countries.forEach((country: any) => {
                country.transit_times.service_level = this.defaultServiceLevel;
            });
        });
    }
}
