export const ZONE_CONFIG = {
    empty_zone_message: 'There are no countries selected for this zone.',
    clear_all: 'Clear all',
    restore: 'Restore',
    clear_all_message:
        'If you want to build your zones from scratch the cost base table and the special packs table will be removed and you will have to import them again. Are you sure you want to proceed with this action?',
    new_zone: 'New zone',
    one_zone_required: 'Please create at least one zone',
    manage_zones: 'Manage zones'
};
