import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService {
    private warehouseData: any = {
        stepNumber: 9
    };

    constructor() {}

    public getWarehouseData(): any {
        return this.warehouseData;
    }
}
