import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, MessagingService, RoutingService } from '@maersk-global/huub-utils';

@Component({
    selector: 'cas-app',
    templateUrl: 'init.component.html'
})
export class InitComponent {
    eclPlatformUrl: string;
    isBeingDisplayedAtECL = false;

    constructor(@Inject('ENVIRONMENT') ENVIRONMENT, private router: Router) {
        this.eclPlatformUrl = ENVIRONMENT.ECL_PLATFORM_URL;
        this.isBeingDisplayedAtECL = ENVIRONMENT.IS_ECL;
    }

    ngOnInit(): void {
        if (this.isBeingDisplayedAtECL) {
            this.addAuthListener();
            RoutingService.addRouteListener(this.router, this.eclPlatformUrl);
        }
    }

    addAuthListener(): void {
        window.addEventListener('message', (message) => {
            MessagingService.handleSetTokenMessageEvent(message, [this.eclPlatformUrl], this.router, () => {});
        });
    }
}
