import { Injectable } from '@angular/core';
import { GLOBAL } from '../constants/dashboard.constant';
import * as moment_ from 'moment-mini-ts';
const moment = moment_;

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    constructor() {}
    private _getStatusFromDate(startDate, endDate) {
        const today = moment()
            .startOf('day')
            .utc();
        if (
            moment(endDate)
                .utc()
                .isBefore(today, 'day')
        ) {
            return GLOBAL.CONTRACT_STATUS.EXPIRED;
        } else if (today.isBefore(moment(startDate).utc(), 'day')) {
            return GLOBAL.CONTRACT_STATUS.SCHEDULED;
        }
        return GLOBAL.CONTRACT_STATUS.UP_TO_DATE;
    }

    private _getContractObject(contract, carrierAccountId, carrierAccountServiceTypeId) {
        if (contract) {
            return {
                id: contract.id,
                carrierAccountId,
                carrierAccountServiceTypeId,
                status:
                    contract.status === GLOBAL.CONTRACT_STATUS.DRAFT
                        ? contract.status
                        : this._getStatusFromDate(contract.start_date, contract.end_date),
                expiryDate: moment(contract.end_date).format('MMMM DD, YYYY')
            };
        }
        return { carrierAccountId, carrierAccountServiceTypeId };
    }

    private _getFuelSurchargeContractObject(contract, carrierAccountServiceTypeId) {
        if (contract) {
            return {
                id: contract.id,
                carrierAccountServiceTypeId,
                status: this._getStatusFromDate(contract.start_date, contract.end_date),
                value: parseFloat(contract.fee).toFixed(2),
                startDate: moment(contract.start_date).format('DD/MM/YYYY'),
                endDate: moment(contract.end_date).format('DD/MM/YYYY')
            };
        }
        return null;
    }

    public getFilterTagsViewModel() {
        return {
            items: []
        };
    }

    public getSearchViewModel() {
        return {
            placeholder: GLOBAL.SEARCH_PLACEHOLDER,
            value: ''
        };
    }

    public getCarrierIconName(carrier) {
        return GLOBAL.CARRIERS.find((carrierName) => carrier.toLowerCase().includes(carrierName)) || '';
    }

    public getTabsViewModel() {
        return {
            items: [
                {
                    text: 'All',
                    value: 'all'
                },
                {
                    text: 'Expired',
                    value: 'expired'
                },
                {
                    text: 'To create',
                    value: 'to_create'
                },
                {
                    text: 'Up to date',
                    value: 'up_to_date'
                },
                {
                    text: 'Draft',
                    value: 'draft'
                },
                {
                    text: 'Scheduled',
                    value: 'scheduled'
                }
            ],
            selectedIndex: 0
        };
    }

    public getFilterViewModel(owners: Array<any>, originCountries: Array<any>, carriers: Array<any>) {
        const fuelSurchargeStatus = [
            {
                text: 'Up to date',
                field: 'fuelsurcharge_status',
                value: 'up_to_date'
            },
            {
                text: 'Expired',
                field: 'fuelsurcharge_status',
                value: 'expired'
            },
            {
                text: 'To create or activate',
                field: 'fuelsurcharge_status',
                value: 'to_create_or_activate'
            },
            {
                text: 'Scheduled',
                field: 'fuelsurcharge_status',
                value: 'scheduled'
            }
        ];

        return {
            filterText: 'Filters',
            buttonText: 'Add',
            selectBox: [
                {
                    text: 'Choose one filter'
                },

                {
                    text: 'Activity',
                    field: 'activity',
                    type: 'activity'
                },
                {
                    text: 'Owner',
                    field: 'owner',
                    type: 'owner'
                },
                {
                    text: 'Origin country',
                    field: 'origin_country',
                    type: 'origin_country'
                },
                {
                    text: 'Carrier',
                    field: 'carrier',
                    type: 'carrier'
                },
                {
                    text: 'Fuel Surcharge',
                    field: 'fuel_surcharge',
                    type: 'fuel_surcharge'
                }
            ],

            selectOptions: {
                activity: [
                    {
                        text: 'Active',
                        field: 'is_active',
                        value: true
                    },
                    {
                        text: 'Inactive',
                        field: 'is_active',
                        value: false
                    }
                ],
                owner: owners.map((owner) => ({
                    text: owner,
                    field: 'owner_name',
                    value: owner
                })),
                origin_country: originCountries.map((country) => ({
                    text: country,
                    field: 'account_country',
                    value: country
                })),
                carrier: carriers.map((carrier) => ({
                    text: carrier,
                    field: 'carrier_name',
                    value: carrier
                })),
                fuel_surcharge: fuelSurchargeStatus
            }
        };
    }

    public getListViewModel(carrierAccountsList) {
        return carrierAccountsList.map((item) => {
            const {
                id,
                owner_name: owner,

                account_id: accountId,
                account_code: code,
                account_country: originCountry,

                is_active: isActive,
                carrier_name: carrier,
                carrier_logo_url: logo,
                service_type_name: serviceType,
                current_contract,
                previous_contract,
                next_contract,
                is_fuel_surcharge_active: isFuelsurchargeActive,
                current_fuel_surcharge: currentSurcharge,
                next_fuel_surcharge: nextSurcharge,
                previous_fuel_surcharge: previousSurcharge
            } = item;

            const currentFuelSurcharge = this._getFuelSurchargeContractObject(
                currentSurcharge || previousSurcharge,
                id
            );

            const nextFuelSurcharge = this._getFuelSurchargeContractObject(nextSurcharge, id);
            const currentContract = this._getContractObject(current_contract || previous_contract, accountId, id);
            const nextContract = this._getContractObject(next_contract, accountId, id);

            return {
                isActive,
                isFuelsurchargeActive,
                carrierServiceTypeAccount: {
                    id,
                    carrier,
                    serviceType,
                    code,
                    logo
                },
                originCountry,
                owner,
                currentContract,
                nextContract,
                currentFuelSurcharge,
                nextFuelSurcharge
            };
        });
    }
}
