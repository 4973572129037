import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'ui-modal-overlap',
    templateUrl: './ui-modal-overlap.component.html'
})
export class UiModalOverlapComponent implements OnInit {
    constructor(@Inject('MODAL_DATA') public viewModel: any) {}

    public ngOnInit() {}
}
