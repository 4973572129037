export const GLOBAL = {
    CARRIERS: ['ctt', 'dhl', 'fedex', 'tnt', 'ups'],
    CONTRACT_STATUS: {
        NOT_CREATED: 'Not created',
        EXPIRED: 'Expired',
        DRAFT: 'Draft',
        UP_TO_DATE: 'Up to date',
        SCHEDULED: 'Scheduled'
    },
    LIST_SIZE: 10,
    WEEK_DAYS: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    MONTH_NAMES: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],
    SEARCH_PLACEHOLDER: 'Search by carrier, service type or account code'
};
