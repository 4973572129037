import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_PATHS } from '../constants/api.constant';
import { ApiService } from '../services/api/api.service';
import { environment } from '../../../../environments/environment';
import { GLOBAL } from '../constants/dashboard.constant';
import { HttpParams } from '@angular/common/http';

import * as moment_ from 'moment-mini-ts';
const moment = moment_;

@Injectable({
    providedIn: 'root'
})
export class DashboardTransportService {
    endpoint: string;
    constructor(private apiService: ApiService, @Inject('ENVIRONMENT') ENVIRONMENT) {
        this.endpoint = ENVIRONMENT.API_ENDPOINT;
    }

    public getCarrierAccountServiceTypesList(
        page = 1,
        search = '',
        filters = [],
        page_size = GLOBAL.LIST_SIZE
    ): Observable<any> {
        const url = `${this.endpoint}/${API_PATHS.ACCOUNT_CARRIER_SERVICE_TYPE_LIST}/`;

        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('page_size', page_size.toString());
        params = params.append('ordering', 'carrier_name');

        if (search.length > 0) {
            params = params.append('search', search);
        }
        filters.forEach((filter) => {
            params = params.append(filter.field, filter.value);
        });
        return this.apiService.get(url, { params });
    }

    public activateDeactivateCarrierAccountServiceType(carrierAccountServiceTypeId, activity): Observable<any> {
        const url = `${this.endpoint}/${API_PATHS.ACCOUNT_CARRIER_SERVICE_TYPE}/${carrierAccountServiceTypeId}/`;
        return this.apiService.patch(url, { is_active: activity });
    }

    public activateDeactivateFuelSurcharge(carrierAccountServiceTypeId, activity): Observable<any> {
        const url = `${this.endpoint}/${API_PATHS.ACCOUNT_CARRIER_SERVICE_TYPE}/${carrierAccountServiceTypeId}/`;
        return this.apiService.patch(url, { is_fuel_surcharge_active: activity });
    }

    public deleteContract(carrierAccountServiceTypeId, contractId): Observable<any> {
        const url = `${this.endpoint}/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountServiceTypeId}/${API_PATHS.CONTRACTS}/${contractId}/`;
        return this.apiService.delete(url);
    }

    public editFuelSurchargeContract(carrierAccountServiceTypeId, contractId, contractData): Observable<any> {
        const url = `${this.endpoint}/${API_PATHS.FUEL_SURCHARGES}/${contractId}/`;
        const body = {
            account_carrier_service_type: carrierAccountServiceTypeId,
            fee: contractData.fee,
            start_date: moment(contractData.startDate).format('YYYY-MM-DD'),
            end_date: moment(contractData.endDate).format('YYYY-MM-DD')
        };
        return this.apiService.put(url, body);
    }

    public addFuelSurchargeContract(carrierAccountServiceTypeId, contractData): Observable<any> {
        const url = `${this.endpoint}/${API_PATHS.FUEL_SURCHARGES}/`;
        const body = {
            account_carrier_service_type: carrierAccountServiceTypeId,
            fee: contractData.fee,
            start_date: moment(contractData.startDate).format('YYYY-MM-DD'),
            end_date: moment(contractData.endDate).format('YYYY-MM-DD')
        };
        return this.apiService.post(url, body);
    }

    public deleteFuelSurchargeContract(carrierAccountServiceTypeId, contractId): Observable<any> {
        const url = `${this.endpoint}/${API_PATHS.FUEL_SURCHARGES}/${contractId}/`;
        const body = {
            account_carrier_service_type: carrierAccountServiceTypeId
        };
        return this.apiService.delete(url, body);
    }
}
