import { Routes } from '@angular/router';
import { ContractPropertiesComponent } from './contract-properties/contract-properties.component';
import { IncotermServiceTypeComponent } from './incoterm-service-type/incoterm-service-type.component';
import { ZonesComponent } from './zones/zones.component';
import { CostBaseTableComponent } from './cost-base-table/cost-base-table.component';
import { SpecialPacksComponent } from './special-packs/special-packs.component';
import { AdditionalChargingRulesComponent } from './additional-charging-rules/additional-charging-rules.component';
import { TransitTimesComponent } from './transit-times/transit-times.component';
import { StartEndDateComponent } from './start-end-date/start-end-date.component';
import { WIZARD_STEPS } from '../../common/scripts/constants/wizard-steps.constant';
import { CanSaveWizardStep } from '../../common/scripts/guards/wizard-step.guard';
import { ContractResolver } from '../../common/scripts/resolvers/contract.resolver';
import { WarehouseSelectionComponent } from './warehouse/warehouse.component';

export const ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: WIZARD_STEPS.SET_CONTRACT_PROPERTIES.path
    },
    {
        path: WIZARD_STEPS.SET_CONTRACT_PROPERTIES.path,
        component: ContractPropertiesComponent,
        canDeactivate: [CanSaveWizardStep],
        runGuardsAndResolvers: 'always',
        resolve: {
            contract: ContractResolver
        },
        data: {
            step: WIZARD_STEPS.SET_CONTRACT_PROPERTIES
        }
    },
    {
        path: WIZARD_STEPS.COUNTRY_ZONES.path,
        component: ZonesComponent,
        canDeactivate: [CanSaveWizardStep],
        resolve: {
            contract: ContractResolver
        },
        runGuardsAndResolvers: 'always',
        data: {
            step: WIZARD_STEPS.COUNTRY_ZONES
        }
    },
    {
        path: WIZARD_STEPS.INCOTERM_SERVICE_TYPE.path,
        component: IncotermServiceTypeComponent,
        canDeactivate: [CanSaveWizardStep],
        resolve: {
            contract: ContractResolver
        },
        runGuardsAndResolvers: 'always',
        data: {
            step: WIZARD_STEPS.INCOTERM_SERVICE_TYPE
        }
    },
    {
        path: WIZARD_STEPS.COST_BASE_TABLE.path,
        component: CostBaseTableComponent,
        canDeactivate: [CanSaveWizardStep],
        runGuardsAndResolvers: 'always',
        resolve: {
            contract: ContractResolver
        },
        data: {
            step: WIZARD_STEPS.COST_BASE_TABLE
        }
    },
    {
        path: WIZARD_STEPS.SPECIAL_PACKS.path,
        component: SpecialPacksComponent,
        canDeactivate: [CanSaveWizardStep],
        runGuardsAndResolvers: 'always',
        resolve: {
            contract: ContractResolver
        },
        data: {
            step: WIZARD_STEPS.SPECIAL_PACKS
        }
    },
    {
        path: WIZARD_STEPS.ADDITIONAL_CHARGING_RULES.path,
        component: AdditionalChargingRulesComponent,
        runGuardsAndResolvers: 'always',
        canDeactivate: [CanSaveWizardStep],
        resolve: {
            contract: ContractResolver
        },
        data: {
            step: WIZARD_STEPS.ADDITIONAL_CHARGING_RULES
        }
    },
    {
        path: WIZARD_STEPS.TRANSIT_TIMES.path,
        component: TransitTimesComponent,
        runGuardsAndResolvers: 'always',
        canDeactivate: [CanSaveWizardStep],
        resolve: {
            contract: ContractResolver
        },
        data: {
            step: WIZARD_STEPS.TRANSIT_TIMES
        }
    },
    {
        path: WIZARD_STEPS.START_END_DATE.path,
        component: StartEndDateComponent,
        runGuardsAndResolvers: 'always',
        canDeactivate: [CanSaveWizardStep],
        resolve: {
            contract: ContractResolver
        },
        data: {
            step: WIZARD_STEPS.START_END_DATE
        }
    },
    {
        path: WIZARD_STEPS.WAREHOUSE_SELECTION.path,
        component: WarehouseSelectionComponent,
        runGuardsAndResolvers: 'always',
        canDeactivate: [CanSaveWizardStep],
        resolve: {
            contract: ContractResolver
        },
        data: {
            step: WIZARD_STEPS.WAREHOUSE_SELECTION
        }
    }
];
