import { Component, OnInit } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ContractTransportService } from '../../common/scripts/transport/contract-transport.service';
import { ContractModel } from '../../common/scripts/models/contract.model';
import { get, omitBy, isNil } from 'lodash';

@Component({
    selector: 'ui-modal-countries',
    templateUrl: './ui-modal-countries.component.html'
})
export class UiModalCountriesComponent implements OnInit {
    private countriesSearchSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private carrierAccountId: number;
    private contractId: number;

    public viewModel = {};
    public countriesList$: Observable<any>;
    public contract: ContractModel = {} as ContractModel;
    public selectedTabIndex = 0;

    public countryList = [
        {
            country_iso2_code: ''
        }
    ];

    public searchByCountryList = [];

    constructor(
        private modal: UiModalService,
        private route: ActivatedRoute,
        private activatedRoute: ActivatedRoute,
        private transportContractProperties: ContractTransportService
    ) {}

    public continentTabsViewModel = {
        items: [],
        selectedIndex: 0
    };

    public responseData: {
        [key: string]: {}[];
    } = {};

    public ngOnInit() {
        this.contractId = this.activatedRoute.snapshot.queryParams['contractId'];
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];

        this.resolve();
    }

    public onCountrySearch(search) {
        this.countriesSearchSubject.next(search);
    }

    public onItemsChanged(itemsConfig) {
        // Console.log(itemsConfig);
    }

    public onSubmitClicked() {
        this.modal.close();
    }

    public onTabClicked($event) {
        this.selectedTabIndex = $event.selectedTabIndex;
    }

    public resolve() {
        this.route.data.subscribe((data) => {
            this.contract = get(data, 'contract', {});
        });

        let contract = omitBy({ ...this.contract }, isNil);
        this.transportContractProperties
            .retrieveContract(this.contractId, this.carrierAccountId)
            .subscribe((result) => {
                contract = result;

                let countryNameIndex = 1;
                this.countryList = [];
                result.zones.forEach((value) => {
                    const countryName = `${countryNameIndex++}. ${value.name}`;

                    this.continentTabsViewModel.items.push({
                        text: countryName
                    });

                    value.countries.forEach((country) => {
                        this.countryList.push({
                            country_iso2_code: country.country_iso2_code
                        });
                    });
                });
            });
    }
}
