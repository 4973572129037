import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../common/scripts/services/dashboard.service';
import { DashboardTransportService } from '../../common/scripts/transport/dashboard.transport.service';
import { UiPaginationViewModelHelper } from '@maersk-global/huub-material';
import { GLOBAL } from '../../common/scripts/constants/dashboard.constant';
import { ContractTransportService } from '../../common/scripts/transport/contract-transport.service';

@Component({
    selector: 'cas-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    private searchDelay = null;

    constructor(
        private dashboardService: DashboardService,
        private currentRoute: ActivatedRoute,
        private transport: DashboardTransportService,
        private paginationViewModelHelper: UiPaginationViewModelHelper,
        private contractTransportService: ContractTransportService
    ) {}

    public tabsViewModel: any;
    public searchViewModel: any;
    public filterTagsViewModel: any;
    public filterViewModel: any;
    public listViewModel: Array<any>;
    public paginationViewModel: any;

    public headerViewModel = {
        title: 'Carrier accounts contracts',
        breadcrumbs: []
    };

    private handleContractUpdated() {
        this.contractTransportService.contractUpdated.subscribe((event) => {
            if (event) {
                this.updateList();
            }
        });
    }

    ngOnInit() {
        this.searchViewModel = this.dashboardService.getSearchViewModel();
        this.filterTagsViewModel = this.dashboardService.getFilterTagsViewModel();
        this.tabsViewModel = this.dashboardService.getTabsViewModel();
        this.currentRoute.data.subscribe(({ carrierAccountsList }) => {
            this.loadList(carrierAccountsList.accountInformation);
        });
        this.handleContractUpdated();
    }

    public updateList() {
        let filters = this.filterTagsViewModel.items;
        if (this.tabsViewModel.selectedIndex > 0) {
            filters = [
                ...filters,
                { field: 'contract_status', value: this.tabsViewModel.items[this.tabsViewModel.selectedIndex].value }
            ];
        }
        this.transport
            .getCarrierAccountServiceTypesList(
                this.paginationViewModel.currentPage,
                this.searchViewModel.value,
                filters
            )
            .subscribe((carrierAccountsList) => this.loadList(carrierAccountsList));
    }

    public loadList(carrierAccountsList) {
        const items = carrierAccountsList.data.results;
        const paginator = carrierAccountsList.paginator;
        this.listViewModel = this.dashboardService.getListViewModel(items);
        this.filterViewModel = this.dashboardService.getFilterViewModel(
            carrierAccountsList.data.filter_values.owner_name,
            carrierAccountsList.data.filter_values.account_country,
            carrierAccountsList.data.filter_values.carrier_name
        );
        this.paginationViewModel = this.paginationViewModelHelper.getGenericPaginationViewModel(
            GLOBAL.LIST_SIZE,
            paginator.previous_page_url,
            paginator.next_page_url,
            paginator.page_number,
            paginator.total_pages,
            paginator.total_items_count
        );
        this.searchDelay = null;
    }

    public onSearchInputChanged() {
        if (!this.searchDelay) {
            this.searchDelay = setTimeout(() => this.updateList(), 100);
        }
    }

    public onFilterAdded(selectedFilter) {
        this.filterTagsViewModel.items.push(selectedFilter.selectedOption);
        this.updateList();
    }

    public onLabelRemoved() {
        this.updateList();
    }

    public onTabChanged(selectedTab) {
        this.tabsViewModel.selectedIndex = selectedTab.selectedTabIndex;
        this.updateList();
    }

    public onPaginationChanged(direction) {
        this.paginationViewModel = this.paginationViewModelHelper.getUpdatedPaginationViewModel(
            this.paginationViewModel,
            direction
        );
        this.updateList();
    }
}
