import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { CurrenciesListHelper } from '@maersk-global/huub-utils';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ui-currencies',
    templateUrl: './ui-currencies.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UiCurrenciesComponent), // eslint-disable-line
            multi: true
        }
    ]
})
export class UiCurrenciesComponent implements OnInit, ControlValueAccessor {
    constructor(private currenciesListHelper: CurrenciesListHelper) {}

    set value(val: any) {
        if (val !== undefined && this.val !== val) {
            this.val = val;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    @Input() public currency?: string;

    public currencySymbol = '';
    public currenciesList = this.currenciesListHelper.getCurrenciesList();
    public val = '';

    public ngOnInit() {
        this.currencySymbol = this.currenciesListHelper.getCurrencyByIso3Code(this.currency).symbol;
    }
    public onChange: any = () => {};
    public onTouch: any = () => {};

    public writeValue(value: any) {
        this.value = value;
    }

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}
