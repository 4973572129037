import { Injectable } from '@angular/core';
import * as moment_ from 'moment-mini-ts';

const moment = moment_;

@Injectable()
export class StartEndDatePageHelper {
    constructor() {}

    public getContractOverlapConfig(
        currentContractStartDate: Date,
        currentContractEndDate: Date,
        minStartDate: Date | null,
        maxEndDate: Date | null
    ) {
        const isStartDateOverlaped = minStartDate && moment(currentContractStartDate).isBefore(minStartDate);

        const isEndDateOverlaped = maxEndDate && moment(currentContractEndDate).isAfter(maxEndDate);

        const hasContractOverlap = isStartDateOverlaped || isEndDateOverlaped;

        return {
            hasContractOverlap,
            isStartDateOverlaped,
            isEndDateOverlaped
        };
    }

    public getContractGapConfig(
        currentContractStartDate: Date,
        currentContractEndDate: Date,
        minStartDate: Date | null,
        maxEndDate: Date | null
    ) {
        const isStartDateGapped = minStartDate && moment(currentContractStartDate).isAfter(minStartDate);

        const isEndDateGapped = maxEndDate && moment(currentContractEndDate).isBefore(maxEndDate);

        const hasGapBetweenContracts = isStartDateGapped || isEndDateGapped;

        return {
            hasGapBetweenContracts,
            isStartDateGapped,
            isEndDateGapped
        };
    }
}
