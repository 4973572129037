import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WizardStepFooterSpec } from '../../common/scripts/constants/wizard-steps.constant';
import { filter } from 'rxjs/operators';
import { toFooterSpec } from '../../common/scripts/utils/footer.utils';
import { has } from 'lodash';
import { FooterStateService } from '../../common/scripts/services/footer-state.service';
import { CostBaseTableService } from '../../common/scripts/services/cost-base-table.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'wizard-footer',
    templateUrl: './wizard-footer.component.html'
})
export class WizardFooterComponent implements OnInit {
    private carrierAccountId: string;
    private contractId: string;

    public footerSpec: any | WizardStepFooterSpec = {};
    public showEditionModeFooter: boolean;
    public errorMsg$: Observable<string>;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private costBaseTableService: CostBaseTableService,
        public footerState: FooterStateService
    ) {}

    private observeNavigation() {
        this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => this.handleNavigationEnd());
    }

    private handleNavigationEnd() {
        const nav = this.router.getCurrentNavigation();

        if (!has(nav, 'extras.state.step')) {
            return;
        }

        const state = nav.extras.state;
        this.footerSpec = toFooterSpec(state.step);
    }

    private handleInit() {
        const data = this.activatedRoute.firstChild.snapshot.data;
        this.footerSpec = toFooterSpec(data.step);

        this.errorMsg$ = this.footerState.errorMsg$;

        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.contractId = params.get('contractId');
            this.carrierAccountId = params.get('carrierAccountId');
        });
    }

    public ngOnInit() {
        this.observeNavigation();
        this.handleInit();
    }

    public navigate(path: string, state = {}) {
        this.router.navigate([path], {
            queryParams: {
                contractId: this.contractId,
                carrierAccountId: this.carrierAccountId
            },
            relativeTo: this.activatedRoute,
            state
        });
    }

    public publish() {
        this.router.navigate(['../dashboard'], {
            relativeTo: this.activatedRoute,
            state: {
                finish: true
            }
        });
    }

    public onClearAllAction() {
        this.footerState.onClearAll();
    }

    public onRestoreAction() {
        this.footerState.onRestore();
    }

    public onCancelEdit() {
        this.costBaseTableService.clearUploadedFileContent();
    }

    public onSaveSubmit() {
        this.costBaseTableService.onSubmitCostBaseTable(false);
    }
}
