export class DatesHelper {
    constructor() {}

    public getDiffDaysBetweenTwoDates(compareDateFrom: string, compareDateTo: string): number {
        const timeDiff = new Date(compareDateFrom).getTime() - new Date(compareDateTo).getTime();
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    public getDatesBetweenTwoDates(startDate: Date, endDate: Date): any[] {
        const dates = [];
        let currentDate = startDate;

        while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = (() => {
                const date = new Date(currentDate.valueOf());
                date.setDate(date.getDate() + 1);
                return date;
            })();
        }
        return dates;
    }
}
