import { ContractModel } from '../models/contract.model';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractTransportService } from '../transport/contract-transport.service';
import { map } from 'rxjs/operators';

@Injectable()
export class WizardResolver  {
    constructor(private transport: ContractTransportService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const carrierAccountId = route.queryParamMap.get('carrierAccountId');

        return this.transport.getAccountInformation(carrierAccountId).pipe(
            map((accountInformation) => ({
                accountInformation
            }))
        );
    }
}
