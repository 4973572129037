export const WIZARD_HEADER = {
    LEAVING_MODAL: {
        title: 'Close without saving?',
        message: 'You have made changes to this contract without saving. Would you like to save it as a draft?',
        submit_text: 'Save as a draft',
        cancel_text: 'Discard changes'
    },
    MESSAGES: {
        draft_saved: 'The new contract was saved as draft successfully.',
        draft_errored: 'The data that you have tried to submit has errors.'
    }
};
