import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdditionalChargingRulesService {
    private additionalChargingRules: any = {
        stepNumber: 6
    };

    constructor() {}

    public getStartEndDateData(): any {
        return this.additionalChargingRules;
    }

    public getAdditionalChargingRulesModel(additionalChargingRules): Array<any> {
        if (!additionalChargingRules) {
            return [];
        }

        return additionalChargingRules.map((rule: any) => ({
            type: rule.is_variable ? 'Variable' : 'Fix',
            name: rule.name,
            value: rule.rule_amount,
            conditions: [`${rule.condition_variable} ${rule.rule_conditions} ${rule.condition_value}`],
            isSurplus: rule.is_surplus,
            isFuelsurcharge: rule.is_fuelsurcharge,
            indexationVariable: rule.indexation_variable
        }));
    }
}
