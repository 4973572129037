import { Component, Inject, OnInit } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';

@Component({
    selector: 'clear-all-and-edit-modal',
    templateUrl: './clear-all-and-edit-modal.component.html'
})
export class ClearAllAndEditModalComponent implements OnInit {
    constructor(private modal: UiModalService, @Inject('MODAL_DATA') public data) {}

    ngOnInit() {}

    public onConfirm() {
        this.modal.close({ confirm: true });
    }

    public onCancel() {
        this.modal.close({ confirm: false });
    }
}
