import { Component, Inject, OnInit } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { InterfaceZoneModel } from '../../../common/scripts/models/zone.model';
import { config } from './manage-zones-modal.config';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { get } from 'lodash';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
    selector: 'manage-zones-modal',
    templateUrl: './manage-zones-modal.component.html'
})
export class ManageZonesModalComponent implements OnInit {
    public zones: InterfaceZoneModel[];
    public config: any;
    public form: UntypedFormGroup;

    constructor(private modal: UiModalService, private fb: UntypedFormBuilder, @Inject('MODAL_DATA') private data: any) {}

    private initForm() {
        this.form = this.fb.group({
            zones: this.fb.array(Array.from(this.zones, (item: InterfaceZoneModel) => this.getZoneSchema(item)))
        });
    }

    private getZoneSchema(item: InterfaceZoneModel) {
        return this.fb.group({
            uuid: [item.uuid],
            id: [item.id],
            countries: [item.countries],
            name: [
                { value: item.name, disabled: true },
                Validators.compose([Validators.required, RxwebValidators.unique()])
            ],
            zone_number: [item.zone_number]
        });
    }

    public ngOnInit() {
        this.config = config;
        this.zones = this.data.zones;
        this.initForm();
    }

    public onConfirm() {
        const val = this.form.getRawValue();
        this.modal.close({
            confirm: true,
            zones: get(val, 'zones', [])
        });
    }

    public onCancel() {
        this.modal.close();
    }

    public drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.zoneCtrls.controls, event.previousIndex, event.currentIndex);
        this.zoneCtrls.controls.forEach((ctrl: AbstractControl, idx: number) => {
            ctrl.patchValue({
                zone_number: idx
            });
        });
    }

    public edit(zoneIdx: number) {
        const zoneNameCtrl = this.zoneCtrls.get([zoneIdx]).get('name');

        if (zoneNameCtrl.enabled) {
            zoneNameCtrl.disable();
        } else {
            zoneNameCtrl.enable();
        }
    }

    public remove(zoneIdx: number) {
        this.zoneCtrls.removeAt(zoneIdx);
    }

    public trackZone(_: number, zone: InterfaceZoneModel) {
        return zone.uuid;
    }

    public get zoneCtrls(): any {
        return this.form.get('zones');
    }
}
