import { Component, OnInit } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';
import { CostBaseTableTransportService } from '../../../common/scripts/transport/cost-base-table-transport.service';
import { saveAs } from 'file-saver';
import { SpecialPacksTransportService } from '../../../common/scripts/transport/special-packs-transport.service';
import { SpecialPacksService } from '../../../common/scripts/services/special-packs.service';
import { ContractModel } from '../../../common/scripts/models/contract.model';
import { ActivatedRoute } from '@angular/router';
import { FooterStateService } from '../../../common/scripts/services/footer-state.service';

@Component({
    selector: 'add-special-pack-modal',
    templateUrl: './add-special-pack-modal.component.html'
})
export class AddSpecialPackModalComponent implements OnInit {
    private contractId: number;
    private carrierAccountId: number;
    private contract: any;

    public viewModel = {};
    public packId: number;
    public defaultDropdownText = 'Select pack name';

    public uploadFilesViewModel = {
        isUploading: false,
        isUploaded: false,
        fileUploadPercentage: 0,
        fileName: ''
    };

    public isFileUploaded: boolean;
    public isPackChosen: boolean;

    public files: any;
    public input: any;

    public isInvalidFileError: boolean;
    public showInvalidFileError: boolean;
    public errorValidationHandled: boolean;

    public specialPackNames = [];

    constructor(
        private modal: UiModalService,
        private specialPacksService: SpecialPacksService,
        private activatedRoute: ActivatedRoute,
        private transportCostBaseTable: CostBaseTableTransportService,
        private transport: SpecialPacksTransportService,
        private footerStateService: FooterStateService
    ) {}

    public ngOnInit() {
        this.contractId = this.activatedRoute.snapshot.queryParams['contractId'];
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];
        this.contract = this.footerStateService.getContract();

        this.specialPacksService.errorHandlingSubject.subscribe((res) => {
            this.isInvalidFileError = res;
        });

        this.getSpecialPacksNames();
    }

    public onSetPackId() {
        this.specialPacksService.setPackId(this.packId);
        this.isPackChosen = true;
    }

    public getSpecialPacksNames() {
        this.transport.getSpecialPackNames(this.carrierAccountId, this.contractId).subscribe((res) => {
            res.data.packs.forEach((val: any) => {
                this.specialPackNames.push({
                    id: val.id,
                    name: val.name,
                    carrier_service_type: val.carrier_service_type
                });
            });
        });
    }

    public onSubmitClicked() {
        this.modal.close();
    }

    public handleSelectedFile({ selectedFile }) {
        this.files = [selectedFile];
        this.specialPacksService.setUploadedFile(this.files);
        this.isFileUploaded = true;
        this.uploadFilesViewModel.isUploaded = true;
        this.uploadFilesViewModel.isUploading = false;
        if (!this.isInvalidFileError) {
            this.specialPacksService.reimportComponentMethod();
        }
    }

    public onViewDocumentClicked($event) {}

    public onRemoveDocumentClicked() {
        this.isFileUploaded = false;
        this.uploadFilesViewModel.isUploaded = false;
        this.uploadFilesViewModel.isUploading = false;
    }

    public reimportFile() {
        this.files = this.specialPacksService.getUploadedFile();
        if (!this.isInvalidFileError) {
            this.specialPacksService.onReimportSubmitted();
            this.modal.close();
        } else {
            this.showInvalidFileError = true;
        }
    }

    public getSampleFile() {
        this.transportCostBaseTable
            .getSampleFile({ id: this.contractId } as ContractModel, this.carrierAccountId, this.contract.zones)
            .subscribe((res) => {
                const blob = new Blob([res], { type: 'text/csv' });
                saveAs(blob, 'Special_packs_sample_download.csv');
            });
    }
}
