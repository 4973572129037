import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'taxable-weight',
    templateUrl: './taxable-weight.component.html'
})
export class TaxableWeightComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
