import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { GLOBAL } from '../constants/global.constant';

@Injectable()
export class MessagingService {
    private activeSubject = new Subject<boolean>();
    private loadingSubject = new Subject<boolean>();
    private messageSubject = new BehaviorSubject<string>('');
    private messageType = new Subject<boolean>();

    public active$: Observable<boolean>;
    public message$: Observable<string>;
    public loading$: Observable<boolean>;
    public messageType$: Observable<boolean>;

    constructor() {
        this.active$ = this.activeSubject.asObservable();
        this.message$ = this.messageSubject.asObservable();
        this.loading$ = this.loadingSubject.asObservable();
        this.messageType$ = this.messageType.asObservable();
    }

    public activate(message: string, isSuccess = true): void {
        this.activeSubject.next(true);
        this.messageSubject.next(message);
        this.messageType.next(isSuccess);

        of(true)
            .pipe(delay(GLOBAL.MESSAGING.DELAY_HIDE))
            .subscribe(() => this.deactivate());
    }

    public deactivate(): void {
        this.activeSubject.next(false);
        this.messageSubject.next('');
    }

    public activateLoading(): void {
        this.loadingSubject.next(true);
    }

    public deactivateLoading(): void {
        this.loadingSubject.next(false);
    }
}
