import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, ReplaySubject, zip } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { get, head, omit, values } from 'lodash';
import { UiModalComponent, UiModalService } from '@maersk-global/huub-material';
import { CountriesListHelper } from '@maersk-global/huub-utils';
import { ContractTransportService } from '../../../common/scripts/transport/contract-transport.service';
import { ZonesPropertiesService } from '../../../common/scripts/services/zones-properties.service';
import { ZonesService } from '../../../common/scripts/services/zones.service';
import { FooterBtns, FooterStateService } from '../../../common/scripts/services/footer-state.service';
import { ZoneTabsModel } from '../../../common/scripts/models/zone-tabs.model';
import { ZonesPropertiesInput } from '../../../common/scripts/interfaces/zones-properties';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { extractIso2Codes } from '../../../common/scripts/utils/zones.utils';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';
import { ZONE_CONFIG } from './zone.config';
import { URL_CONFIG } from '../../../config/url.config';
import { NewZoneModalComponent } from '../../../components/modals/new-zone-modal/new-zone-modal.component';
import { SearchByCountryComponent } from '../../../components/search-by-country/search-by-country.component';
import { ClearAllAndEditModalComponent } from '../../../components/modals/clear-all-and-edit-modal/clear-all-and-edit-modal.component';
import { MessagingService } from '../../../common/scripts/services/messaging.service';
import { ManageZonesModalComponent } from '../../../components/modals/manage-zones-modal/manage-zones-modal.component';
import { ZoneModel } from '../../../common/scripts/models/zone.model';
import { RestoreModalComponent } from '../../../components/modals/restore-modal/restore-modal.component';

@Component({
    selector: 'country-zones',
    templateUrl: './zones.component.html'
})
export class ZonesComponent extends AbstractWizardStep implements OnInit, IsWizardStep, OnDestroy {
    private countriesSearchSubject: ReplaySubject<string> = new ReplaySubject<string>();
    private countriesListSubject: ReplaySubject<any[]> = new ReplaySubject<any>();
    private contract: any;
    private clearAllFooter$: any;
    private restoreFooter$: any;
    private carrierAccountId: number;
    public stepNumber: number;

    @ViewChild('searchByCountry', { static: false })
    public searchCmp: SearchByCountryComponent;
    public zonesProperties: ZonesPropertiesInput;
    public countriesList$: Observable<any>;
    public searchByCountryList = [];
    public zonesList$: Observable<ZoneTabsModel>;
    public canAddCountries = false;
    public emptyZoneError = false;
    public selectedZoneUuid: string;
    public CONFIG = ZONE_CONFIG;

    constructor(
        protected footerStateService: FooterStateService,
        private zonesPropsService: ZonesPropertiesService,
        private countriesListHelper: CountriesListHelper,
        private transportContractProperties: ContractTransportService,
        private zonesService: ZonesService,
        private uiModal: UiModalService,
        private activatedRoute: ActivatedRoute,
        private msgService: MessagingService,
        private router: Router
    ) {
        super();
    }

    protected setupFooter() {
        this.setupDefaultBtns();
    }

    private initContractData() {
        this.setupFooter();
        this.initZones();
    }

    private setupDefaultBtns() {
        const hasZones = get(this.contract, 'zones', []).length;
        const btns = [
            { btn: FooterBtns.NEXT, state: { visible: true, enabled: true, text: 'Incoterm service type' } },
            { btn: FooterBtns.PREV, state: { visible: true, enabled: true, text: 'Contract properties' } },
            { btn: FooterBtns.CLEAR_ALL, state: { visible: true, enabled: hasZones, text: 'Clear all' } },
            { btn: FooterBtns.RESTORE, state: { visible: true, enabled: false, text: 'Restore' } }
        ];
        this.footerStateService.toggleAll(btns);
    }

    private initSearch() {
        const list = this.countriesListHelper.getCountriesList();
        this.searchByCountryList = list.map((country) => ({ text: country.name }));
    }

    private initCountries() {
        this.countriesList$ = this.countriesListSubject.asObservable();
    }

    private initSearchToCountries() {
        const list = this.countriesListHelper.getCountriesList();
        combineLatest(of(list), this.countriesSearchSubject.asObservable())
            .pipe(
                switchMap(([countries, search]) => zip(of(countries), this.zonesList$, of(search))),
                map(([countries, zonesList, search]) => [
                    countries.filter((country) => !search || country.name.toLowerCase().includes(search)),
                    zonesList.items
                ]),
                map(([countries, zonesList]) => [head(countries), zonesList]),
                map(([country, zonesList]) => ({
                    country,
                    zoneTab: zonesList.filter((zoneTab: any) =>
                        zoneTab.zone.countries.find(
                            (zoneCountry: any) => zoneCountry.country_iso2_code === country.iso2code
                        )
                    )
                })),
                map(({ country, zoneTab }) => ({
                    country,
                    zoneTab: zoneTab.length ? head(zoneTab) : ZonesService.getDefaultZoneModel()
                }))
            )
            .subscribe(({ country, zoneTab }: any) => {
                this.zonesService.setSelected(zoneTab);
                this.setCountriesList(zoneTab.zone.uuid, country.iso2code);
            });
    }

    private setCountriesList(zoneUuid: string, iso2code?: string) {
        this.zonesList$.pipe(first()).subscribe((zonesTabs) => {
            const zoneTab = zonesTabs.items.find((tab) => tab.zone.uuid === zoneUuid);
            if (!zoneTab) {
                return;
            }
            this.selectedZoneUuid = zoneUuid;
            if (zoneUuid === '' && !iso2code) {
                const excludeIso2Codes = zonesTabs.items.reduce(
                    (accu, codes) => [...accu, ...extractIso2Codes(codes.zone.countries)],
                    []
                );
                this.setDefaultZoneCountries(excludeIso2Codes);
            } else {
                this.setSelectedZoneCountries(iso2code ? [iso2code] : extractIso2Codes(zoneTab.zone.countries));
            }
        });
    }

    private setDefaultZoneCountries(excludeIso2Codes: string[]) {
        const list = this.countriesListHelper.getCountriesListOrderName();
        const result = list.filter((country) => !excludeIso2Codes.includes(country.iso2code));
        this.countriesListSubject.next(result);
    }

    private setSelectedZoneCountries(includeIso2Codes: string[]) {
        const list = this.countriesListHelper.getCountriesListOrderName();
        const result = list.filter((country) => includeIso2Codes.includes(country.iso2code));
        this.countriesListSubject.next(result);
    }

    private handleClearAllAndEditMode() {
        this.uiModal
            .openModal(UiModalComponent, [ClearAllAndEditModalComponent], {
                title: 'Clear all',
                message: ZONE_CONFIG.clear_all_message
            })
            .afterClose()
            .subscribe((data: any) => {
                if (get(data, 'confirm', false)) {
                    this.clearZones();
                }
            });
    }

    private handleRestore() {
        this.uiModal
            .openModal(UiModalComponent, [RestoreModalComponent], {
                title: 'Restore previous contract information',
                message:
                    'Are you sure you want to discard recent changes and restore the previous contract information?'
            })
            .afterClose()
            .subscribe((data: any) => {
                if (get(data, 'confirm', false)) {
                    this.router.navigate(
                        [`${URL_CONFIG.CAS.INDEX}/${URL_CONFIG.CAS.WIZARD.INDEX}/${URL_CONFIG.CAS.WIZARD.STEPS.ZONES}`],
                        {
                            queryParamsHandling: 'preserve',
                            state: {
                                force: true
                            }
                        }
                    );
                }
            });
    }

    private initFooterActions() {
        this.clearAllFooter$ = this.footerStateService.onClearAll$.subscribe(() => this.handleClearAllAndEditMode());
        this.restoreFooter$ = this.footerStateService.onRestore$.subscribe(() => this.handleRestore());
    }

    private initZones() {
        this.zonesList$ = this.zonesService.zones$;
        this.zonesService.setZones(this.contract.zones);
        this.setCountriesList('');
    }

    private addZone(zone: any) {
        const model = ZoneModel.create({
            ...zone,
            countries: zone.countries.map((country: any) => ({ country_iso2_code: country.iso2code }))
        });
        this.contract.zones.push(model);
        this.zonesService.setZones(this.contract.zones);
        this.setCountriesList('');
        this.footerStateService.enable(FooterBtns.CLEAR_ALL, ZONE_CONFIG.clear_all);
        this.footerStateService.enable(FooterBtns.RESTORE, ZONE_CONFIG.restore);
        this.msgService.activate(`${zone.name} was created with success.`);
    }

    private clearZones() {
        this.contract.zones = [];
        this.zonesService.setZones([]);
        this.setCountriesList('');
        this.footerStateService.disable(FooterBtns.CLEAR_ALL, ZONE_CONFIG.clear_all);
        this.footerStateService.enable(FooterBtns.RESTORE, ZONE_CONFIG.restore);
    }

    public ngOnInit() {
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];
        this.zonesProperties = this.zonesPropsService.getZonesProperties();
        this.activatedRoute.data.subscribe((data) => {
            this.contract = get(data, 'contract', {});
            this.stepNumber = data.step.index + 1;
            this.initContractData();
        });

        this.initSearch();
        this.initCountries();
        this.initFooterActions();
        this.initSearchToCountries();
    }

    public ngOnDestroy() {
        this.clearAllFooter$.unsubscribe();
        this.restoreFooter$.unsubscribe();
    }

    public onCountrySearch(search: string) {
        if (!search) {
            this.setCountriesList(this.selectedZoneUuid);
        } else {
            this.countriesSearchSubject.next(search);
        }
    }

    public onZoneSelected(zone: ZoneModel) {
        this.setCountriesList(get(zone, 'selectedTab.zone.uuid'));
        this.searchCmp.clearSearch();
    }

    public onAddZone(preselected?: any) {
        this.uiModal
            .openModal(UiModalComponent, [NewZoneModalComponent], {
                title: ZONE_CONFIG.new_zone,
                searchByCountries: this.searchByCountryList,
                countriesList: this.countriesListHelper
                    .getCountriesList()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter(
                        (country: any) =>
                            this.contract.zones.findIndex(
                                (zone: any) =>
                                    zone.countries.findIndex(
                                        (countryZone: any) => countryZone.country_iso2_code === country.iso2code
                                    ) > -1
                            ) === -1
                    ),
                preselectedCountry: preselected,
                forbiddenNames: this.contract.zones.map((zone: any) => zone.name)
            })
            .afterClose()
            .subscribe((data: any) => {
                if (get(data, 'confirm', false)) {
                    this.addZone(data.value);
                }
            });
    }

    public previousStep() {
        const contract = omit(
            {
                ...this.contract,
                zones: this.contract.zones.map((zone: any) => (zone as ZoneModel).toDTO())
            },
            ['start_date', 'end_date']
        );
        this.footerStateService.setContract(contract);
        return of(true);
    }

    public validateAndSave() {
        const hasZones = !!get(this.contract, 'zones', []).length;
        const hasValidZones = get(this.contract, 'zones', []).every(
            (zone: ZoneModel) => !!get(zone, 'countries', []).length
        );
        const contract = omit(
            {
                ...this.contract,
                zones: this.contract.zones.map((zone: any) => (zone as ZoneModel).toDTO())
            },
            ['start_date', 'end_date']
        );

        if (!hasZones) {
            this.footerStateService.setErrorMsg(ZONE_CONFIG.one_zone_required);
        }

        if (!hasValidZones) {
            this.emptyZoneError = true;
            this.zonesList$.pipe(first()).subscribe((zones) => {
                const errorZoneTab = zones.items.find(
                    (zoneTab) => !!zoneTab.zone.uuid && !zoneTab.zone.countries.length
                );
                this.setCountriesList(errorZoneTab.zone.uuid);
                this.zonesService.setZones(this.contract.zones, errorZoneTab.zone.uuid);
            });
        }
        this.footerStateService.setContract(contract);
        return of(hasZones && hasValidZones).pipe(
            catchError(() => of(false)),
            map((valid) => !!valid)
        );
    }

    public onRemoveFromZone(country: any) {
        const zones = [...this.contract.zones].map((zone) =>
            ZoneModel.create({
                ...zone,
                countries: zone.countries.filter((zoneCountry) => country.iso2code !== zoneCountry.country_iso2_code)
            })
        );
        const removeFrom = this.contract.zones.find((zone: ZoneModel) => zone.uuid === this.selectedZoneUuid);
        this.contract.zones = zones;
        this.zonesService.setZones(zones, this.selectedZoneUuid);
        this.setCountriesList(this.selectedZoneUuid);
        this.msgService.activate(`${country.name} has been removed from ${removeFrom.name}`);
        this.footerStateService.enable(FooterBtns.RESTORE, ZONE_CONFIG.restore);
    }

    public getMoveToZonesList() {
        return this.contract.zones.filter((zone: any) => zone.uuid !== this.selectedZoneUuid);
    }

    public onMoveToZone(data: any) {
        const zones = [...this.contract.zones]
            .map((zone) =>
                ZoneModel.create({
                    ...zone,
                    countries: zone.countries.filter((country) => country.country_iso2_code !== data.country.iso2code)
                })
            )
            .map((zone) =>
                ZoneModel.create({
                    ...zone,
                    countries:
                        data.moveToZoneUuid === zone.uuid
                            ? [...zone.countries, { country_iso2_code: data.country.iso2code }]
                            : zone.countries
                })
            );
        this.contract.zones = zones;
        this.zonesService.setZones(zones, this.selectedZoneUuid);
        this.setCountriesList(this.selectedZoneUuid);
        const moveToZone = this.contract.zones.find((zone) => zone.uuid === data.moveToZoneUuid);
        this.footerStateService.enable(FooterBtns.RESTORE, ZONE_CONFIG.restore);
        this.msgService.activate(`${data.country.name} has been moved to ${moveToZone.name}`);
    }

    public onManageZones() {
        this.uiModal
            .openModal(UiModalComponent, [ManageZonesModalComponent], {
                title: ZONE_CONFIG.manage_zones,
                zones: [...this.contract.zones]
            })
            .afterClose()
            .subscribe((manage) => {
                if (!get(manage, 'confirm', false)) {
                    return;
                }
                const managedZones = get(manage, 'zones', []);

                if (managedZones.length < this.contract.zones.length) {
                    this.msgService.activate('Zones have been updated');
                }

                const currentDeleted = managedZones.every((zone) => zone.uuid !== this.selectedZoneUuid);
                this.contract.zones = managedZones.map((zone) => ZoneModel.create(zone));
                this.zonesService.setZones(this.contract.zones);

                if (currentDeleted) {
                    this.setCountriesList('');
                }

                this.footerStateService.enable(FooterBtns.RESTORE, ZONE_CONFIG.restore);
            });
    }
}
