import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { GLOBAL } from '../../common/scripts/constants/dashboard.constant';
import { DashboardTransportService } from '../../common/scripts/transport/dashboard.transport.service';
import { UiModalService } from '@maersk-global/huub-material';
import * as moment_ from 'moment-mini-ts';
const moment = moment_;

@Component({
    selector: 'ui-modal-fuelsurcharge',
    templateUrl: './ui-modal-fuelsurcharge.component.html'
})
export class UiModalFuelsurchargeComponent implements OnInit {
    public viewModel: any;
    public startDate: any;
    public endDate: any;
    public monthNames = GLOBAL.MONTH_NAMES;
    public weekDays = GLOBAL.WEEK_DAYS;
    public isFormDisabled: boolean;
    public fuelsurchargeFee: number;
    public errorFuelsurcharge = false;
    public errorStartDate = false;
    public errorEndDate = false;
    public limitsNextContract: { min: number; max: number };
    public limitsCurrentContract: { min: number; max: number };
    constructor(
        public modal: UiModalService,
        @Inject('MODAL_DATA') private componentViewModel,
        private transport: DashboardTransportService
    ) {
        this.viewModel = this.componentViewModel.componentViewModel;
    }

    ngOnInit() {
        this.isFormDisabled =
            (!!this.viewModel.currentFuelSurcharge && !!this.viewModel.nextFuelSurcharge) ||
            !this.viewModel.isFuelsurchargeActive;

        this.limitsCurrentContract = {
            min: this.viewModel.previousFuelSurcharge ? this.viewModel.previousFuelSurcharge.endDate : null,
            max: this.viewModel.nextFuelSurcharge ? this.viewModel.nextFuelSurcharge.startDate : null
        };

        this.limitsNextContract = {
            min: this.viewModel.currentFuelSurcharge ? this.viewModel.currentFuelSurcharge.endDate : null,
            max: null
        };
    }

    public activateDeactivateFuelSurcharge() {
        this.isFormDisabled =
            (!!this.viewModel.currentFuelSurcharge && !!this.viewModel.nextFuelSurcharge) ||
            this.viewModel.isFuelsurchargeActive;

        this.transport
            .activateDeactivateFuelSurcharge(
                this.viewModel.carrierServiceTypeAccount.id,
                !this.viewModel.isFuelsurchargeActive
            )
            .subscribe();
    }

    public onStartDateSelected(date) {
        this.startDate = moment(date, 'DD/MM/YYYY');
        if (this.errorEndDate) {
            this.errorEndDate = this.endDate.isBefore(this.startDate);
        }
    }

    public onEndDateSelected(date) {
        this.endDate = moment(date, 'DD/MM/YYYY');
        this.errorEndDate = this.endDate.isBefore(this.startDate);
    }

    public addFuelSurcharge() {
        this.validateForm();
        if (this.errorStartDate || this.errorEndDate || this.errorFuelsurcharge) {
            return;
        }

        this.transport
            .addFuelSurchargeContract(this.viewModel.carrierServiceTypeAccount.id, {
                fee: this.fuelsurchargeFee,
                startDate: this.startDate,
                endDate: this.endDate
            })
            .subscribe((response) => {
                this.viewModel.nextFuelSurcharge = {
                    id: response.id,
                    value: parseFloat(response.fee).toFixed(2),
                    startDate: moment(response.start_date).format('DD/MM/YYYY'),
                    endDate: moment(response.end_date).format('DD/MM/YYYY'),
                    carrierAccountServiceTypeId: this.viewModel.carrierServiceTypeAccount.id
                };
                this.isFormDisabled =
                    (!!this.viewModel.currentFuelSurcharge && !!this.viewModel.nextFuelSurcharge) ||
                    !this.viewModel.isFuelsurchargeActive;
            });
    }

    public deleteCurrentFuelSurcharge = (contractId) => {
        this.transport
            .deleteFuelSurchargeContract(this.viewModel.carrierServiceTypeAccount.id, contractId)
            .subscribe(() => {
                this.viewModel.currentFuelSurcharge = null;
                this.isFormDisabled =
                    (!!this.viewModel.currentFuelSurcharge && !!this.viewModel.nextFuelSurcharge) ||
                    !this.viewModel.isFuelsurchargeActive;
            });
    };

    public deleteFuelSurcharge = (contractId) => {
        this.transport
            .deleteFuelSurchargeContract(this.viewModel.carrierServiceTypeAccount.id, contractId)
            .subscribe(() => {
                delete this.viewModel.nextFuelSurcharge;
                this.isFormDisabled =
                    (!!this.viewModel.currentFuelSurcharge && !!this.viewModel.nextFuelSurcharge) ||
                    !this.viewModel.isFuelsurchargeActive;
            });
    };

    public validateForm() {
        this.errorStartDate = !this.startDate;
        this.errorEndDate = !this.endDate || this.endDate.isBefore(this.startDate);
        this.errorFuelsurcharge = !this.fuelsurchargeFee;
        if (this.viewModel.currentFuelSurcharge) {
            this.errorStartDate =
                this.startDate &&
                this.startDate.isSameOrBefore(moment(this.viewModel.currentFuelSurcharge.endDate, 'DD/MM/YYYY'));
        }
    }
}
