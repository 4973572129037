import { ZoneCountryModel } from './zone-country.model';
import { ZoneCostModel } from './zone-cost.model';
import { ZoneAfterLimitCostsModel } from './zone-after-limits-cost.model';
import { v4 } from 'uuid';
import { ZoneSpecialPackModel } from './special-packs.model';
import { ZoneAfterLimitSpecialPackModel } from './special-packs-after-limits.model';
import { ZoneRestrictionsSpecialPackModel } from './special-packs-restrictions.model';

export interface InterfaceZoneModel {
    uuid?: string;
    id?: number;
    countries: ZoneCountryModel[];
    name: string;
    zone_number?: number;
    costs?: ZoneCostModel[];
    cost_base_table_restrictions?: {
        taxable_weight_calculation_type: string;
        conversion_rate: number;
        minimum_price_per_pack: number;
    };
    after_limit_costs?: ZoneAfterLimitCostsModel[];
    special_packs?: ZoneSpecialPackModel[];
    after_limit_special_packs?: ZoneAfterLimitSpecialPackModel[];
    special_packs_restrictions?: ZoneRestrictionsSpecialPackModel[];
}

export interface InterfaceZoneDTO {
    id?: number;
    countries: ZoneCountryModel[];
    name: string;
    zone_number?: number;
    costs: ZoneCostModel[];
    cost_base_table_restrictions?: {
        taxable_weight_calculation_type: string;
        conversion_rate: number;
        minimum_price_per_pack: number;
    };
    after_limit_costs?: ZoneAfterLimitCostsModel[];
    special_packs: ZoneSpecialPackModel[];
    after_limit_special_packs?: ZoneAfterLimitSpecialPackModel[];
    special_packs_restrictions?: ZoneRestrictionsSpecialPackModel[];
}

export class ZoneModel implements InterfaceZoneModel {
    public uuid?: string;
    public id?: number;
    public countries: ZoneCountryModel[];
    public name: string;
    public zone_number?: number;
    public costs?: ZoneCostModel[];
    public cost_base_table_restrictions?: {
        taxable_weight_calculation_type: string;
        conversion_rate: number;
        minimum_price_per_pack: number;
    };
    public after_limit_costs?: ZoneAfterLimitCostsModel[];
    public special_packs: ZoneSpecialPackModel[];
    public after_limit_special_packs?: ZoneAfterLimitSpecialPackModel[];
    public special_packs_restrictions?: ZoneRestrictionsSpecialPackModel[];

    public static create({
        uuid = null,
        id = null,
        countries,
        name,
        zone_number = null,
        costs = [],
        cost_base_table_restrictions = {
            minimum_price_per_pack: null,
            taxable_weight_calculation_type: null,
            conversion_rate: null
        },
        after_limit_costs = null,
        special_packs = [],
        after_limit_special_packs = [],
        special_packs_restrictions = []
    }): ZoneModel {
        const model = new ZoneModel();

        model.uuid = uuid || v4();
        model.id = id;
        model.countries = countries;
        model.name = name;
        model.zone_number = zone_number;
        model.costs = costs;
        model.cost_base_table_restrictions = cost_base_table_restrictions;
        model.after_limit_costs = after_limit_costs;
        model.special_packs = special_packs;
        model.after_limit_special_packs = after_limit_special_packs;
        model.special_packs_restrictions = special_packs_restrictions;

        return model;
    }

    public toDTO(): InterfaceZoneDTO {
        return {
            id: this.id || undefined,
            name: this.name,
            countries: this.countries,
            zone_number: isNaN(this.zone_number) ? undefined : this.zone_number,
            costs: this.costs || [],
            cost_base_table_restrictions: this.cost_base_table_restrictions || {
                minimum_price_per_pack: null,
                taxable_weight_calculation_type: null,
                conversion_rate: null
            },
            after_limit_costs: this.after_limit_costs || [],
            special_packs: this.special_packs || [],
            after_limit_special_packs: this.after_limit_special_packs || [],
            special_packs_restrictions: this.special_packs_restrictions || []
        };
    }
}
