import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanSaveWizardStep } from './wizard-step.guard';
import { InitWizardGuard } from './init-wizard.guard';

@NgModule({
    imports: [CommonModule],
    providers: [CanSaveWizardStep, InitWizardGuard]
})
export class GuardsModule {}
