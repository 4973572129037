import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';

export enum FooterBtns {
    PREV,
    NEXT,
    EDIT,
    RESTORE,
    CLEAR_ALL,
    FINISH,
    CANCEL,
    SAVE
}

export interface FooterBtnState {
    enabled: boolean;
    visible?: boolean;
    text?: string;
}

@Injectable({
    providedIn: 'root'
})
export class FooterStateService {
    private contract: any;
    private prevStateSubject = new BehaviorSubject<FooterBtnState>({ enabled: false });
    private nextStateSubject = new BehaviorSubject<FooterBtnState>({ enabled: false });
    private editStateSubject = new BehaviorSubject<FooterBtnState>({ enabled: false });
    private restoreStateSubject = new BehaviorSubject<FooterBtnState>({ enabled: false });
    private clearAllStateSubject = new BehaviorSubject<FooterBtnState>({ enabled: false });
    private finishStateSubject = new BehaviorSubject<FooterBtnState>({ enabled: false });
    private cancelStateSubject = new BehaviorSubject<FooterBtnState>({ enabled: false });
    private saveStateSubject = new BehaviorSubject<FooterBtnState>({ enabled: false });

    private onEditSubject = new Subject<void>();
    private onClearAllSubject = new Subject<void>();
    private onCancelSubject = new Subject<void>();
    private onSaveSubject = new Subject<void>();
    private onRestoreSubject = new Subject<void>();

    private errorMsgSubject = new ReplaySubject<string>();

    private btnsMap: Map<FooterBtns, Subject<FooterBtnState>>;

    public prevState$: Observable<FooterBtnState>;
    public nextState$: Observable<FooterBtnState>;
    public editState$: Observable<FooterBtnState>;
    public restoreState$: Observable<FooterBtnState>;
    public clearAllState$: Observable<FooterBtnState>;
    public finishState$: Observable<FooterBtnState>;
    public cancelState$: Observable<FooterBtnState>;
    public saveState$: Observable<FooterBtnState>;

    public onEdit$: Observable<void>;
    public onClearAll$: Observable<void>;
    public onCancel$: Observable<void>;
    public onSave$: Observable<void>;
    public onRestore$: Observable<void>;

    public errorMsg$: Observable<string>;

    constructor() {
        this.setup();
    }

    private setup() {
        this.prevState$ = this.prevStateSubject.asObservable();
        this.nextState$ = this.nextStateSubject.asObservable();
        this.editState$ = this.editStateSubject.asObservable();
        this.restoreState$ = this.restoreStateSubject.asObservable();
        this.clearAllState$ = this.clearAllStateSubject.asObservable();
        this.finishState$ = this.finishStateSubject.asObservable();
        this.cancelState$ = this.cancelStateSubject.asObservable();
        this.saveState$ = this.saveStateSubject.asObservable();
        this.restoreState$ = this.restoreStateSubject.asObservable();

        this.onEdit$ = this.onEditSubject.asObservable();
        this.onClearAll$ = this.onClearAllSubject.asObservable();
        this.onCancel$ = this.onCancelSubject.asObservable();
        this.onSave$ = this.onSaveSubject.asObservable();
        this.onRestore$ = this.onRestoreSubject.asObservable();

        this.errorMsg$ = this.errorMsgSubject.asObservable();

        this.btnsMap = new Map([
            [FooterBtns.PREV, this.prevStateSubject],
            [FooterBtns.NEXT, this.nextStateSubject],
            [FooterBtns.EDIT, this.editStateSubject],
            [FooterBtns.RESTORE, this.restoreStateSubject],
            [FooterBtns.CLEAR_ALL, this.clearAllStateSubject],
            [FooterBtns.FINISH, this.finishStateSubject],
            [FooterBtns.CANCEL, this.cancelStateSubject],
            [FooterBtns.SAVE, this.saveStateSubject]
        ]);
    }

    public enable(btn: FooterBtns, text: string): void {
        this.toggle(btn, { visible: true, enabled: true, text });
    }

    public disable(btn: FooterBtns, text: string): void {
        this.toggle(btn, { visible: true, enabled: false, text });
    }

    public toggle(btn: FooterBtns, state: FooterBtnState): void {
        this.btnsMap.get(btn).next(state);
    }

    public toggleAll(val: any): void {
        this.btnsMap.forEach((_, btn) => this.toggle(btn, { visible: false, enabled: false }));
        val.forEach((toggle: any) => this.toggle(toggle.btn, toggle.state));
    }

    public onClearAll(): void {
        this.onClearAllSubject.next();
    }

    public onRestore(): void {
        this.onRestoreSubject.next();
    }

    public setErrorMsg(msg: string): void {
        this.errorMsgSubject.next(msg);
        timer(5000).subscribe(() => this.clearErrorMsg());
    }

    public clearErrorMsg(): void {
        this.errorMsgSubject.next(null);
    }

    public setContract(updatedContract: any) {
        if (!updatedContract) {
            this.contract = null;
        } else {
            this.contract = { ...this.contract, ...updatedContract };
        }
        return of(true);
    }

    public getContract() {
        return this.contract;
    }
}
