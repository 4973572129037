export const GLOBAL = {
    MESSAGING: {
        DELAY_HIDE: 3500
    },
    CONTRACT_PROPERTIES: {
        PAYMENT_CONDITIONS: [
            {
                id: 1,
                name: 'Direct debit'
            },
            {
                id: 2,
                name: 'Transfer'
            }
        ],
        CREDIT_DAYS: [15, 30, 45, 60]
    },
    INCOTERM_CONFIGURATIONS: {
        DAP: 'dap',
        DDP: 'ddp'
    }
};
