import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'flag-icon',
    templateUrl: './flag-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlagIconComponent {
    public svgHeight = '12';
    public svgWidth = '19';

    @Input() iso: string;

    @Input()
    set height(height: number) {
        if (isNaN(height)) {
            return;
        }

        this.svgHeight = height.toString();
    }

    @Input()
    set width(width) {
        if (isNaN(width)) {
            return;
        }

        this.svgWidth = width.toString();
    }
}
