import { ContractModel } from '../models/contract.model';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ContractTransportService } from '../transport/contract-transport.service';
import { map, finalize } from 'rxjs/operators';
import { ZoneModel } from '../models/zone.model';
import { MessagingService } from '../services/messaging.service';
import { FooterStateService } from '../services/footer-state.service';

@Injectable()
export class ContractResolver  {
    constructor(
        private transport: ContractTransportService,
        private messaging: MessagingService,
        private footerStateService: FooterStateService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ContractModel> {
        const contractId = route.queryParamMap.get('contractId');
        const carrierAccountId = route.queryParamMap.get('carrierAccountId');
        this.messaging.activateLoading();
        return this.footerStateService.getContract()
            ? of({
                  ...this.footerStateService.getContract(),
                  zones: (this.footerStateService.getContract().zones || []).map((zone: any) => ZoneModel.create(zone))
              }).pipe(
                  finalize(() => {
                      this.messaging.deactivateLoading();
                  })
              )
            : this.transport.retrieveContract(contractId, carrierAccountId).pipe(
                  map((contract) => {
                    this.footerStateService.setContract(contract)
                    return {
                      ...contract,
                      zones: contract.zones.map((zone: any) => ZoneModel.create(zone))
                  }}),
                  finalize(() => {
                      this.messaging.deactivateLoading();
                  })
              );
    }
}
