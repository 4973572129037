import { Component, OnInit, Inject } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';

@Component({
    selector: 'restore-modal',
    templateUrl: './restore-modal.component.html'
})
export class RestoreModalComponent implements OnInit {
    constructor(private modal: UiModalService, @Inject('MODAL_DATA') public data) {}

    public ngOnInit() {}

    public onConfirm() {
        this.modal.close({ confirm: true });
    }

    public onCancel() {
        this.modal.close({ confirm: false });
    }
}
