import { Component, OnInit, Inject } from '@angular/core';
import { WIZARD_STEPS } from '../../common/scripts/constants/wizard-steps.constant';
import { UiModalService } from '@maersk-global/huub-material';

@Component({
    selector: 'ui-steps-validator',
    templateUrl: './ui-modal-steps-validator.component.html'
})
export class UiModalStepsValidatorComponent implements OnInit {
    public viewModel = [];

    constructor(private modal: UiModalService, @Inject('MODAL_DATA') private data: any) {}

    public ngOnInit() {
        const {
            validatedStepOne,
            validatedStepTwo,
            validatedStepThree,
            validatedStepSix,
            validatedStepSeven,
            validatedStepEight
        } = this.data;

        this.viewModel = [
            {
                ...WIZARD_STEPS.SET_CONTRACT_PROPERTIES,
                isValidated: validatedStepOne,
                icon: !validatedStepOne ? 'circle-empty' : 'circle-checked'
            },
            {
                ...WIZARD_STEPS.COUNTRY_ZONES,
                isValidated: validatedStepTwo,
                icon: !validatedStepTwo ? 'circle-empty' : 'circle-checked'
            },
            {
                ...WIZARD_STEPS.COST_BASE_TABLE,
                isValidated: validatedStepThree,
                icon: !validatedStepThree ? 'circle-empty' : 'circle-checked'
            },
            {
                ...WIZARD_STEPS.TRANSIT_TIMES,
                isValidated: validatedStepSix,
                icon: !validatedStepSix ? 'circle-empty' : 'circle-checked'
            },
            {
                ...WIZARD_STEPS.START_END_DATE,
                isValidated: validatedStepSeven,
                icon: !validatedStepSeven ? 'circle-empty' : 'circle-checked'
            },
            {
                ...WIZARD_STEPS.WAREHOUSE_SELECTION,
                isValidated: validatedStepEight,
                icon: !validatedStepEight ? 'circle-empty' : 'circle-checked'
            }
        ];
    }

    public onStepLinkClicked() {
        this.modal.close();
    }
}
