import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TransitTimesService {
    private transitTimesData: any = {
        stepNumber: 7
    };

    constructor() {}

    public getTransitTimesData(): any {
        return this.transitTimesData;
    }
}
