import { Component, Input, OnInit } from '@angular/core';
import { GLOBAL } from '../../common/scripts/constants/dashboard.constant';
import { DashboardTransportService } from '../../common/scripts/transport/dashboard.transport.service';

import * as moment_ from 'moment-mini-ts';
const moment = moment_;

@Component({
    selector: 'fuelsurcharge-contract',
    templateUrl: './fuelsurcharge-contract.component.html'
})
export class FuelsurchargeContractComponent implements OnInit {
    @Input()
    public viewModel: any;

    @Input()
    public title: string;

    @Input()
    public limits: any;

    @Input()
    public delete: Function;

    @Input()
    public disabled = false;

    public isEditionActive = false;
    public editionData: {
        fee: string;
        startDate: any;
        endDate: any;
    } = {
        fee: '',
        startDate: '',
        endDate: ''
    };

    public monthNames = GLOBAL.MONTH_NAMES;
    public weekDays = GLOBAL.WEEK_DAYS;

    public errorStartDate = false;
    public errorEndDate = false;
    public errorFuelsurcharge = false;

    constructor(private transport: DashboardTransportService) {}
    ngOnInit() {
        this.initializeEditionData();
    }

    toggleEdition() {
        this.isEditionActive = !this.isEditionActive;
        this.initializeEditionData();
    }

    initializeEditionData() {
        this.editionData.fee = parseFloat(this.viewModel.value).toFixed(2);
        this.editionData.startDate = moment(this.viewModel.startDate, 'DD/MM/YYYY');
        this.editionData.endDate = moment(this.viewModel.endDate, 'DD/MM/YYYY');
        this.validateForm();
    }

    save() {
        this.validateForm();
        if (this.errorStartDate || this.errorEndDate || this.errorFuelsurcharge) {
            return;
        }
        this.transport
            .editFuelSurchargeContract(this.viewModel.carrierAccountServiceTypeId, this.viewModel.id, this.editionData)
            .subscribe(() => {
                this.viewModel.value = this.editionData.fee;
                this.viewModel.startDate = this.editionData.startDate.format('DD/MM/YYYY');
                this.viewModel.endDate = this.editionData.endDate.format('DD/MM/YYYY');
                this.toggleEdition();
            });
    }

    public onStartDateSelected(date) {
        this.editionData.startDate = moment(date, 'DD/MM/YYYY');
        this.validateForm();
    }

    public onEndDateSelected(date) {
        this.editionData.endDate = moment(date, 'DD/MM/YYYY');
        this.validateForm();
    }

    public validateForm() {
        this.errorStartDate =
            !this.editionData.startDate ||
            (this.limits.min && this.editionData.startDate.isSameOrBefore(moment(this.limits.min, 'DD/MM/YYYY')));
        this.errorEndDate =
            !this.editionData.endDate ||
            this.editionData.endDate.isSameOrBefore(this.editionData.startDate) ||
            (this.limits.max && this.editionData.endDate.isSameOrAfter(moment(this.limits.max, 'DD/MM/YYYY')));
        this.errorFuelsurcharge = !this.editionData.fee;
    }
}
