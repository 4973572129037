import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import { FooterBtns, FooterStateService } from '../../../common/scripts/services/footer-state.service';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';
import { GLOBAL } from '../../../common/scripts/constants/global.constant';
import { INCOTERM_CONFIG } from './incoterm.config';
import { ContractTransportService } from '../../../common/scripts/transport/contract-transport.service';
import { IncotermModel } from '../../../common/scripts/models/incoterm.model';

@Component({
    selector: 'incoterm-service-type',
    templateUrl: './incoterm-service-type.component.html'
})
export class IncotermServiceTypeComponent extends AbstractWizardStep implements OnInit, IsWizardStep {
    private carrierAccountId: number;

    public contract: any;
    public INCOTERM_CONFIGURATIONS = GLOBAL.INCOTERM_CONFIGURATIONS;
    public stepNumber: number;
    public incoterm: IncotermModel;

    constructor(
        protected footerStateService: FooterStateService,
        private activatedRoute: ActivatedRoute,
        private transportContractProperties: ContractTransportService
    ) {
        super();
    }

    protected setupFooter() {
        this.setupDefaultBtns();
    }

    private initContractData() {
        this.setupFooter();
    }

    private setupDefaultBtns() {
        const btns = [
            { btn: FooterBtns.NEXT, state: { visible: true, enabled: true, text: 'Cost base table' } },
            { btn: FooterBtns.PREV, state: { visible: true, enabled: true, text: 'Zones' } }
        ];
        this.footerStateService.toggleAll(btns);
    }

    public ngOnInit() {
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];
        this.activatedRoute.data.subscribe((data) => {
            this.contract = get(data, 'contract', {});
            this.stepNumber = data.step.index + 1;
            this.initContractData();

            this.incoterm = this.contract.zones[0].countries[0].incoterm;
        });
    }

    public updateIncotermConfiguration(configuration) {
        this.contract.zones = this.contract.zones.map((zone) => {
            const countries = zone.countries.map((country) => {
                let incoterm = country.incoterm;

                if (!incoterm) {
                    incoterm = {};
                }

                switch (configuration) {
                    case this.INCOTERM_CONFIGURATIONS.DAP:
                        incoterm.dap = !incoterm.dap;
                        break;
                    case this.INCOTERM_CONFIGURATIONS.DDP:
                        incoterm.ddp = !incoterm.ddp;
                        break;
                    default:
                        break;
                }

                return {
                    ...country,
                    incoterm
                };
            });

            return {
                ...zone,
                countries
            };
        });
    }

    public previousStep() {
        this.footerStateService.setContract(this.contract);
        return of(true);
    }

    public validateAndSave() {
        const hasConfiguration =
            this.contract.zones[0] &&
            this.contract.zones[0].countries[0] &&
            this.contract.zones[0].countries[0].incoterm &&
            (this.contract.zones[0].countries[0].incoterm.dap || this.contract.zones[0].countries[0].incoterm.ddp);

        if (!hasConfiguration) {
            this.footerStateService.setErrorMsg(INCOTERM_CONFIG.no_configuration_message);
        }
        this.footerStateService.setContract(this.contract);
        return of(hasConfiguration).pipe(
            catchError(() => of(false)),
            map((valid) => !!valid)
        );
    }
}
