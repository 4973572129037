import { Component, OnInit, OnDestroy } from '@angular/core';
import { CostBaseTableInput } from '../../../common/scripts/interfaces/cost-base-table';
import { CostBaseTableService } from '../../../common/scripts/services/cost-base-table.service';
import { UiModalService, UiModalComponent } from '@maersk-global/huub-material';
import { UiModalReimportComponent } from '../../../components/ui-modal-reimport/ui-modal-reimport.component';
import { FooterStateService, FooterBtns } from '../../../common/scripts/services/footer-state.service';
import { CostBaseTableTransportService } from '../../../common/scripts/transport/cost-base-table-transport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorValidationModel } from '../../../common/scripts/interfaces/cost-base-table-error-validation';
import { get, omit, sortBy } from 'lodash';
import { ContractTransportService } from '../../../common/scripts/transport/contract-transport.service';
import { ClearAllAndEditModalComponent } from '../../../components/modals/clear-all-and-edit-modal/clear-all-and-edit-modal.component';
import { CurrenciesListHelper } from '@maersk-global/huub-utils';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, map, switchMap, concatMap } from 'rxjs/operators';
import { RestoreModalComponent } from '../../../components/modals/restore-modal/restore-modal.component';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { UiModalConfirmationRulesComponent } from '../../../components/ui-modal-confirmation-rules/ui-modal-confirmation-rules.component';
import { URL_CONFIG } from '../../../config/url.config';

@Component({
    selector: 'cost-base-table',
    templateUrl: './cost-base-table.component.html'
})
export class CostBaseTableComponent extends AbstractWizardStep implements OnInit, IsWizardStep, OnDestroy {
    private clearAllFooter$: any;
    private restoreFooter$: any;
    private carrierAccountId: number;

    public uploadFilesViewModel = {
        isUploading: false,
        isUploaded: false,
        fileUploadPercentage: 0,
        fileName: ''
    };

    public stepNumber: number;
    public records: any[] = [];
    public showInvalidFileTypeError: boolean;
    public showInvalidFileStructureError: boolean;
    public showRequiredDocumentError: boolean;
    public costBaseTableContent: CostBaseTableInput;
    public uploadResults: any;
    public selectedTabIndex = 0;
    public showResponseData: boolean;
    public showResponseDataValidation: boolean;
    public afterLimitConditions = [];
    public showAfterLimitConditionsButton = true;
    public editModeOn = true;
    public isEditAfterLimitConditionModeOn = false;
    public selectedItemIndex: number;
    public contract: any;
    public weightUnit = '';
    public currency = '';
    public currencySymbol: string;
    public serverResponseData = [];
    public showServerResponseData: boolean;
    public isCostTableNotEmpty: boolean;
    public responseData: {
        [key: string]: {
            from: number;
            to: number;
            cost: number;
            error?: ErrorValidationModel;
            id?: number;
        }[];
    } = {};
    public continentTabsViewModel = {
        items: [],
        selectedIndex: 0
    };
    public emptyValueErrorModel = {
        text: 'Invalid value. This cell cannot be empty.',
        icon: 'info',
        color: 'red'
    };
    public invalidValueErrorModel = {
        text: 'Invalid value. Please use a higher or equal cost and try again.',
        icon: 'info',
        color: 'red'
    };
    public invalidCharacterValueErrorModel = {
        text: 'Invalid value. Make sure that valid characters for this field has been used.',
        icon: 'info',
        color: 'red'
    };
    public invalidWeightRangeErrorModel = {
        text: 'Invalid weight range value. Confirm if values are organized by increasing values.',
        icon: 'info',
        color: 'red'
    };
    public afterLimitConditionItemEdit = {
        weightAbove: '',
        weightAdditional: '',
        cost: '',
        isWeightAboveValid: false,
        isWeightAdditionalValid: false,
        isCostValid: false
    };
    public afterLimitConditionItemAdd = {
        zone: null,
        weightAbove: '',
        weightAdditional: '',
        cost: '',
        isWeightAboveValid: true,
        isWeightAdditionalValid: false,
        isCostValid: false
    };

    public taxableWeight: any = '';
    public conversionRate: any = '';
    public minimumPerPack: any = '';

    public minimumPerPackMap = {};
    public conversionRatePerZoneMap = {};
    public taxableWeightPerZoneMap = {};

    constructor(
        private costBaseTableService: CostBaseTableService,
        private uiModal: UiModalService,
        private transportCostBaseTable: CostBaseTableTransportService,
        private transportContractProperties: ContractTransportService,
        private activatedRoute: ActivatedRoute,
        private footerStateService: FooterStateService,
        private route: Router,
        private currenciesListHelper: CurrenciesListHelper
    ) {
        super();
    }

    protected setupFooter() {
        this.setupDefaultBtns();
    }

    private uploadCSVFile(files: any, shouldAnalizeFile: boolean) {
        this.clearAfterLimitConditionItemAdd();
        if (this.getAfterLimitConditionsByIndex(this.selectedTabIndex).length === 0) {
            this.showAfterLimitConditionsButton = true;
        }
        this.editModeOn = true;
        if (files.length === 0) {
            return;
        }

        this.transportCostBaseTable.uploadCSVFile(files[0], this.contract.id, this.carrierAccountId).subscribe(
            (result) => {
                if (!result) {
                    return;
                }

                this.uploadFilesViewModel.isUploaded = result.isUploaded;
                this.uploadFilesViewModel.isUploading = result.isUploading;
                this.uploadFilesViewModel.fileUploadPercentage = result.fileUploadPercentage;

                if (result.isUploaded) {
                    this.uploadResults = result.body.data[0];

                    this.showRequiredDocumentError = false;
                    this.showInvalidFileStructureError = false;
                    this.showInvalidFileTypeError = false;

                    if (shouldAnalizeFile) {
                        this.uploadListener();
                        const isValid = this.validateResponseData();
                        this.showResponseDataValidation = !isValid;
                    }
                }
            },
            () => {
                this.uploadFilesViewModel.isUploading = false;
                this.uploadFilesViewModel.fileUploadPercentage = 0;
                this.uploadFilesViewModel.isUploaded = true;

                this.showRequiredDocumentError = false;
                this.showInvalidFileTypeError = true;

                this.costBaseTableService.callErrorHandling(true);
            }
        );

        this.costBaseTableService.getUploadedFileContent().isUploaded = true;
        this.costBaseTableService.getUploadedFileContent().isUploading = false;
    }

    private tabClicked() {
        this.showAfterLimitConditionsButton = !this.getAfterLimitConditionsByIndex(this.selectedTabIndex).length;
        this.clearAfterLimitConditionItemAdd();
    }

    private handleClearAllAndEditMode() {
        this.uiModal
            .openModal(UiModalComponent, [ClearAllAndEditModalComponent], {
                title: 'Clear all',
                message:
                    'If you want to clear all the information you must have to import the cost base table and the special packs table again. Are you sure you want to proceed with this action?'
            })
            .afterClose()
            .subscribe((data: any) => {
                if (get(data, 'confirm', false)) {
                    this.clearCostBaseTable();
                    this.setupFooter();
                }
            });
    }

    private handleRestoreMode() {
        this.uiModal
            .openModal(UiModalComponent, [RestoreModalComponent], {
                title: 'Restore previous contract information',
                message:
                    'Are you sure you want to discard recent changes and restore the previous contract information?'
            })
            .afterClose()
            .subscribe((data: any) => {
                if (get(data, 'confirm', false)) {
                    this.route.navigate(
                        [
                            `${URL_CONFIG.CAS.INDEX}/${URL_CONFIG.CAS.WIZARD.INDEX}/${URL_CONFIG.CAS.WIZARD.STEPS.COST_BASE_TABLE}`
                        ],
                        {
                            queryParamsHandling: 'preserve',
                            state: {
                                force: true
                            }
                        }
                    );
                    this.setupFooter();
                }
            });
    }

    private clearCostBaseTable() {
        this.responseData = {};
        this.showResponseDataValidation = false;
        this.uploadFilesViewModel = {
            isUploading: false,
            isUploaded: false,
            fileUploadPercentage: 0,
            fileName: ''
        };
        this.costBaseTableService.clearUploadedFileContent();
        this.afterLimitConditions = [];
        this.showInvalidFileTypeError = false;
        this.showRequiredDocumentError = false;
        this.showInvalidFileStructureError = false;
        this.showResponseData = false;
        if (this.getAfterLimitConditionsByIndex(this.selectedTabIndex).length === 0) {
            this.showAfterLimitConditionsButton = true;
        }
    }

    private setupDefaultBtns() {
        const btns = [
            { btn: FooterBtns.NEXT, state: { visible: true, enabled: true, text: 'Special packs' } },
            { btn: FooterBtns.PREV, state: { visible: true, enabled: true, text: 'Incoterm service type' } }
        ];

        this.footerStateService.toggleAll(btns);
    }

    private initFooterActions() {
        this.clearAllFooter$ = this.footerStateService.onClearAll$.subscribe(() => this.handleClearAllAndEditMode());
        this.restoreFooter$ = this.footerStateService.onRestore$.subscribe(() => this.handleRestoreMode());
    }

    private destroyFooterActions() {
        this.clearAllFooter$.unsubscribe();
        this.restoreFooter$.unsubscribe();
    }

    private resolve() {
        this.activatedRoute.data.subscribe((data) => {
            const contract = get(data, 'contract', {});
            this.contract = contract;
            this.stepNumber = data.step.index + 1;

            this.weightUnit = contract.weight_unit;
            this.currency = contract.currency_iso3_code;
            this.currencySymbol = this.currenciesListHelper.getCurrencyByIso3Code(this.currency).symbol;

            this.responseData = {};
            this.afterLimitConditions = [];

            contract.zones.forEach((value: any, index: number) => {
                this.minimumPerPackMap[index] = value.cost_base_table_restrictions.minimum_price_per_pack || '';

                this.taxableWeightPerZoneMap[index] =
                    value.cost_base_table_restrictions.taxable_weight_calculation_type || '';

                this.conversionRatePerZoneMap[index] = Number(value.cost_base_table_restrictions.conversion_rate)
                    ? Number(value.cost_base_table_restrictions.conversion_rate).toString() || ''
                    : '';

                this.responseData[value.name] = [];
                value.costs.forEach((costValue: any) => {
                    this.responseData[value.name].push({
                        from: costValue.taxable_weight_lower_bound,
                        to: costValue.taxable_weight_upper_bound,
                        cost: costValue.cost
                    });
                });
                value.after_limit_costs.forEach((afterLimitCostValue: any) => {
                    this.afterLimitConditions.push({
                        zone: value.zone_number || index,
                        weightAbove: afterLimitCostValue.weight_threshold,
                        weightAdditional: afterLimitCostValue.weight_step,
                        cost: afterLimitCostValue.cost,
                        isWeightAboveValid: true,
                        isWeightAdditionalValid: false,
                        isCostValid: false,
                        id: afterLimitCostValue.id
                    });
                });
            });

            let countryNameIndex = 1;

            for (const key of Object.keys(this.responseData)) {
                const countryName = `${countryNameIndex++}. ${key}`;

                this.continentTabsViewModel.items.push({
                    text: countryName,
                    zoneName: key
                });
            }

            this.taxableWeight = this.taxableWeightPerZoneMap[this.selectedTabIndex] || '';
            this.conversionRate = this.conversionRatePerZoneMap[this.selectedTabIndex] || '';
            this.minimumPerPack = this.minimumPerPackMap[this.selectedTabIndex] || '';

            this.checkIfCostBaseTableIsEmpty();

            if (!this.isCostTableNotEmpty) {
                this.showResponseData = false;
            } else {
                this.showResponseData = true;
                this.validateResponseData();
                this.tabClicked();
                this.setupFooter();
            }
        });
    }

    public ngOnInit() {
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];

        this.setupFooter();
        this.initFooterActions();
        this.resolve();

        this.showServerResponseData = !!this.serverResponseData;
        this.costBaseTableContent = this.costBaseTableService.getCostBaseTableContent();
        this.uploadFilesViewModel = this.costBaseTableService.getUploadedFileContent();

        this.costBaseTableService.reimportFileSubject.subscribe(() => {
            const uploadedFileData = this.costBaseTableService.getUploadedFile();
            this.handleSelectedFileReimport(uploadedFileData);
        });

        this.costBaseTableService.reimportSubmittedSubject.subscribe(() => {
            this.uploadListener();
            this.validateResponseData();
            this.afterLimitConditions = [];
        });
    }

    public ngOnDestroy() {
        this.destroyFooterActions();
    }

    public onRemoveDocumentClicked() {
        this.costBaseTableService.clearUploadedFileContent();
        this.showInvalidFileTypeError = false;
        this.showRequiredDocumentError = false;
        this.showInvalidFileStructureError = false;
        this.showResponseDataValidation = false;
        this.showResponseData = false;
        this.clearCostBaseTable();
        this.setupFooter();
    }

    public uploadFileOnSelectedFile(payload: any) {
        this.handleSelectedFile([payload.selectedFile]);
    }

    public handleSelectedFileReimport(files: any) {
        this.uploadCSVFile(files, false);
    }

    public handleSelectedFile(files: any) {
        this.uploadCSVFile(files, true);
    }

    public clearAfterLimitConditionItemAdd() {
        this.afterLimitConditionItemAdd.weightAbove = '';
        this.afterLimitConditionItemAdd.weightAdditional = '';
        this.afterLimitConditionItemAdd.cost = '';
    }

    public onTabClicked($event: any) {
        this.selectedTabIndex = $event.selectedTabIndex;

        this.taxableWeight = this.taxableWeightPerZoneMap[this.selectedTabIndex] || '';
        this.conversionRate = this.conversionRatePerZoneMap[this.selectedTabIndex] || '';
        this.minimumPerPack = this.minimumPerPackMap[this.selectedTabIndex] || '';

        this.tabClicked();
    }

    public getContinentDataByIndex(index: string | number | any) {
        if (!this.responseData) {
            this.showResponseData = false;
        } else {
            return this.responseData[index] || [];
        }
    }

    public getAfterLimitConditionsByIndex(index: string | number | any) {
        if (!this.afterLimitConditions) {
            this.showAfterLimitConditionsButton = true;
        } else {
            return this.afterLimitConditions.filter((d) => d.zone === index);
        }
    }

    public uploadListener(): void {
        this.showRequiredDocumentError = false;
        const continents = this.uploadResults;
        this.responseData = {};
        let previousWeight = 0;
        let itemIndex = 0;
        for (const key of Object.keys(continents)) {
            previousWeight = 0;

            this.responseData[key] = [];
            const continentData = continents[key];
            continentData.forEach(([weight, cost]) => {
                this.responseData[key].push({
                    from: Number(previousWeight),
                    to: weight,
                    cost: cost,
                    id: itemIndex++
                });
                previousWeight = weight;
            });

            this.showResponseData = true;
            this.setupFooter();
        }
    }

    public validateWrongDataImport() {
        const isValid = this.validateResponseData();
        this.showResponseDataValidation = !isValid;
    }

    public validateResponseData(): boolean {
        let isValid = true;
        this.continentTabsViewModel.items = [];
        let countryNameIndex = 1;
        for (const key of Object.keys(this.responseData)) {
            let maxCost = 0;
            let errorCount = 0;
            const continentData = this.responseData[key];
            continentData.forEach((element) => {
                let errorValidation: ErrorValidationModel = {};
                if (element.from === null || element.from.toString().length === 0) {
                    errorValidation.weight = this.emptyValueErrorModel;
                } else if (element.from >= Number(element.to)) {
                    errorValidation.weight = this.invalidWeightRangeErrorModel;
                } else if (isNaN(Number(element.to))) {
                    errorValidation.weight = this.invalidWeightRangeErrorModel;
                } else if (Number(element.to) === 0) {
                    errorValidation.weight = this.emptyValueErrorModel;
                }

                if (element.cost.toString().length === 0) {
                    errorValidation.cost = this.emptyValueErrorModel;
                } else if (isNaN(Number(element.cost))) {
                    errorValidation.cost = this.invalidCharacterValueErrorModel;
                } else if (Number(element.cost) < maxCost) {
                    errorValidation.cost = this.invalidValueErrorModel;
                } else {
                    maxCost = element.cost;
                }

                if (!(errorValidation.cost || errorValidation.weight)) {
                    errorValidation = null;
                } else {
                    isValid = false;
                    if (errorValidation.cost) {
                        errorCount++;
                    }
                    if (errorValidation.weight) {
                        errorCount++;
                    }
                }
                element.error = errorValidation;
            });
            let countryName = `${countryNameIndex++}. ${key}`;
            if (errorCount > 0) {
                countryName += ' <span class="ui-notification-counter" color="red">' + errorCount + '</span>';
            }

            this.continentTabsViewModel.items.push({
                text: countryName,
                zoneName: key
            });
        }
        return isValid;
    }

    public onFileUploadCanceled() {
        this.records = [];
        this.showInvalidFileTypeError = false;
    }

    public addAfterLimitConditionsForm() {
        this.showAfterLimitConditionsButton = false;
    }

    public addAfterLimitConditions() {
        this.afterLimitConditionItemAdd.zone = this.selectedTabIndex;

        const newLimit = {
            ...this.afterLimitConditionItemAdd
        };

        this.afterLimitConditions.push(newLimit);
        this.afterLimitConditions = sortBy(this.afterLimitConditions, [
            (responseAfterLimit) => parseFloat(responseAfterLimit.weightAbove)
        ]);

        if (this.getAfterLimitConditionsByIndex(this.selectedTabIndex).length > 0) {
            this.showAfterLimitConditionsButton = false;
        }
        this.afterLimitConditionItemAdd = {
            zone: 0,
            weightAbove: '',
            weightAdditional: '',
            cost: '',
            isWeightAboveValid: true,
            isWeightAdditionalValid: false,
            isCostValid: false
        };
    }

    public validateAfterLimitConditionItem(afterLimitConditionItem: any, selectedConditionIndexToEdit?: number) {
        afterLimitConditionItem.isWeightAboveValid = true;
        afterLimitConditionItem.isWeightAdditionalValid = true;
        afterLimitConditionItem.isCostValid = true;

        const afterLimitConditionsInSelectedZone = this.afterLimitConditions
            .filter((d) => d.zone === this.selectedTabIndex)
            .filter((_, index) => {
                if (!isNaN(selectedConditionIndexToEdit) && selectedConditionIndexToEdit > -1) {
                    return index !== selectedConditionIndexToEdit;
                }

                return true;
            });

        const selectedZone = this.continentTabsViewModel.items[this.selectedTabIndex];

        const maxWeightInSelectedZone = Math.max(
            ...this.getContinentDataByIndex(selectedZone.zoneName).map((a) => a.to)
        );

        if (Number(afterLimitConditionItem.weightAbove) < maxWeightInSelectedZone) {
            afterLimitConditionItem.isWeightAboveValid = false;
        }
        const existingWeightAbove = afterLimitConditionsInSelectedZone.find(
            (element) => element.weightAbove === afterLimitConditionItem.weightAbove
        );

        if (isNaN(Number(afterLimitConditionItem.weightAbove)) || afterLimitConditionItem.weightAbove === '') {
            afterLimitConditionItem.isWeightAboveValid = false;
        }
        if (
            isNaN(Number(afterLimitConditionItem.weightAdditional)) ||
            afterLimitConditionItem.weightAdditional === ''
        ) {
            afterLimitConditionItem.isWeightAdditionalValid = false;
        }

        if (isNaN(Number(afterLimitConditionItem.cost)) || afterLimitConditionItem.cost === '') {
            afterLimitConditionItem.isCostValid = false;
        }

        if (
            existingWeightAbove != null &&
            (this.isEditAfterLimitConditionModeOn ||
                this.afterLimitConditionItemEdit.weightAbove !== afterLimitConditionItem.weightAbove)
        ) {
            afterLimitConditionItem.isWeightAboveValid = false;
        }

        const existingWeightAboveIndex = afterLimitConditionsInSelectedZone.findIndex(
            (element) => Number(element.weightAbove) === Number(afterLimitConditionItem.weightAbove)
        );
        if (existingWeightAboveIndex > -1 && existingWeightAboveIndex !== this.selectedItemIndex) {
            afterLimitConditionItem.isWeightAboveValid = false;
        }
    }

    public removeAfterLimitCondition(afterLimitCondition: any) {
        const index = this.afterLimitConditions.findIndex(
            (condition) =>
                condition.zone === afterLimitCondition.zone &&
                Number(condition.weightAbove) === Number(afterLimitCondition.weightAbove)
        );
        this.afterLimitConditions.splice(index, 1);
    }

    public openReimportModal(): void {
        this.uiModal
            .openModal(UiModalComponent, [UiModalReimportComponent], {
                title: 'Reimport'
            })
            .afterClose()
            .subscribe();
    }

    public openViewCountriesModal(): void {
        // This.uiModal
        //     .openModal(UiModalComponent, [UiModalCountriesComponent], {
        //         Title: 'View countries'
        //     })
        //     .afterClose()
        //     .subscribe();
    }

    public onWeightAboveChange() {
        this.validateAfterLimitConditionItem(this.afterLimitConditionItemAdd);
    }

    public onWeightAdditionalChange() {
        this.validateAfterLimitConditionItem(this.afterLimitConditionItemAdd);
    }

    public onCurrencyChange() {
        this.validateAfterLimitConditionItem(this.afterLimitConditionItemAdd);
    }

    public onWeightAboveChangeEdit(index: number) {
        this.validateAfterLimitConditionItem(this.afterLimitConditionItemEdit, index);
    }

    public onWeightAdditionalChangeEdit(index: number) {
        this.validateAfterLimitConditionItem(this.afterLimitConditionItemEdit, index);
    }

    public onCurrencyChangeEdit(index: number) {
        this.validateAfterLimitConditionItem(this.afterLimitConditionItemEdit, index);
    }

    public turnOnEditAfterLimitConditionMode(index: number | any) {
        this.selectedItemIndex = index;
        const clickedItem = this.getAfterLimitConditionsByIndex(this.selectedTabIndex)[this.selectedItemIndex];
        this.afterLimitConditionItemEdit.weightAbove = clickedItem.weightAbove;
        this.afterLimitConditionItemEdit.weightAdditional = clickedItem.weightAdditional;
        this.afterLimitConditionItemEdit.cost = clickedItem.cost;
        this.isEditAfterLimitConditionModeOn = true;

        this.validateAfterLimitConditionItem(this.afterLimitConditionItemEdit, index);
    }

    public onDismissAfterLimitEdit() {
        this.isEditAfterLimitConditionModeOn = false;
    }

    public onConfirmAfterLimitEdition() {
        if (this.afterLimitConditionItemEdit.isWeightAboveValid) {
            const clickedItem = this.getAfterLimitConditionsByIndex(this.selectedTabIndex)[this.selectedItemIndex];
            clickedItem.weightAbove = this.afterLimitConditionItemEdit.weightAbove;
            clickedItem.weightAdditional = this.afterLimitConditionItemEdit.weightAdditional;
            clickedItem.cost = this.afterLimitConditionItemEdit.cost;
            this.isEditAfterLimitConditionModeOn = false;
        }
        this.afterLimitConditions = sortBy(this.afterLimitConditions, [
            (responseAfterLimit) => parseFloat(responseAfterLimit.weightAbove)
        ]);
    }

    public checkIfCostBaseTableIsEmpty() {
        this.isCostTableNotEmpty = this.contract.zones.some((zone: any) => !!zone.costs.length);
    }

    public getSampleFile() {
        this.transportCostBaseTable
            .getSampleFile(this.contract, this.carrierAccountId, this.contract.zones)
            .subscribe((res) => {
                const blob = new Blob([res], { type: 'text/csv' });
                saveAs(blob, 'Cost_base_table_sample_download.csv');
            });
    }

    public exportDataFile() {
        this.saveAndSendData();

        const source = this.transportContractProperties
            .patchContract(this.contract, this.carrierAccountId)
            .pipe(concatMap(() => this.transportCostBaseTable.exportCSVFile(this.contract, this.carrierAccountId)));
        source.subscribe((res) => {
            const blob = new Blob([res], { type: 'text/csv' });
            saveAs(blob, 'Cost_base_table_exported.csv');
        });
    }

    public saveAndSendData() {
        this.showRequiredDocumentError = true;
        let countryNameIndex = 0;
        this.contract = omit(this.contract, ['start_date', 'end_date']);
        this.contract.zones.forEach((value: any, zoneIndex: number) => {
            let costIndex = 0;
            const continentDataByIndex = this.getContinentDataByIndex(value.name);
            if (value.costs.length > continentDataByIndex.length) {
                value.costs.splice(continentDataByIndex.length);
            }
            value.costs.forEach((costValue: any) => {
                costValue.cost = continentDataByIndex[costIndex].cost;
                costValue.taxable_weight_lower_bound = continentDataByIndex[costIndex].from;
                costValue.taxable_weight_upper_bound = continentDataByIndex[costIndex].to;
                costIndex++;
            });
            if (value.costs.length < continentDataByIndex.length) {
                for (let i = value.costs.length; i < continentDataByIndex.length; i++) {
                    value.costs.push({
                        cost: continentDataByIndex[i].cost,
                        taxable_weight_lower_bound: continentDataByIndex[i].from,
                        taxable_weight_upper_bound: continentDataByIndex[i].to
                    });
                }
            }

            let afterLimitIndex = 0;
            const afterLimitConditionDataByIndex = this.getAfterLimitConditionsByIndex(countryNameIndex);
            if (value.after_limit_costs.length > afterLimitConditionDataByIndex.length) {
                value.after_limit_costs.splice(afterLimitConditionDataByIndex.length);
            }
            value.after_limit_costs.forEach((afterLimitValue: any) => {
                afterLimitValue.weight_threshold = afterLimitConditionDataByIndex[afterLimitIndex].weightAbove;
                afterLimitValue.weight_step = afterLimitConditionDataByIndex[afterLimitIndex].weightAdditional;
                afterLimitValue.cost = afterLimitConditionDataByIndex[afterLimitIndex].cost;
                afterLimitIndex++;
            });
            if (value.after_limit_costs.length < afterLimitConditionDataByIndex.length) {
                for (let i = value.after_limit_costs.length; i < afterLimitConditionDataByIndex.length; i++) {
                    value.after_limit_costs.push({
                        cost: afterLimitConditionDataByIndex[i].cost,
                        weight_threshold: afterLimitConditionDataByIndex[i].weightAbove,
                        weight_step: afterLimitConditionDataByIndex[i].weightAdditional
                    });
                }
            }

            value.cost_base_table_restrictions = {
                taxable_weight_calculation_type: this.taxableWeightPerZoneMap[zoneIndex] || null,
                conversion_rate: this.conversionRatePerZoneMap[zoneIndex] || null,
                minimum_price_per_pack: this.minimumPerPackMap[zoneIndex] || null
            };

            countryNameIndex++;
        });
        this.footerStateService.setContract(this.contract);
    }

    public onTaxableWeightChange() {
        this.taxableWeightPerZoneMap[this.selectedTabIndex] = this.taxableWeight;

        if (this.taxableWeight === 'real_weight') {
            this.conversionRate = '';
            this.conversionRatePerZoneMap[this.selectedTabIndex] = '';
        }
    }

    public onConversionRateChange() {
        this.conversionRatePerZoneMap[this.selectedTabIndex] = this.conversionRate;
    }

    public onMinimumCurrencyInput() {
        this.minimumPerPackMap[this.selectedTabIndex] = this.minimumPerPack;
    }

    public previousStep() {
        this.saveAndSendData();
        return of(true);
    }

    public validateAndSave() {
        const taxableWeightFormulaMap = {};
        const minimumPerPackMap = {};

        this.continentTabsViewModel.items.forEach((_, index) => {
            taxableWeightFormulaMap[index] = [
                {
                    calculation: this.taxableWeightPerZoneMap[index],
                    conversionRate: this.conversionRatePerZoneMap[index]
                }
            ];
            minimumPerPackMap[index] = [this.minimumPerPackMap[index]];
        });

        return this.uiModal
            .openModal(UiModalComponent, [UiModalConfirmationRulesComponent], {
                title: 'Are all the rules set for each zone?',
                costBaseTableRestrictions: {
                    taxableWeightFormulaMap,
                    minimumPerPackMap,
                    afterLimitPackMapValidation: this.afterLimitConditions.length > 0
                },
                availableZones: this.contract.zones
            })
            .afterClose()
            .pipe(
                switchMap((data) => {
                    const isValid = get(data, 'confirm', false);

                    if (!isValid) {
                        return of(isValid);
                    }

                    this.saveAndSendData();

                    return of(this.contract.zones[0].costs.length > 0).pipe(
                        catchError(() => of(false)),
                        map((valid: boolean) => !!valid)
                    );
                }),
                map((valid: boolean) => !!valid)
            );
    }
}
