import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ZoneTabsModel } from '../models/zone-tabs.model';
import { findSelectedIndex } from '../utils/zones.utils';
import { ZoneTabModel } from '../models/zone-tab.model';
import { InterfaceZoneModel } from '../models/zone.model';

@Injectable({
    providedIn: 'root'
})
export class ZonesService {
    private zonesSubject = new BehaviorSubject<ZoneTabsModel>({} as ZoneTabsModel);
    public zones$: Observable<ZoneTabsModel>;

    constructor() {
        this.zones$ = this.zonesSubject.asObservable();
    }

    static getDefaultZone() {
        return { uuid: '', name: 'Not shipping to', countries: [] };
    }

    static getDefaultZoneModel() {
        return {
            name: ZonesService.getDefaultZone().name,
            zone: ZonesService.getDefaultZone()
        };
    }

    public setZones(zones: InterfaceZoneModel[], selectedZoneUuid = '') {
        const items = [ZonesService.getDefaultZone(), ...zones].map((zone) => ({
            zone,
            text: zone.name
        }));
        const tabs: ZoneTabsModel = {
            items,
            selectedIndex: items.findIndex((item) => item.zone.uuid === selectedZoneUuid)
        };

        this.zonesSubject.next(tabs);
    }

    public setSelected(zone: ZoneTabModel) {
        this.zones$
            .pipe(
                first(),
                map((zones) => ({
                    ...zones,
                    selectedIndex: findSelectedIndex(
                        zones.items.map((zoneTab) => zoneTab.zone),
                        zone
                    )
                }))
            )
            .subscribe((zones) => this.zonesSubject.next(zones));
    }
}
