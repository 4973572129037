import { Component, OnInit } from '@angular/core';
import { AditionalCharginRulesTransportService } from '../../../common/scripts/transport/additional-charging-rules-transport.service';
import { FooterStateService, FooterBtns } from '../../../common/scripts/services/footer-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { get } from 'lodash';
import { of } from 'rxjs';
import { AdditionalChargingRulesService } from '../../../common/scripts/services/additional-charging-rules.service';
import { URL_CONFIG } from '../../../config/url.config';
import { WIZARD_STEPS } from '../../../common/scripts/constants/wizard-steps.constant';

@Component({
    selector: 'additional-charging-rules',
    templateUrl: './additional-charging-rules.component.html'
})
export class AdditionalChargingRulesComponent extends AbstractWizardStep implements OnInit, IsWizardStep {
    public uploadFilesViewModel = {
        isUploading: false,
        isUploaded: false,
        fileUploadPercentage: 0,
        fileName: ''
    };

    public contract: any;
    public carrierAccountId: number;
    public showInvalidFileTypeError = false;
    public additionalChargingRules: any = null;
    public stepNumber: number;

    constructor(
        private transportAdditionalChargingRules: AditionalCharginRulesTransportService,
        private additionalChargingRulesService: AdditionalChargingRulesService,
        private route: ActivatedRoute,
        private router: Router,
        private footerStateService: FooterStateService
    ) {
        super();
    }

    ngOnInit() {
        this.carrierAccountId = this.route.snapshot.queryParams['carrierAccountId'];

        this.route.data.subscribe((data) => {
            this.contract = get(data, 'contract', {});
            this.additionalChargingRules = this.additionalChargingRulesService.getAdditionalChargingRulesModel(
                this.contract.additional_charging_rules
            );
            this.stepNumber = data.step.index + 1;
        });

        this.setupFooter();
    }

    public getSampleFile() {
        this.transportAdditionalChargingRules
            .getSampleFile(this.contract.id, this.carrierAccountId)
            .subscribe((url: string) => {
                window.open(url);
            });
    }

    public uploadFileOnSelectedFile(payload: any) {
        this.transportAdditionalChargingRules
            .uploadFile(payload.selectedFile, this.contract.id, this.carrierAccountId)
            .subscribe(
                (result) => {
                    if (!result) {
                        return;
                    }

                    this.uploadFilesViewModel.isUploaded = result.isUploaded;
                    this.uploadFilesViewModel.isUploading = result.isUploading;
                    this.uploadFilesViewModel.fileUploadPercentage = result.fileUploadPercentage;

                    if (result.isUploaded) {
                        this.additionalChargingRules = this.additionalChargingRulesService.getAdditionalChargingRulesModel(
                            result.body.data.additional_charging_rules
                        );
                        this.contract.additional_charging_rules = result.body.data.additional_charging_rules;
                        this.footerStateService.setContract(this.contract);
                    }
                },
                () => {
                    this.uploadFilesViewModel.isUploading = false;
                    this.uploadFilesViewModel.fileUploadPercentage = 0;
                    this.uploadFilesViewModel.isUploaded = true;
                    this.showInvalidFileTypeError = true;
                }
            );
    }

    public setupFooter() {
        const btns = [
            { btn: FooterBtns.NEXT, state: { visible: true, enabled: true, text: 'Transit times' } },
            { btn: FooterBtns.PREV, state: { visible: true, enabled: true, text: 'Special packs' } }
        ];

        this.footerStateService.toggleAll(btns);
    }

    public previousStep() {
        return of(true);
    }

    public validateAndSave() {
        this.footerStateService.setContract(this.contract);
        return of(true);
    }

    public reimport() {
        this.showInvalidFileTypeError = false;
        this.additionalChargingRules = [];
        this.contract.additional_charging_rules = [];
        this.footerStateService.setContract(this.contract);
        this.uploadFilesViewModel = {
            isUploading: false,
            isUploaded: false,
            fileUploadPercentage: 0,
            fileName: ''
        };
    }

    public onRemoveDocumentClicked() {
        this.uploadFilesViewModel = {
            isUploading: false,
            isUploaded: false,
            fileUploadPercentage: 0,
            fileName: ''
        };
    }
}
