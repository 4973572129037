import { Component, OnInit, Inject } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';

@Component({
    selector: 'ui-modal-gap',
    templateUrl: './ui-modal-gap.component.html'
})
export class UiModalGapComponent implements OnInit {
    constructor(private modal: UiModalService, @Inject('MODAL_DATA') public viewModel: any) {}

    public ngOnInit() {}

    public onConfirm() {
        this.modal.close({
            confirm: true
        });
    }

    public onCancel() {
        this.modal.close({
            confirm: false
        });
    }
}
