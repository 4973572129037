import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ui-number-in-circle',
    templateUrl: './ui-number-in-circle.component.html'
})
export class UiNumberInCircleComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
