/* URL Configuration */
import { Routes } from '@angular/router';
import { ROUTES as PAGES_ROUTES } from '../pages/pages.routes';

export const ROUTES: Routes = [
    {
        path: 'cas',
        children: PAGES_ROUTES
    }
];
