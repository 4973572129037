import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StartEndDateService {
    private startEndDateData: any = {
        stepNumber: 8
    };

    constructor() {}

    public getStartEndDateData(): any {
        return this.startEndDateData;
    }
}
