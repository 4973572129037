import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SpecialPacksInput } from '../interfaces/special-packs';

@Injectable({
    providedIn: 'root'
})
export class SpecialPacksService {
    private specialPacksPropertiesContent: any = {
        stepNumber: 5
    };
    public uploadFilesViewModel = {
        isUploading: false,
        isUploaded: false,
        fileUploadPercentage: 0,
        fileName: ''
    };
    public uploadedFile: any;
    public packId: number;
    public uploadFileSubject = new Subject<any>();
    public reimportFileSubject = new Subject<any>();
    public submitSpecialPacks = new Subject<boolean>();
    public reimportSubmittedSubject = new Subject<any>();

    public reimportSpecialPackSubject = new Subject<any>();

    public errorHandlingSubject = new Subject<boolean>();

    public getSpecialPacksProperties(): SpecialPacksInput {
        return this.specialPacksPropertiesContent;
    }

    public onUploadFileSubject(): void {
        this.uploadFileSubject.next();
    }

    public onReimportSubmitted(): void {
        this.reimportSubmittedSubject.next();
    }

    public onReimportSpecialPack(): void {
        this.reimportSpecialPackSubject.next();
    }

    public onSubmitSpecialPacks(value: boolean): void {
        this.submitSpecialPacks.next(value);
    }

    public reimportComponentMethod(): void {
        this.reimportFileSubject.next();
    }

    public callErrorHandling(value: boolean): void {
        this.errorHandlingSubject.next(value);
    }

    public getUploadedFileContent(): any {
        return this.uploadFilesViewModel;
    }

    public clearUploadedFileContent(): any {
        for (const prop of Object.getOwnPropertyNames(this.uploadFilesViewModel)) {
            delete this.uploadFilesViewModel[prop];
        }
        return this.uploadFilesViewModel;
    }

    public setUploadedFile(file: any): void {
        this.uploadedFile = file;
    }

    public getUploadedFile(): any {
        return this.uploadedFile;
    }

    public setPackId(packId: number): void {
        this.packId = packId;
    }

    public getPackId(): number {
        return this.packId;
    }
}
