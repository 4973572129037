import { Injectable } from '@angular/core';
import { CostBaseTableInput } from '../interfaces/cost-base-table';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CostBaseTableService {
    constructor() {}
    private costBaseTableContent: CostBaseTableInput = {
        stepNumber: 4
    };

    public uploadFilesViewModel = {
        isUploading: false,
        isUploaded: false,
        fileUploadPercentage: 0,
        fileName: ''
    };

    public records: any[] = [];
    public uploadedFile: any;
    public inputTarget: any;

    public uploadFileSubject = new Subject<any>();
    public reimportFileSubject = new Subject<any>();
    public submitCostBaseTable = new Subject<boolean>();
    public reimportSubmittedSubject = new Subject<any>();

    public errorHandlingSubject = new Subject<boolean>();

    public onReimportSubmitted(): void {
        this.reimportSubmittedSubject.next();
    }

    public onSubmitCostBaseTable(value: boolean): void {
        this.submitCostBaseTable.next(value);
    }

    public reimportComponentMethod(): void {
        this.reimportFileSubject.next();
    }

    public callErrorHandling(value: boolean): void {
        this.errorHandlingSubject.next(value);
    }

    public getCostBaseTableContent(): any {
        return this.costBaseTableContent;
    }

    public getUploadedFileContent(): any {
        return this.uploadFilesViewModel;
    }

    public clearUploadedFileContent(): any {
        for (const prop of Object.getOwnPropertyNames(this.uploadFilesViewModel)) {
            delete this.uploadFilesViewModel[prop];
        }
        return this.uploadFilesViewModel;
    }

    public setUploadedFile(file: any): void {
        this.uploadedFile = file;
    }

    public getUploadedFile(): any {
        return this.uploadedFile;
    }
}
