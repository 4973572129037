import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClearAllAndEditModalComponent } from './clear-all-and-edit-modal/clear-all-and-edit-modal.component';
import { NewZoneModalComponent } from './new-zone-modal/new-zone-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components.module';
import { ManageZonesModalComponent } from './manage-zones-modal/manage-zones-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RestoreModalComponent } from './restore-modal/restore-modal.component';
import { AddSpecialPackModalComponent } from './add-special-pack-modal/add-special-pack-modal.component';
import { HuubMaterialLibModule } from '@maersk-global/huub-material';
import { ReimportSpecialPackModalComponent } from './reimport-special-pack-modal/reimport-special-pack-modal.component';
import { UiModalStepsValidatorComponent } from '../ui-modal-steps-validator/ui-modal-steps-validator.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        ClearAllAndEditModalComponent,
        NewZoneModalComponent,
        ManageZonesModalComponent,
        RestoreModalComponent,
        AddSpecialPackModalComponent,
        ReimportSpecialPackModalComponent,
        UiModalStepsValidatorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        DragDropModule,
        HuubMaterialLibModule,
        RouterModule
    ]
})
export class ModalsModule {}
