import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_PATHS } from '../constants/api.constant';
import { ApiService } from '../services/api/api.service';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SpecialPacksTransportService {
    endpoint: string;
    constructor(private apiService: ApiService, @Inject('ENVIRONMENT') ENVIRONMENT) {
        this.endpoint = ENVIRONMENT.API_ENDPOINT;
    }

    public getSpecialPackNames(carrierAccountId: number, contractId: number): Observable<any> {
        const relativePath = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${contractId}/special-packs/list/`;
        const url = `${this.endpoint}${relativePath}/`;

        return this.apiService.get(url);
    }
}
