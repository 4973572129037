import { Component, OnInit } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';
import { CostBaseTableService } from '../../common/scripts/services/cost-base-table.service';
import { saveAs } from 'file-saver';
import { CostBaseTableTransportService } from '../../common/scripts/transport/cost-base-table-transport.service';
import { ContractModel } from '../../common/scripts/models/contract.model';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';
import { FooterStateService } from '../../common/scripts/services/footer-state.service';

@Component({
    selector: 'ui-modal-reimport',
    templateUrl: './ui-modal-reimport.component.html'
})
export class UiModalReimportComponent implements OnInit {
    private carrierAccountId: number;
    private contractId: number;
    contract;

    public viewModel = {};

    public uploadFilesViewModel = {
        isUploading: false,
        isUploaded: false,
        fileUploadPercentage: 0,
        fileName: ''
    };

    public isFileUploaded: boolean;

    public files: any;

    public isInvalidFileError: boolean;
    public showInvalidFileError: boolean;
    public errorValidationHandled: boolean;

    constructor(
        private modal: UiModalService,
        private costBaseTableService: CostBaseTableService,
        private transportCostBaseTable: CostBaseTableTransportService,
        private activatedRoute: ActivatedRoute,
        private footerStateService: FooterStateService
    ) {}

    public ngOnInit() {
        this.contract = this.footerStateService.getContract();

        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];
        this.contractId = this.activatedRoute.snapshot.queryParams['contractId'];

        this.costBaseTableService.errorHandlingSubject.subscribe((res) => {
            this.isInvalidFileError = res;
        });
    }

    public onSubmitClicked() {
        this.modal.close();
    }

    public handleSelectedFile({ selectedFile }) {
        this.files = [selectedFile];
        this.costBaseTableService.setUploadedFile(this.files);
        this.isFileUploaded = true;
        this.uploadFilesViewModel.isUploaded = true;
        this.uploadFilesViewModel.isUploading = false;
        if (!this.isInvalidFileError) {
            this.costBaseTableService.reimportComponentMethod();
        }
    }

    public reimportFile() {
        this.files = this.costBaseTableService.getUploadedFile();
        if (!this.isInvalidFileError) {
            this.costBaseTableService.onReimportSubmitted();
            this.modal.close();
        } else {
            this.showInvalidFileError = true;
        }
    }

    public getSampleFile() {
        this.transportCostBaseTable
            .getSampleFile({ id: this.contractId } as ContractModel, this.carrierAccountId, this.contract.zones)
            .subscribe((res) => {
                const blob = new Blob([res], { type: 'text/csv' });
                saveAs(blob, 'Cost base table sample download.csv');
            });
    }
}
