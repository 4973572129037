export const URL_CONFIG = {
    CAS: {
        INDEX: 'cas',
        DASHBOARD: {
            INDEX: 'dashboard'
        },
        WIZARD: {
            INDEX: 'wizard',
            STEPS: {
                CONTRACT_PROPERTIES: 'contract-properties',
                ZONES: 'zones',
                COST_BASE_TABLE: 'cost-base-table',
                SPECIAL_PACKS: 'special-packs',
                ADDITIONAL_CHARGING_RULES: 'additional-charging-rules',
                TAXABLE_WEIGHT: 'taxable-weight',
                TRANSIT_TIMES: 'transit-times',
                START_END_DATE: 'start-end-date'
            }
        }
    }
};
