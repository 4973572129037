import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchByCountryComponent } from './search-by-country/search-by-country.component';
import { HuubMaterialLibModule } from '@maersk-global/huub-material';
import { ZonesCountriesListComponent } from './zones-countries-list/zones-countries-list.component';
import { ZonesCountriesListItemComponent } from './zones-countries-list-item/zones-countries-list-item.component';
import { FlagIconComponent } from './flag-icon/flag-icon.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UiModalCountriesComponent } from './ui-modal-countries/ui-modal-countries.component';

@NgModule({
    declarations: [
        SearchByCountryComponent,
        ZonesCountriesListComponent,
        ZonesCountriesListItemComponent,
        UiModalCountriesComponent,
        FlagIconComponent
    ],
    imports: [CommonModule, HuubMaterialLibModule, AngularSvgIconModule.forRoot()],
    exports: [SearchByCountryComponent, ZonesCountriesListComponent, ZonesCountriesListItemComponent, FlagIconComponent]
})
export class ComponentsModule {}
