import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CountriesListHelper } from '@maersk-global/huub-utils';

@Component({
    selector: 'contract-info',
    templateUrl: './contract-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CountriesListHelper]
})
export class ContractInfoComponent implements OnInit {
    @Input() public carrier: any;

    @Input() public originCountryIso: string;

    @Input() public owner: string;

    public originCountry: string;

    public carrierOverlayViewModel = {
        text: 'Carrier and service type',
        position: {
            originX: 'center',
            overlayX: 'center'
        }
    };

    public accountCountryOverlayViewModel = {
        text: 'Account country',
        position: {
            originX: 'center',
            overlayX: 'center'
        }
    };

    public ownerOverlayViewModel = {
        text: 'Account owner',
        position: {
            originX: 'center',
            overlayX: 'center'
        }
    };

    constructor(private countriesListHelper: CountriesListHelper) {}

    public ngOnInit() {
        this.originCountry = this.countriesListHelper.getCountryByIso2Code(this.originCountryIso).name;
    }
}
