import { Injectable } from '@angular/core';
import { ZonesPropertiesInput } from '../interfaces/zones-properties';

@Injectable({
    providedIn: 'root'
})
export class ZonesPropertiesService {
    private zonesPropertiesContent: ZonesPropertiesInput = {
        stepNumber: 2
    };

    public getZonesProperties(): ZonesPropertiesInput {
        return this.zonesPropertiesContent;
    }
}
