import { Component, OnInit, Inject } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';

@Component({
    selector: 'ui-confirmation-rules',
    templateUrl: './ui-modal-confirmation-rules.component.html'
})
export class UiModalConfirmationRulesComponent implements OnInit {
    public viewModel = {};

    public minimumPerPackHasError: boolean;
    public taxableWeightCalculationHasError: boolean;
    public afterLimitHasError: boolean;

    public minimumPerPackIcon: string;
    public taxableWeightCalculationIcon: string;
    public afterLimitIcon: string;

    constructor(private modal: UiModalService, @Inject('MODAL_DATA') private data: any) {}

    public ngOnInit() {
        this.minimumPerPackHasError = false;
        this.taxableWeightCalculationHasError = false;
        this.afterLimitHasError =
            (this.data.costBaseTableRestrictions && !this.data.costBaseTableRestrictions.afterLimitPackMapValidation) ||
            false;

        const availableZones = this.data.availableZones;
        const costBaseTableRestrictions = this.data.costBaseTableRestrictions;

        availableZones.forEach((_: any, index: number) => {
            costBaseTableRestrictions.minimumPerPackMap[index].forEach((minimum) => {
                if (minimum === '') {
                    this.minimumPerPackHasError = true;
                }
            });
            costBaseTableRestrictions.taxableWeightFormulaMap[index].forEach((taxableWeight) => {
                if (taxableWeight.calculation === '') {
                    this.taxableWeightCalculationHasError = true;
                    return;
                }

                if (taxableWeight.calculation !== 'real_weight' && isNaN(taxableWeight.conversionRate)) {
                    this.taxableWeightCalculationHasError = true;
                    return;
                }
            });
        });

        this.minimumPerPackIcon = this.minimumPerPackHasError ? 'circle-empty' : 'circle-checked';
        this.taxableWeightCalculationIcon = this.taxableWeightCalculationHasError ? 'circle-empty' : 'circle-checked';
        this.afterLimitIcon = this.afterLimitHasError ? 'circle-empty' : 'circle-checked';
    }

    public confirmaOperation() {
        this.modal.close({ confirm: true });
    }

    public cancelOperation() {
        this.modal.close({ confirm: false });
    }
}
