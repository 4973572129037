/**
 * API_PATHS - Constant for storing relatives paths of different endpoints
 * .
 * IMPORTANT: Constant must be extended when another HTTP endpoint will be provided
 */

export const API_PATHS = {
    CARRIER_ACCOUNTS: 'carriers-accounts',
    CONTRACTS: 'contracts',
    COSTS: 'costs',
    ADDITIONAL_CHARGING_RULES: 'additional-charging-rules',
    IMPORT: 'import',
    SAMPLE: 'sample',
    SERVICE_LEVELS: 'service-levels',
    ACCOUNT_CARRIER_SERVICE_TYPE_LIST: 'account-carrier-service-types-list',
    ACCOUNT_CARRIER_SERVICE_TYPE: 'account-carrier-service-types',
    FUEL_SURCHARGES: 'fuel-surcharges',
    WAREHOUSES: 'warehouses'
};
