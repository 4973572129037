import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ZoneTabsModel } from '../../common/scripts/models/zone-tabs.model';

@Component({
    selector: 'zones-tabs',
    templateUrl: './zones-tabs.component.html'
})
export class ZonesTabsComponent {
    @Input() public zones: ZoneTabsModel;
    @Input() public canAddZones: boolean;

    @Output() public onZoneSelected = new EventEmitter<any>();
    @Output() public onAddZone = new EventEmitter<void>();
    @Output() public onManageZones = new EventEmitter<void>();

    public onSelected(zone) {
        this.onZoneSelected.emit(zone);
    }

    public onAdd() {
        this.onAddZone.emit();
    }

    public manageZones() {
        this.onManageZones.emit();
    }
}
