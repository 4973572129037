import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { head } from 'lodash';

@Component({
    selector: 'search-by-country',
    templateUrl: './search-by-country.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchByCountryComponent {
    @ViewChild('search', { static: false })
    private search: any;

    @Input() public countries = [];

    @Output() public onCountrySearch = new EventEmitter<string>();

    constructor() {}

    public onCountrySearchChange(search) {
        const found: any = head(search.selectedItems) || {};

        this.onCountrySearch.emit(found.text ? found.text.toLowerCase() : '');
    }

    public clearSearch() {
        this.search.clearSearch();
    }
}
