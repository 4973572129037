import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public constructor(private httpClient: HttpClient) {}

    public get(endPoint: string, options?): Observable<any> {
        return this.httpClient.get(endPoint, options);
    }

    public patch(endPoint: string, body: any, options?): Observable<any> {
        return this.httpClient.patch(endPoint, body, options);
    }

    public post(endPoint: string, body?: any, options?): Observable<any> {
        return this.httpClient.post(endPoint, body, options);
    }

    public put(endPoint: string, body: any, options?): Observable<any> {
        return this.httpClient.put(endPoint, body, options);
    }

    public delete(endPoint: string, options?): Observable<any> {
        return this.httpClient.delete(endPoint, options);
    }
}
