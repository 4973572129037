import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get, cloneDeep } from 'lodash';
import { WarehouseService } from '../../../common/scripts/services/warehouse.service';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { FooterBtns, FooterStateService } from '../../../common/scripts/services/footer-state.service';
import { of, combineLatest } from 'rxjs';
import { StepsValidatorService } from '../../../common/scripts/services/steps-validator.service';
import { catchError, switchMap, map } from 'rxjs/operators';
import { ContractTransportService } from '../../../common/scripts/transport/contract-transport.service';
import { UiModalService, UiModalComponent } from '@maersk-global/huub-material';
import { UiModalStepsValidatorComponent } from '../../../components/ui-modal-steps-validator/ui-modal-steps-validator.component';

@Component({
    selector: 'warehouse-selection',
    templateUrl: './warehouse.component.html'
})
export class WarehouseSelectionComponent extends AbstractWizardStep implements OnInit, IsWizardStep {
    public carrierAccountId: number;
    public contract: any;
    public warehousesList: any;
    public selectAllState = false;

    public bannerViewModel = {
        message: 'Ups.. Something is missing',
        subMessage: 'In order to be able to proceed, you need to select at least one warehouse for this contract',
        icon: 'error',
        isDismissable: true,
        isVisible: false
    };

    public stepNumber: number;

    public warehouseCompData = this.warehouseService.getWarehouseData();

    constructor(
        private uiModal: UiModalService,
        private transportContractProperties: ContractTransportService,
        private warehouseService: WarehouseService,
        private activatedRoute: ActivatedRoute,
        private footerStateService: FooterStateService,
        private stepsValidator: StepsValidatorService
    ) {
        super();
    }

    protected setupFooter() {
        const btns = [
            {
                btn: FooterBtns.FINISH,
                state: { visible: true, enabled: true, text: 'Publish contract' }
            },
            {
                btn: FooterBtns.PREV,
                state: { visible: true, enabled: true, text: 'Dates' }
            }
        ];

        this.footerStateService.toggleAll(btns);
    }

    private getPatchContractObservable(isValid: boolean) {
        return of(isValid).pipe(
            catchError(() => of(false)),
            switchMap((valid: any) => {
                if (valid) {
                    const contract = cloneDeep(this.contract);

                    return this.transportContractProperties.patchContract(contract, this.carrierAccountId, true);
                }
                return of(valid);
            }),
            map((valid: boolean) => !!valid)
        );
    }

    public ngOnInit() {
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];

        combineLatest(this.activatedRoute.parent.data, this.activatedRoute.data).subscribe(
            ([{ warehouses }, { contract, step }]) => {
                this.warehousesList = warehouses;
                this.contract = contract;
                this.stepNumber = step.index + 1;

                this.warehousesList.forEach((warehouse) => {
                    warehouse.isActive = this.contract.warehouses.indexOf(warehouse.id) > -1;
                });
            }
        );

        this.setupFooter();
    }

    public onCheckAllChange() {
        this.warehousesList.forEach((warehouse: any) => {
            warehouse.isActive = this.selectAllState;
        });
    }

    public previousStep() {
        this.contract.warehouses = [].concat(
            this.warehousesList.filter((warehouse: any) => warehouse.isActive).map((warehouse: any) => warehouse.id)
        );
        this.footerStateService.setContract(this.contract);
        return of(true);
    }

    public validateAndSave() {
        let isFormValid = true;

        const selectedWarehouses = this.warehousesList.filter((warehouse: any) => warehouse.isActive);

        if (selectedWarehouses.length === 0) {
            isFormValid = false;

            this.bannerViewModel.isVisible = true;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }

        if (isFormValid) {
            this.contract.warehouses = [].concat(
                this.warehousesList.filter((warehouse: any) => warehouse.isActive).map((warehouse: any) => warehouse.id)
            );
            this.footerStateService.setContract(this.contract);

            const validatedStepOne = this.stepsValidator.isValidatedStepOne(this.contract);
            const validatedStepTwo = this.stepsValidator.isValidatedStepTwo(this.contract);
            const validatedStepThree = this.stepsValidator.isValidatedStepThree(this.contract);
            const validatedStepSix = this.stepsValidator.isValidatedStepSix(this.contract);
            const validatedStepSeven = this.stepsValidator.isValidatedStepSeven(this.contract);
            const validatedStepEight = this.stepsValidator.isValidatedStepEight(this.contract);

            const stepsHaveErrors =
                !validatedStepOne ||
                !validatedStepTwo ||
                !validatedStepThree ||
                !validatedStepSix ||
                !validatedStepSeven ||
                !validatedStepEight;

            if (stepsHaveErrors) {
                return this.uiModal
                    .openModal(UiModalComponent, [UiModalStepsValidatorComponent], {
                        title: 'Steps validator',
                        validatedStepOne,
                        validatedStepTwo,
                        validatedStepThree,
                        validatedStepSix,
                        validatedStepSeven,
                        validatedStepEight
                    })
                    .afterClose()
                    .pipe(
                        switchMap(() => of(false)),
                        map((valid: boolean) => !!valid)
                    );
            }
            return this.getPatchContractObservable(isFormValid);
        }

        return this.getPatchContractObservable(isFormValid);
    }
}
