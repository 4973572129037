export const environment = {
    production: false,
    CAS_CONFIG: {
        API_ENDPOINT: 'https://api.carrieraccount.uat.thehuub.io/api/v1',
        IS_ECL: true,
        ECL_PLATFORM_URL: 'https://ecl-platform.uat.maersk.com',
        MOP_APP_NAME: 'CarrierAccountStaticClient-Uat',
        MOP_API_KEY: 'ZVSuLNxEUx8kmOeJ4WX5C5Kxv+TcmS2xPaEZzNKaZ1C4YFb8/avssb/PxEE+8g==',
        MOP_ENV: 'uat',
    },
    domain: ''
};
