import { Inject, Injectable } from '@angular/core';
import { API_PATHS } from '../constants/api.constant';
import { environment } from '../../../../environments/environment';
import { ContractModel } from '../models/contract.model';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api/api.service';
import { map } from 'rxjs/operators';
import { UiUploadFileEventsHelper } from '@maersk-global/huub-material';

const multiPartHttpOptions: any = {
    headers: {},
    observe: 'events',
    reportProgress: true
};

@Injectable({
    providedIn: 'root'
})
export class CostBaseTableTransportService {
    endpoint: string;
    constructor(
        private apiService: ApiService,
        private uploadEventsHelper: UiUploadFileEventsHelper,
        @Inject('ENVIRONMENT') ENVIRONMENT
    ) {
        this.endpoint = ENVIRONMENT.API_ENDPOINT;
    }

    public uploadCSVFile(uploadedFile: File | any, contractId: number, carrierAccountId: number): Observable<any> {
        const relativePath = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${contractId}/${API_PATHS.COSTS}/${API_PATHS.IMPORT}/`;
        const url = `${this.endpoint}${relativePath}`;
        const formData = new FormData();

        formData.append('file', uploadedFile);

        return this.apiService
            .post(url, formData, multiPartHttpOptions)
            .pipe(map((event: any) => this.uploadEventsHelper.getAbstractedEventsDataModel(event)));
    }

    public exportCSVFile(contract: ContractModel, carrierAccountId: number): Observable<any> {
        const relativePath = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${contract.id}/costs/export/`;
        const url = `${this.endpoint}${relativePath}`;

        return this.apiService.get(url, { responseType: 'blob' });
    }

    public getSampleFile(contract: ContractModel, carrierAccountId: number, listZones: Array<any>): Observable<Blob> {
        const relativePath = `/${API_PATHS.CARRIER_ACCOUNTS}/${carrierAccountId}/${API_PATHS.CONTRACTS}/${contract.id}/costs/sample/`;
        const url = `${this.endpoint}${relativePath}`;

        return this.apiService.post(url, { zones: listZones }, { responseType: 'blob' });
    }
}
