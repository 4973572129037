import { Component, ViewEncapsulation } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';

@Component({
    selector: 'ui-modal-remove-contract',
    templateUrl: './ui-modal-remove-contract.component.html'
})
export class UiModalRemoveContractComponent {
    constructor(private modal: UiModalService) {}

    public onConfirm() {
        this.modal.close(true);
    }

    public onCancel() {
        this.modal.close(false);
    }
}
