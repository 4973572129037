import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';

@Component({
    selector: 'ui-modal-carrier-account-service-activity',
    templateUrl: './ui-modal-carrier-account-service-activity.component.html'
})
export class UiModalCarrierAccountServiceActiveComponent {
    public isInputValid = true;
    public inputValue = '';

    constructor(private modal: UiModalService, @Inject('MODAL_DATA') public componentViewModel) {}

    public onConfirm() {
        const compareString = this.componentViewModel.isActive ? 'INACTIVATE' : 'ACTIVATE';
        if (this.inputValue === compareString) {
            this.modal.close(true);
            return;
        }
        this.isInputValid = false;
    }

    public onCancel() {
        this.modal.close(false);
    }
}
