import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IsWizardStep } from './is-wizard-step';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { WIZARD_HEADER } from '../../../components/wizard-header/wizard-header.config';
import { MessagingService } from '../services/messaging.service';
import { ContractTransportService } from '../transport/contract-transport.service';
import { FooterStateService } from '../services/footer-state.service';

// This is the last step path on wizard
const LAST_PATH = 'warehouse';
@Injectable()
export class CanSaveWizardStep  {
    constructor(
        private router: Router,
        private messaging: MessagingService,
        private contractTransportService: ContractTransportService,
        private footerStateService: FooterStateService
    ) {}

    private sendErrorNotification() {
        this.messaging.activate(WIZARD_HEADER.MESSAGES.draft_errored, false);
        return of(false);
    }

    private mapValidate(valid: boolean) {
        return valid;
    }

    private updateContract(isToPublish: boolean, contract: any, carrierAccountId: number) {
        this.contractTransportService
            .patchContract(contract, Number(carrierAccountId), isToPublish)
            .subscribe((response) => {
                this.contractTransportService.contractUpdated.emit(true);
            });
        this.footerStateService.setContract(null);
    }

    canDeactivate(component: IsWizardStep, currentRoute: ActivatedRouteSnapshot): Observable<boolean> {
        const navigation = this.router.getCurrentNavigation();
        const state = navigation.extras.state;

        if (navigation.trigger === 'popstate' || (state.force && state.saveAsDraft)) {
            return component.validateAndSave().pipe(
                map((valid) => {
                    this.updateContract(
                        false,
                        this.footerStateService.getContract(),
                        currentRoute.queryParams.carrierAccountId
                    );
                    return this.mapValidate(valid);
                }),
                catchError(() => this.sendErrorNotification())
            );
        }

        if (state.force) {
            return state.force;
        }

        if (state.finish) {
            return component.validateAndSave().pipe(
                map((valid) => this.mapValidate(valid)),
                catchError(() => this.sendErrorNotification())
            );
        }

        if (currentRoute.data.step.index > state.step.index) {
            return component.previousStep().pipe(
                map((valid) => this.mapValidate(valid)),
                catchError(() => this.sendErrorNotification())
            );
        }

        return component.validateAndSave().pipe(
            map((valid) => this.mapValidate(valid)),
            catchError(() => this.sendErrorNotification())
        );
    }
}
