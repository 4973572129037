import { Component, Inject, OnInit } from '@angular/core';
import { UiModalService } from '@maersk-global/huub-material';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { config } from './new-zone-modal.config';

export const duplicateNameValidator = (forbidden: string[]) => (fc: UntypedFormControl) =>
    forbidden.some((name) => name === fc.value) ? { duplicate: config.errors.duplicate } : null;

const notEmpty = () => (fc: UntypedFormControl) => (fc.value.trim() === '' ? { empty: config.errors.empty } : null);

@Component({
    selector: 'new-zone-modal',
    templateUrl: './new-zone-modal.component.html'
})
export class NewZoneModalComponent implements OnInit {
    private forbiddenNames: string[];

    public zoneForm: UntypedFormGroup;
    public searchByCountries = [];
    public countriesList = [];
    public countriesListFocusIndex: number;
    public config: any;
    public countriesCount = 0;

    constructor(private modal: UiModalService, private fb: UntypedFormBuilder, @Inject('MODAL_DATA') private data: any) {}

    private init() {
        this.initForm();
    }

    private initForm() {
        this.zoneForm = this.fb.group({
            name: ['', Validators.compose([duplicateNameValidator(this.forbiddenNames), notEmpty()])],
            countries: [[]]
        });

        this.zoneForm.get('countries').valueChanges.subscribe((countries) => (this.countriesCount = countries.length));
    }

    public ngOnInit() {
        this.config = config;
        this.searchByCountries = this.data.searchByCountries;
        this.countriesList = this.data.countriesList;
        this.forbiddenNames = this.data.forbiddenNames;

        this.init();

        if (this.data.preselectedCountry) {
            this.zoneForm.patchValue({ countries: [this.data.preselectedCountry] });
            this.countriesListFocusIndex = this.countriesList.findIndex(
                (country) => country.id === this.data.preselectedCountry.id
            );
        }
    }

    public onConfirm() {
        const getRawValue = {
            ...this.zoneForm.getRawValue(),
            name: this.zoneForm.getRawValue().name.trim()
        };

        this.modal.close({ confirm: true, value: getRawValue });
    }

    public onCancel() {
        this.modal.close({ confirm: false });
    }

    public onCountrySearch(search: any) {
        if (!search) {
            this.countriesList = this.data.countriesList;
        } else {
            this.countriesList = this.data.countriesList.filter((country: any) =>
                country.name.toLowerCase().includes(search.toLowerCase())
            );
        }
    }
}
