import { Component, OnInit } from '@angular/core';
import { StartEndDateService } from '../../../common/scripts/services/start-end-date.service';
import { ActivatedRoute } from '@angular/router';
import { get, cloneDeep } from 'lodash';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { FooterBtns, FooterStateService } from '../../../common/scripts/services/footer-state.service';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { ContractTransportService } from '../../../common/scripts/transport/contract-transport.service';
import * as moment_ from 'moment-mini-ts';
import { UiModalService, UiModalComponent } from '@maersk-global/huub-material';
import { UiModalOverlapComponent } from '../../../components/ui-modal-overlap/ui-modal-overlap.component';
import { UiModalGapComponent } from '../../../components/ui-modal-gap/ui-modal-gap.component';
import { StartEndDatePageHelper } from './start-end-date.helper';

const moment = moment_;

@Component({
    selector: 'start-end-date',
    templateUrl: './start-end-date.component.html',
    providers: [StartEndDatePageHelper]
})
export class StartEndDateComponent extends AbstractWizardStep implements OnInit, IsWizardStep {
    public carrierAccountId: number;
    public startEndDateData: any;
    public contract: any;
    public startDateIsInvalid = false;
    public endDateIsInvalid = false;
    public stepNumber: number;

    public isStartDateGapped = false;
    public isEndDateGapped = false;

    public minStartDate: Date = null;
    public maxEndDate: Date = null;

    public weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    public monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    constructor(
        private uiModal: UiModalService,
        private transportContractProperties: ContractTransportService,
        private startEndDateService: StartEndDateService,
        private activatedRoute: ActivatedRoute,
        private footerStateService: FooterStateService,
        private componentHelper: StartEndDatePageHelper
    ) {
        super();
    }

    protected setupFooter() {
        const btns = [
            {
                btn: FooterBtns.NEXT,
                state: { visible: true, enabled: true, text: 'Define the warehouse' }
            },
            {
                btn: FooterBtns.PREV,
                state: { visible: true, enabled: true, text: 'Transit times' }
            }
        ];

        this.footerStateService.toggleAll(btns);
    }

    private convertContractDatesFromString(response: any) {
        response.data.start_date = response.data.start_date ? new Date(response.data.start_date) : null;
        response.data.end_date = response.data.end_date ? new Date(response.data.end_date) : null;
        return response;
    }

    private getPatchContractObservable(isValid: boolean) {
        return of(isValid).pipe(
            catchError(() => of(false)),
            switchMap((valid: boolean) => {
                if (valid) {
                    const contract = cloneDeep(this.contract);

                    contract.start_date = moment(contract.start_date).format('YYYY-MM-DD');
                    contract.end_date = moment(contract.end_date).format('YYYY-MM-DD');
                    this.footerStateService.setContract(contract);
                }
                return of(valid);
            }),
            map((valid: boolean) => !!valid)
        );
    }

    private convertUnformatedDate(date: string) {
        const splitedDate = date.split('-');
        return new Date(`${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`);
    }

    public ngOnInit() {
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];
        this.startEndDateData = this.startEndDateService.getStartEndDateData();

        this.activatedRoute.data.subscribe((data) => {
            this.contract = get(data, 'contract', {});
            this.stepNumber = data.step.index + 1;

            if (this.contract.start_date) {
                this.transportContractProperties
                    .validateDate(
                        this.carrierAccountId,
                        this.contract.id,
                        moment(this.contract.start_date).format('YYYY-MM-DD')
                    )
                    .pipe(map(this.convertContractDatesFromString))
                    .subscribe(({ data: { start_date: minStartDate, end_date: maxEndDate } }) => {
                        const currentContractStartDate = this.contract.start_date
                            ? new Date(this.contract.start_date)
                            : null;
                        const currentContractEndDate = this.contract.end_date ? new Date(this.contract.end_date) : null;

                        const { isStartDateGapped, isEndDateGapped } = this.componentHelper.getContractGapConfig(
                            currentContractStartDate,
                            currentContractEndDate,
                            minStartDate,
                            maxEndDate
                        );

                        this.isStartDateGapped = isStartDateGapped;
                        this.isEndDateGapped = isEndDateGapped;

                        this.minStartDate = minStartDate;
                        this.maxEndDate = maxEndDate;
                    });
            }
        });

        this.setupFooter();
    }

    public onStartDateSelected($event: any) {
        this.contract.start_date = this.convertUnformatedDate($event);
    }

    public onEndDateSelected($event: any) {
        this.contract.end_date = this.convertUnformatedDate($event);
    }

    public previousStep() {
        this.getPatchContractObservable(true);
        return of(true);
    }

    public validateAndSave() {
        let isFormValid = true;
        const currentContractStartDate = this.contract.start_date ? new Date(this.contract.start_date) : null;
        const currentContractEndDate = this.contract.end_date ? new Date(this.contract.end_date) : null;

        this.startDateIsInvalid = false;
        this.endDateIsInvalid = false;

        if (!currentContractStartDate) {
            this.startDateIsInvalid = true;
            isFormValid = false;
        }

        if (!currentContractEndDate || moment(currentContractEndDate).isBefore(currentContractStartDate)) {
            this.endDateIsInvalid = true;
            isFormValid = false;
        }

        if (isFormValid) {
            return this.transportContractProperties
                .validateDate(
                    this.carrierAccountId,
                    this.contract.id,
                    moment(this.contract.start_date).format('YYYY-MM-DD')
                )
                .pipe(
                    catchError(() => of(false)),
                    map(this.convertContractDatesFromString),
                    switchMap(({ data: { start_date: minStartDate, end_date: maxEndDate } }) => {
                        const {
                            hasContractOverlap,
                            isStartDateOverlaped,
                            isEndDateOverlaped
                        } = this.componentHelper.getContractOverlapConfig(
                            currentContractStartDate,
                            currentContractEndDate,
                            minStartDate,
                            maxEndDate
                        );

                        this.minStartDate = minStartDate;
                        this.maxEndDate = maxEndDate;

                        if (hasContractOverlap) {
                            return this.uiModal
                                .openModal(UiModalComponent, [UiModalOverlapComponent], {
                                    title: 'Date Overlap',
                                    isStartDateOverlaped,
                                    isEndDateOverlaped,
                                    minStartDate,
                                    maxEndDate
                                })
                                .afterClose()
                                .pipe(
                                    switchMap(() => {
                                        this.startDateIsInvalid = isStartDateOverlaped;
                                        this.endDateIsInvalid = isEndDateOverlaped;
                                        return of(false);
                                    }),
                                    map((valid: boolean) => !!valid)
                                );
                        }

                        const {
                            hasGapBetweenContracts,
                            isStartDateGapped,
                            isEndDateGapped
                        } = this.componentHelper.getContractGapConfig(
                            currentContractStartDate,
                            currentContractEndDate,
                            minStartDate,
                            maxEndDate
                        );

                        if (hasGapBetweenContracts) {
                            return this.uiModal
                                .openModal(UiModalComponent, [UiModalGapComponent], {
                                    title: 'Date Gap',
                                    isStartDateGapped,
                                    isEndDateGapped,
                                    minStartDate,
                                    maxEndDate
                                })
                                .afterClose()
                                .pipe(
                                    switchMap((data) => {
                                        const isValid = get(data, 'confirm', false);

                                        this.isStartDateGapped = isStartDateGapped;
                                        this.isEndDateGapped = isEndDateGapped;

                                        if (!isValid) {
                                            this.startDateIsInvalid = isStartDateGapped;
                                            this.endDateIsInvalid = isEndDateGapped;

                                            return of(isValid);
                                        }

                                        return this.getPatchContractObservable(isFormValid);
                                    }),
                                    map((valid: boolean) => !!valid)
                                );
                        }

                        return this.getPatchContractObservable(isFormValid);
                    }),
                    map((valid: boolean) => !!valid)
                );
        }

        return this.getPatchContractObservable(isFormValid);
    }
}
