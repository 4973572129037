import { Injectable } from '@angular/core';
import { ContractModel } from '../models/contract.model';

@Injectable({
    providedIn: 'root'
})
export class StepsValidatorService {
    constructor() {}

    public isValidatedStepOne(contract: ContractModel): boolean {
        return !!(
            contract.payment_conditions !== '' &&
            contract.credit_days > 0 &&
            contract.currency_iso3_code !== '' &&
            contract.target_shipments > 0
        );
    }

    public isValidatedStepTwo(contract: ContractModel): boolean {
        const zonesWithoutCountries = contract.zones.filter((zone) => zone.countries.length === 0);
        return !!(contract.zones.length > 0 && zonesWithoutCountries.length === 0);
    }

    public isValidatedStepThree(contract: ContractModel): boolean {
        return (
            contract.zones.filter(
                (zone) =>
                    zone.costs.length > 0 &&
                    zone.cost_base_table_restrictions.taxable_weight_calculation_type !== '' &&
                    zone.cost_base_table_restrictions.minimum_price_per_pack > -1
            ).length > 0
        );
    }

    // Not a mandatory one
    // Public isValidatedStepFour(contract: ContractModel): boolean {
    //     Return true;
    // }

    // Public isValidatedStepFive(contract: ContractModel): boolean {
    //     Return contract.payment_conditions !== ''
    // }

    public isValidatedStepSix(contract: ContractModel): boolean {
        return (
            contract.zones.filter(
                (zone) =>
                    zone.countries.filter(
                        (country) =>
                            country.transit_times &&
                            country.transit_times.lower_bound_days > -1 &&
                            country.transit_times.upper_bound_days > -1 &&
                            Number(country.transit_times.upper_bound_days) >
                                Number(country.transit_times.lower_bound_days) &&
                            Number(country.transit_times.upper_bound_days) !==
                                Number(country.transit_times.lower_bound_days) &&
                            country.transit_times.service_level !== ''
                    ).length > 0
            ).length > 0
        );
    }

    public isValidatedStepSeven(contract: ContractModel): boolean {
        return !!(contract.start_date !== '' && contract.end_date !== '');
    }

    public isValidatedStepEight(contract: ContractModel): boolean {
        return !!(contract.warehouses && contract.warehouses.length > 0);
    }
}
