import { WIZARD_STEPS } from '../constants/wizard-steps.constant';

export const updateItem = (index: number, [key, value]) => {
    const nextIdx = index + 1;
    const prevIdx = index - 1;
    const nextDef = Object.values(WIZARD_STEPS).find((wizardStepItem) => wizardStepItem.index === nextIdx);
    const prevDef = Object.values(WIZARD_STEPS).find((wizardStepItem) => wizardStepItem.index === prevIdx);

    switch (key) {
        case 'prev':
            return { [key]: { ...value, ...prevDef, item: prevDef } };
        case 'next':
            return { [key]: { ...value, ...nextDef, item: nextDef } };
        default:
            return { [key]: value };
    }
};

export const toFooterSpec = (spec: any) => {
    const footer = Object.entries(spec.footer);
    return footer.reduce((accu, item) => ({ ...accu, ...updateItem(spec.index, item) }), {});
};
