import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { WeightUnitsListHelper } from '@maersk-global/huub-utils';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'weight-units',
    templateUrl: './weight-units.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WeightUnitsComponent), // eslint-disable-line
            multi: true
        }
    ]
})
export class WeightUnitsComponent implements OnInit {
    constructor(private weightUnitsListHelper: WeightUnitsListHelper, private route: ActivatedRoute) {}

    set value(val) {
        if (val !== undefined && this.val !== val) {
            this.val = parseFloat(val || 0);
            this.onChange(this.val);
            this.onTouch(this.val);
        }
    }

    @Input() public weightUnit?: string;

    public weightUnitSymbol = '';
    public weightUnitList = this.weightUnitsListHelper.getWeightUnitsList();
    public val: number | string;

    ngOnInit() {
        this.route.data.subscribe(({ contract }) => {
            this.weightUnitSymbol = contract.weight_unit;
        });
    }

    public onChange: any = () => {};
    public onTouch: any = () => {};

    public writeValue(value: any) {
        this.value = value;
    }

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}
