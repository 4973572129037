import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { unionBy, get, xorBy } from 'lodash';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'zones-countries-list',
    templateUrl: './zones-countries-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ZonesCountriesListComponent, // eslint-disable-line
            multi: true
        }
    ]
})
export class ZonesCountriesListComponent implements ControlValueAccessor, AfterViewInit {
    private selected = [];

    @Input() public countries: any[];
    @Input() public selectable: boolean;
    @Input() public canAdd: boolean;
    @Input() public canEdit: boolean;
    @Input() public focusIndex?: number;
    @Input() public moveToZones?: any[];

    @Output() public onAddToZone = new EventEmitter<any>();
    @Output() public oMoveToZone = new EventEmitter<any>();
    @Output() public onRemoveFromZone = new EventEmitter<any>();

    @ViewChildren('countryListItem', { read: ElementRef })
    public listItems: QueryList<ElementRef>;

    private setSelected(country, checked) {
        this.selected = checked ? unionBy(this.selected, [country], 'id') : xorBy(this.selected, [country], 'id');
    }

    public onChange = (value) => {};

    public ngAfterViewInit() {
        if (this.focusIndex) {
            this.listItems.toArray()[this.focusIndex].nativeElement.scrollIntoView();
        }
    }

    public onCountrySelectionChange(data) {
        const checked = get(data, 'checked', false);
        this.setSelected(data.item, checked);
        this.onChange(this.selected);
    }

    public registerOnChange(fn) {
        this.onChange = fn;
    }

    public registerOnTouched() {}

    public writeValue(value) {
        if (Array.isArray(value)) {
            value.forEach((country) => this.setSelected(country, true));
        }
    }

    public trackByCountryId(index, country) {
        return get(country, 'id');
    }

    public isSelected(country) {
        return this.selected.some((item) => item.id === country.id);
    }

    public onAddItemToZone(country) {
        this.onAddToZone.emit(country);
    }

    public onRemoveItemFromZone(country) {
        this.onRemoveFromZone.emit(country);
    }

    public moveToZone(data) {
        this.oMoveToZone.emit(data);
    }
}
