import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'nav-dropdown',
    templateUrl: './nav-dropdown.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavDropdownComponent {
    @Input()
    set viewModel(viewModel: any) {
        this.vModel = viewModel;
        this.activeItem = viewModel.items.find((item: any) => this.router.url.includes(item.path));
    }

    vModel: any;
    activeItem: any;

    constructor(private router: Router) {}

    public onItemSelected(item: any) {
        this.activeItem = item;
    }
}
