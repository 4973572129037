import { Component, OnInit } from '@angular/core';
import { UiModalComponent, UiModalService } from '@maersk-global/huub-material';
import { AddSpecialPackModalComponent } from '../../../components/modals/add-special-pack-modal/add-special-pack-modal.component';
import { FooterBtns, FooterStateService } from '../../../common/scripts/services/footer-state.service';
import { ActivatedRoute } from '@angular/router';
import { get, omitBy, isNil, remove, sortBy } from 'lodash';
import { ContractTransportService } from '../../../common/scripts/transport/contract-transport.service';
import { CurrenciesListHelper } from '@maersk-global/huub-utils';
import { SpecialPacksTransportService } from '../../../common/scripts/transport/special-packs-transport.service';
import { SpecialPacksService } from '../../../common/scripts/services/special-packs.service';
import { CostBaseTableTransportService } from '../../../common/scripts/transport/cost-base-table-transport.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AbstractWizardStep } from '../../../common/scripts/helpers/component.helper';
import { IsWizardStep } from '../../../common/scripts/guards/is-wizard-step';
import { SpecialPacksInput } from '../../../common/scripts/interfaces/special-packs';
import { saveAs } from 'file-saver';
import { ReimportSpecialPackModalComponent } from '../../../components/modals/reimport-special-pack-modal/reimport-special-pack-modal.component';
import { UiModalConfirmationRulesComponent } from '../../../components/ui-modal-confirmation-rules/ui-modal-confirmation-rules.component';

@Component({
    selector: 'special-packs',
    templateUrl: './special-packs.component.html'
})
export class SpecialPacksComponent extends AbstractWizardStep implements OnInit, IsWizardStep {
    private carrierAccountId: number;
    private contractId: number;

    public uploadFilesViewModel = {
        isUploading: false,
        isUploaded: false,
        fileUploadPercentage: 0,
        fileName: ''
    };

    public stepNumber: number;
    public specialPacksContent: SpecialPacksInput;
    public showTable = true;
    public selectedTabIndex = 0;
    public selectedZoneName = '';
    public contract: any;
    public weightUnit = '';
    public currency = '';
    public currencySymbol: string;
    public isCostTableNotEmpty: boolean;
    public uploadResults: any;
    public isPackEmpty: boolean;
    public currentActiveAfterLimitConditionPack: number;
    public currentActiveAfterLimitConditionZone: number;
    public isEditAfterLimitConditionModeOn = false;
    public selectedItemIndex: number;

    public packsData = [];

    public continentTabsViewModel = {
        items: [],
        selectedIndex: 0
    };

    public afterLimitConditionItemAdd = {
        zone: null,
        pack: null,
        weightAbove: '',
        weightAdditional: '',
        cost: '',
        isWeightAboveValid: true,
        isWeightAdditionalValid: true,
        isCostValid: true
    };

    public afterLimitConditionItemEdit = {
        weightAbove: '',
        weightAdditional: '',
        cost: '',
        isWeightAboveValid: true,
        isWeightAdditionalValid: true,
        isCostValid: true
    };

    public afterLimitConditions = [];
    public specialPacksRestrictions = {};
    public costs: any = {};

    public minimumPerPackMap = {};
    public conversionRatePerZoneMap = {};
    public taxableWeightPerZoneMap = {};

    constructor(
        private uiModal: UiModalService,
        private footerStateService: FooterStateService,
        private activatedRoute: ActivatedRoute,
        private currenciesListHelper: CurrenciesListHelper,
        private transport: SpecialPacksTransportService,
        private specialPacksService: SpecialPacksService,
        private transportCostBaseTable: CostBaseTableTransportService
    ) {
        super();
    }

    protected setupFooter() {
        const btns = [
            { btn: FooterBtns.NEXT, state: { visible: true, enabled: true, text: 'Additional charging rules' } },
            { btn: FooterBtns.PREV, state: { visible: true, enabled: true, text: 'Cost base table' } }
        ];

        this.footerStateService.toggleAll(btns);
    }

    private tabClicked() {
        this.clearAfterLimitConditionItemAdd();
    }

    private loadSpecialPacksRestrictions(zoneName, packId, specialPackRestrictions: any = {}) {
        this.specialPacksRestrictions[zoneName] = this.specialPacksRestrictions[zoneName] || {};
        this.specialPacksRestrictions[zoneName][packId] = {
            taxableWeightCalculationType: specialPackRestrictions.taxable_weight_calculation_type || '',
            conversionRate: Number(specialPackRestrictions.conversion_rate).toString() || '',
            minimumPricePerPack: specialPackRestrictions.minimum_price_per_pack || ''
        };
    }

    private resolve() {
        this.activatedRoute.data.subscribe((data) => {
            // this.contract = get(data, 'contract', {});
            this.stepNumber = data.step.index + 1;
        });
        this.contract = this.footerStateService.getContract();
        this.weightUnit = this.contract.weight_unit;
        this.currency = this.contract.currency_iso3_code;
        this.currencySymbol = this.currenciesListHelper.getCurrencyByIso3Code(this.currency).symbol;
        let countryNameIndex = 1;
        this.costs = {};

        this.contract.zones.forEach((zone: any) => {
            const countryName = `${countryNameIndex++}. ${zone.name}`;
            this.continentTabsViewModel.items.push({
                text: countryName,
                zoneName: zone.name
            });
            this.costs[zone.name] = {};
            if (zone.special_packs) {
                zone.special_packs.forEach((pack) => {
                    if (!this.costs[zone.name][pack.pack]) {
                        this.costs[zone.name][pack.pack] = [];
                    }
                    this.costs[zone.name][pack.pack].push({
                        from: Number(pack.taxable_weight_lower_bound),
                        to: Number(pack.taxable_weight_upper_bound),
                        cost: pack.cost,
                        isValid: true
                    });
                });
            }
            if (zone.after_limit_special_packs) {
                zone.after_limit_special_packs.forEach((afterLimitCostValue) => {
                    this.afterLimitConditions.push({
                        zone: zone.name,
                        weightAbove: afterLimitCostValue.weight_threshold,
                        weightAdditional: afterLimitCostValue.weight_step,
                        cost: afterLimitCostValue.cost,
                        isWeightAboveValid: true,
                        isWeightAdditionalValid: false,
                        isCostValid: false,
                        id: afterLimitCostValue.id,
                        pack: afterLimitCostValue.pack
                    });
                });
            }

            if (zone.special_packs_restrictions) {
                zone.special_packs_restrictions.forEach((special_packs_restriction) => {
                    this.loadSpecialPacksRestrictions(
                        zone.name,
                        special_packs_restriction.pack,
                        special_packs_restriction
                    );
                });
            }
        });

        this.showTable = this.contract.zones.every(
            (zone) =>
                zone.special_packs.length &&
                zone.special_packs.length > 0 &&
                zone.special_packs_restrictions &&
                zone.special_packs_restrictions.length > 0
        );

        if (this.showTable) {
            this.setupFooter();
            this.selectedZoneName = this.continentTabsViewModel.items[this.selectedTabIndex].zoneName;
        }

        this.getSpecialPacksName();
    }

    private uploadCSVFile(files, shouldAnalizeFile) {
        if (files.length === 0) {
            return;
        }

        this.transportCostBaseTable.uploadCSVFile(files[0], this.contract.id, this.carrierAccountId).subscribe(
            (result) => {
                if (!result) {
                    return;
                }

                this.uploadFilesViewModel.isUploaded = result.isUploaded;
                this.uploadFilesViewModel.isUploading = result.isUploading;
                this.uploadFilesViewModel.fileUploadPercentage = result.fileUploadPercentage;

                if (result.isUploaded) {
                    this.uploadResults = result.body.data[0];

                    if (shouldAnalizeFile) {
                        this.uploadListener();
                    }
                }
            },
            () => {
                this.uploadFilesViewModel.isUploading = false;
                this.uploadFilesViewModel.fileUploadPercentage = 0;
                this.uploadFilesViewModel.isUploaded = true;

                this.specialPacksService.callErrorHandling(true);
            }
        );

        this.specialPacksService.getUploadedFileContent().isUploaded = true;
        this.specialPacksService.getUploadedFileContent().isUploading = false;
    }

    public ngOnInit() {
        this.contractId = this.activatedRoute.snapshot.queryParams['contractId'];
        this.carrierAccountId = this.activatedRoute.snapshot.queryParams['carrierAccountId'];

        this.setupFooter();
        this.resolve();

        this.specialPacksContent = this.specialPacksService.getSpecialPacksProperties();

        this.uploadFilesViewModel = this.specialPacksService.getUploadedFileContent();

        this.specialPacksService.reimportFileSubject.subscribe(() => {
            const uploadedFileData = this.specialPacksService.getUploadedFile();
            this.handleSelectedFileReimport(uploadedFileData);
        });

        this.specialPacksService.reimportSubmittedSubject.subscribe(() => {
            this.uploadListener();
        });

        this.specialPacksService.reimportSpecialPackSubject.subscribe((res) => {
            this.uploadListener();
        });

        this.specialPacksService.uploadFileSubject.subscribe((res) => {
            const uploadedFileData = this.specialPacksService.getUploadedFile();
            this.uploadCSVFile(uploadedFileData, true);
        });
    }

    public handleSelectedFileReimport(files) {
        this.uploadCSVFile(files, false);
    }

    public openAddSpecialPackModal(): void {
        this.uiModal
            .openModal(UiModalComponent, [AddSpecialPackModalComponent], {
                title: 'New pack'
            })
            .afterClose()
            .subscribe();
    }

    public openReimportModal(): void {
        this.uiModal
            .openModal(UiModalComponent, [ReimportSpecialPackModalComponent], {
                title: 'Reimport pack'
            })
            .afterClose()
            .subscribe();
    }

    public onTabClicked($event) {
        this.selectedTabIndex = $event.selectedTabIndex;
        this.selectedZoneName = this.continentTabsViewModel.items[this.selectedTabIndex].zoneName;
        this.tabClicked();
    }

    public clearAfterLimitConditionItemAdd() {
        this.afterLimitConditionItemAdd.weightAbove = '';
        this.afterLimitConditionItemAdd.weightAdditional = '';
        this.afterLimitConditionItemAdd.cost = '';
    }

    public getPackByIndex(index) {
        return this.packsData.filter((d) => d.pack === index);
    }

    public removePackFromZones(packId) {
        Object.values(this.costs).forEach((zone) => {
            delete zone[packId];
        });

        Object.values(this.specialPacksRestrictions).forEach((zone) => {
            delete zone[packId];
        });

        this.afterLimitConditions.forEach((limitCondition, index) => {
            if (limitCondition.pack === packId) {
                this.afterLimitConditions.splice(index, 1);
            }
        });
    }

    public getAfterLimitConditionsByZone(zoneName, packId) {
        return this.afterLimitConditions.filter((d) => Number(d.pack) === Number(packId) && d.zone === zoneName);
    }

    public getAfterLimitConditionsDataByZoneWithoutPack(zoneName) {
        return this.afterLimitConditions.filter((d) => d.zone === zoneName);
    }

    public checkIfCostBaseTableIsEmpty() {
        this.isCostTableNotEmpty = this.contract.zones.some((zone) => !!zone.special_packs.length);
    }

    public getSpecialPacksName() {
        this.transport.getSpecialPackNames(this.carrierAccountId, this.contractId).subscribe((res) => {
            res.data.packs.forEach((val) => {
                this.packsData.push({
                    id: val.id,
                    name: val.name,
                    carrier_service_type: val.carrier_service_type
                });
            });
        });
    }

    public openViewCountriesModal(): void {
        // This.uiModal
        //     .openModal(UiModalComponent, [UiModalCountriesComponent], {
        //         Title: 'View countries'
        //     })
        //     .afterClose()
        //     .subscribe();
    }

    public addAfterLimitConditionsForm(packId) {
        this.currentActiveAfterLimitConditionPack = packId;
        this.currentActiveAfterLimitConditionZone = this.selectedTabIndex;
    }

    public turnOnEditAfterLimitConditionMode(index, packId) {
        this.selectedItemIndex = index;
        const clickedItem = this.getAfterLimitConditionsByZone(this.selectedZoneName, packId)[this.selectedItemIndex];
        this.afterLimitConditionItemEdit.weightAbove = clickedItem.weightAbove;
        this.afterLimitConditionItemEdit.weightAdditional = clickedItem.weightAdditional;
        this.afterLimitConditionItemEdit.cost = clickedItem.cost;
        this.isEditAfterLimitConditionModeOn = true;
        this.validateWeightAbove(this.afterLimitConditionItemEdit, packId);
        this.validateWeightAdditional(this.afterLimitConditionItemEdit);
        this.validateCost(this.afterLimitConditionItemEdit);
    }

    public onDismissAfterLimitEdit() {
        this.isEditAfterLimitConditionModeOn = false;
    }

    public onConfirmAfterLimitEdition(packId) {
        if (this.afterLimitConditionItemEdit.isWeightAboveValid) {
            const clickedItem = this.getAfterLimitConditionsByZone(this.selectedZoneName, packId)[
                this.selectedItemIndex
            ];
            clickedItem.weightAbove = this.afterLimitConditionItemEdit.weightAbove;
            clickedItem.weightAdditional = this.afterLimitConditionItemEdit.weightAdditional;
            clickedItem.cost = this.afterLimitConditionItemEdit.cost;
            this.isEditAfterLimitConditionModeOn = false;
        }
        this.afterLimitConditions = sortBy(this.afterLimitConditions, [
            (responseAfterLimit) => parseFloat(responseAfterLimit.weightAbove)
        ]);
    }

    public uploadListener(): void {
        const continents = this.uploadResults;
        let previousWeight = 0;
        const selectedPackId = this.specialPacksService.getPackId();
        Object.keys(continents).forEach((zoneName) => {
            previousWeight = 0;
            this.costs[zoneName][selectedPackId] = [];
            const continentData = continents[zoneName];
            continentData.forEach(([weight, cost]) => {
                this.costs[zoneName][selectedPackId].push({
                    from: Number(previousWeight),
                    to: Number(weight),
                    cost: cost,
                    isValid: true
                });
                previousWeight = weight;
            });
            this.showTable = true;
            this.isPackEmpty = false;
            this.loadSpecialPacksRestrictions(zoneName, selectedPackId);
        });
        this.selectedZoneName = this.continentTabsViewModel.items[this.selectedTabIndex].zoneName;
    }

    public addAfterLimitConditions(packId) {
        this.afterLimitConditionItemAdd.zone = this.selectedZoneName;
        this.afterLimitConditionItemAdd.pack = packId;

        const newLimit = {
            ...this.afterLimitConditionItemAdd
        };

        this.afterLimitConditions.push(newLimit);
        this.afterLimitConditions = sortBy(this.afterLimitConditions, [
            (responseAfterLimit) => parseFloat(responseAfterLimit.weightAbove)
        ]);

        this.afterLimitConditionItemAdd = {
            zone: 0,
            pack: packId,
            weightAbove: '',
            weightAdditional: '',
            cost: '',
            isWeightAboveValid: true,
            isWeightAdditionalValid: true,
            isCostValid: true
        };
    }

    public onWeightAboveChange(packId) {
        this.validateWeightAbove(this.afterLimitConditionItemAdd, packId);
    }

    public onWeightAdditionalChange() {
        this.validateWeightAdditional(this.afterLimitConditionItemAdd);
    }

    public onCurrencyChange() {
        this.validateCost(this.afterLimitConditionItemAdd);
    }

    public onWeightAboveChangeEdit(packId) {
        this.validateWeightAbove(this.afterLimitConditionItemEdit, packId);
    }

    public onWeightAdditionalChangeEdit() {
        this.validateWeightAdditional(this.afterLimitConditionItemEdit);
    }

    public onCurrencyChangeEdit() {
        this.validateCost(this.afterLimitConditionItemEdit);
    }

    public validateWeightAdditional(afterLimitConditionItem) {
        afterLimitConditionItem.isWeightAdditionalValid = true;
        if (
            isNaN(Number(afterLimitConditionItem.weightAdditional)) ||
            afterLimitConditionItem.weightAdditional === ''
        ) {
            afterLimitConditionItem.isWeightAdditionalValid = false;
        }
    }

    public validateCost(afterLimitConditionItem) {
        afterLimitConditionItem.isCostValid = true;
        if (isNaN(Number(afterLimitConditionItem.cost)) || afterLimitConditionItem.cost === '') {
            afterLimitConditionItem.isCostValid = false;
        }
    }

    public validateWeightAbove(afterLimitConditionItem, packId) {
        afterLimitConditionItem.isWeightAboveValid = true;
        const afterLimitConditionsInSelectedZoneAndPack = this.afterLimitConditions.filter(
            (d) => d.zone === this.selectedZoneName && d.pack === packId
        );

        const maxWeightInSelectedZone = Math.max(...this.costs[this.selectedZoneName][packId].map((a) => a.to));

        if (Number(afterLimitConditionItem.weightAbove) < maxWeightInSelectedZone) {
            afterLimitConditionItem.isWeightAboveValid = false;
        }

        if (isNaN(Number(afterLimitConditionItem.weightAbove)) || afterLimitConditionItem.weightAbove === '') {
            afterLimitConditionItem.isWeightAboveValid = false;
        }
        const existingWeightAboveIndex = afterLimitConditionsInSelectedZoneAndPack.findIndex(
            (element) => Number(element.weightAbove) === Number(afterLimitConditionItem.weightAbove)
        );
        if (existingWeightAboveIndex > -1 && existingWeightAboveIndex !== this.selectedItemIndex) {
            afterLimitConditionItem.isWeightAboveValid = false;
        }
    }

    public removeAfterLimitCondition(position) {
        remove(this.afterLimitConditions, position);
    }

    public saveAndSendData() {
        this.contract.zones.forEach((zone) => {
            zone.special_packs = [];
            zone.special_packs_restrictions = [];
            zone.after_limit_special_packs = [];
            // ADD SPECIAL PACKS COSTS
            Object.keys(this.costs[zone.name]).forEach((packId) => {
                const packCosts = this.costs[zone.name][packId];
                packCosts.forEach((packCost) => {
                    zone.special_packs.push({
                        cost: Number(packCost.cost),
                        taxable_weight_lower_bound: Number(packCost.from),
                        taxable_weight_upper_bound: Number(packCost.to),
                        pack: Number(packId)
                    });
                });
            });

            // ADD SPECIAL PACKS RESTRICTIONS
            if (this.specialPacksRestrictions[zone.name]) {
                Object.keys(this.specialPacksRestrictions[zone.name]).forEach((packId) => {
                    const specialPackRestrictions = this.specialPacksRestrictions[zone.name][packId];
                    zone.special_packs_restrictions.push({
                        taxable_weight_calculation_type: specialPackRestrictions.taxableWeightCalculationType,
                        conversion_rate: Number(specialPackRestrictions.conversionRate),
                        minimum_price_per_pack: Number(specialPackRestrictions.minimumPricePerPack),
                        pack: Number(packId)
                    });
                });
            }

            // ADD SPECIAL PACKS AFTER LIMIT COSTS
            const zoneAfterLimitConditions = this.afterLimitConditions.filter(
                (condition) => condition.zone === zone.name
            );
            zoneAfterLimitConditions.forEach((afterLimitCondition) => {
                zone.after_limit_special_packs.push({
                    cost: Number(afterLimitCondition.cost),
                    weight_threshold: Number(afterLimitCondition.weightAbove),
                    weight_step: Number(afterLimitCondition.weightAdditional),
                    pack: Number(afterLimitCondition.pack)
                });
            });
        });
    }

    public previousStep() {
        this.saveAndSendData();
        this.footerStateService.setContract(this.contract);
        return of(true);
    }

    public validateAndSave() {
        if (Object.keys(this.costs).length === 0) {
            return of(true);
        }
        this.saveAndSendData();
        const taxableWeightFormulaMap = {};
        const minimumPerPackMap = {};
        let afterLimitPackMapValidation = true;

        this.continentTabsViewModel.items.forEach((zone, index) => {
            taxableWeightFormulaMap[index] = [];
            minimumPerPackMap[index] = [];
            if (this.specialPacksRestrictions[zone.zoneName]) {
                Object.entries(this.specialPacksRestrictions[zone.zoneName]).forEach(([packId, pack]: any) => {
                    taxableWeightFormulaMap[index].push({
                        calculation: pack.taxableWeightCalculationType,
                        conversionRate: pack.conversionRate
                    });
                    minimumPerPackMap[index].push(pack.minimumPricePerPack);

                    afterLimitPackMapValidation = afterLimitPackMapValidation
                        ? this.afterLimitConditions.findIndex(
                              (limitCondition) =>
                                  limitCondition.zone === zone.zoneName && limitCondition.pack === Number(packId)
                          ) !== -1
                        : afterLimitPackMapValidation;
                });
            }
        });
        return this.uiModal
            .openModal(UiModalComponent, [UiModalConfirmationRulesComponent], {
                title: 'Are all the rules set for each zone?',
                costBaseTableRestrictions: {
                    taxableWeightFormulaMap,
                    minimumPerPackMap,
                    afterLimitPackMapValidation
                },
                availableZones: this.contract.zones
            })
            .afterClose()
            .pipe(
                switchMap((data) => {
                    const isValid = get(data, 'confirm', false);

                    if (!isValid) {
                        return of(isValid);
                    }
                    this.footerStateService.setContract(this.contract);
                    return of(isValid).pipe(
                        catchError(() => of(false)),
                        map((valid: boolean) => !!valid)
                    );
                }),
                map((valid: boolean) => !!valid)
            );
    }

    public getSampleFile() {
        this.transportCostBaseTable
            .getSampleFile(this.contract, this.carrierAccountId, this.contract.zones)
            .subscribe((res) => {
                const blob = new Blob([res], { type: 'text/csv' });
                saveAs(blob, 'Special_packs_sample_download.csv');
            });
    }

    public validateCostChange(packId) {
        this.costs[this.selectedZoneName][packId].forEach((costEntry, index) => {
            if (index === 0) {
                return;
            }
            if (
                this.costs[this.selectedZoneName][packId]
                    .slice(0, index)
                    .find((previousPackCost) => parseFloat(costEntry.cost) < parseFloat(previousPackCost.cost))
            ) {
                costEntry.isValid = false;
                return;
            }
            costEntry.isValid = true;
        });
    }
}
