import { NgModule, ApplicationRef } from '@angular/core';
import { UserService } from '@maersk-global/huub-utils';
import { InitComponent } from './init.component';
import { CasModule } from '../src';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { RumInit } from '@maersk-global/telemetry-web-sdk-beta';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'cas',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [InitComponent],
    imports: [
        RouterModule.forRoot(appRoutes, {
            onSameUrlNavigation: 'reload'
        }),
        CasModule.setEnvironment(environment.CAS_CONFIG)
    ]
})
export class CasSandboxModule {
    ngDoBootstrap(app: ApplicationRef) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token') || '';
        const userData = atob(token.split('.')[1] || '') || '{}';

        const cachedToken = localStorage.getItem('accessToken');
        const cachedUserData = localStorage.getItem('userData');

        if (environment.CAS_CONFIG.MOP_API_KEY) {
            const faro = RumInit({
              app: {
                name: environment.CAS_CONFIG.MOP_APP_NAME,
                version: '1.0.0',
                environment: environment.CAS_CONFIG.MOP_ENV,
              },
              apiKey: environment.CAS_CONFIG.MOP_API_KEY,
              debug: false,
              traceLog: true
            });
          }

        if (token !== '') {
            UserService.setUserData(token, JSON.parse(userData));

            localStorage.setItem('accessToken', token);
            localStorage.setItem('userData', userData);
        } else if (cachedToken && cachedUserData) {
            UserService.setUserData(cachedToken, JSON.parse(cachedUserData));
        } else {
            UserService.setUserData(token, JSON.parse(userData));

            localStorage.setItem('accessToken', token);
            localStorage.setItem('userData', userData);
        }

        // if (environment.domain !== '') {
        //     document.domain = environment.domain;
        // }

        app.bootstrap(InitComponent);
    }
}
